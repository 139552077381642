import React, { FC, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import useSWR from 'swr'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu';

import AdditionalPlans from './home/AdditionalPlans'
import RunningPlan from './home/RunningPlan'
import Favorites from './home/FavoritesHome'
import IntegrationsMainPage from './integrations/IntegrationsMainPage'
import { AuthPageProps, RSTabsDataProps } from '../modules/types'
import WithAuth from '../components/WithAuth'
import RSNavCont from '../components/layout/RSNavCont'
import RSHeaderCont from '../components/layout/RSHeaderCont'
import RSDrawer from '../components/layout/RSDrawer'
import RSCardCont from '../components/cards/RSCardCont'
import RSIcon from '../components/icons/RSIcon'
import { SH4, P1, P2 } from '../components/text/RSTypography'
import useIsMobileScreen from '../hooks/useIsMobileScreen'
import useRNBridge from '../hooks/useRNBridge'
import useRSTabs from '../hooks/useRSTabs'
import useStore from '../store/useStore'
import { ICurrentUser } from '../store/createAccountStore'
import { getReq } from '../modules/apiConsume'
import { SHARE_REWARD_AMOUNT } from '../modules/cliEnvValues';
import { UpdateNotificationStatus } from '../modules/weeklyOutlookActions'

const Home: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {
	const navigate = useNavigate()
	const isMobile = useIsMobileScreen()

	const [runningLoading, setRunningLoading] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)

	const [showWeeklyOutlookDrawer, setShowWeeklyOutlookDrawer] = useState<boolean>(false)
	const [announcementData, setAnnouncementData] = useState<Array<any>>([])
	const [openAnnouncement, setOpenAnnouncement] = useState<boolean>(false)
	const [displayPopupConnectWatch, setDisplayPopupConnectWatch] = useState<boolean>(false)
	const [garminLoading, setGarminLoading] = useState<boolean>(true)
	const [userGarminDetails, setUserGarminDetails] = useState<any>()
	const appleHealthPermission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')
	const watchConnected = (userGarminDetails?.['status'] == 1 || appleHealthPermission?.status === 'granted') ? true : false

	const { data: outlookNotification, mutate } = useSWR(userInfo?.account_id ? `/notification/user/get-last?user_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const initialRef = useRef<any>(true)

	const { sendDataToReactNative } = useRNBridge()

	const { notificationStatus } = useStore((state: ICurrentUser) => state)
	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false
	// const { mobileType } = useDeviceDetect()

	const unreadAnnouncement = announcementData?.filter((d: any) => d.isUnread)?.length || 0

	const tabList: RSTabsDataProps = [{
		title: 'RUNNING',
		body: <RunningPlan userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} setIsEmpty={setIsEmpty} showWeeklyOutlookDrawer={showWeeklyOutlookDrawer} setShowWeeklyOutlookDrawer={setShowWeeklyOutlookDrawer} />,
	}, {
		title: 'PROGRAMS',
		body: <AdditionalPlans userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} />
	}, {
		title: 'FAVORITES',
		body: <Favorites userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} />,
	}]

	const { tabsUI, tab } = useRSTabs({
		data: tabList,
		btnContSX: { width: '100%', justifyContent: 'space-evenly' },
		btnSX: { px: window.innerWidth < 350 ? 1 : 2 }
	})

	const handleGetStoredAnnouncement = (initialData?: Array<any>) => {
		const newAnnouncementData = (initialData || announcementData)?.map((aData: any) => {
			const sName = localStorage.getItem(aData?.storageName || '')
			const isUnread = aData?.storageName ? (sName ? moment(sName).diff(moment(), 'days') > 12 : true) : (aData?.isUnread || false)
			if (isUnread && aData?.storageName)
				localStorage.removeItem(aData?.storageName || '')
			return {...aData, isUnread}
		}).filter((d: any) => d.title)
		const unreadArr = newAnnouncementData?.filter((d: any) => d.isUnread) || []
		const readArr = newAnnouncementData?.filter((d: any) => !d.isUnread) || []
		setAnnouncementData([...unreadArr, ...readArr])
	}


	const handleCloseAllAnnouncement = () => {
		announcementData?.forEach((aData: any) => {
			aData?.storageName && localStorage.setItem(aData.storageName, moment().format('YYYY/MM/DD'))
			aData?.isUnread && aData?.onRead?.()
		})
		handleGetStoredAnnouncement()
	}

	const handleCloseAnnouncement = (name: string) => {
		localStorage.setItem(name, moment().format('YYYY/MM/DD'))
		handleGetStoredAnnouncement()
	}


	const getGarminDetails = async () => {
		const response = await getReq('/v2/garmin/user')
		const data = response?.data?.result?.[0]
		setUserGarminDetails(data)
		setGarminLoading(false)
	}

	const updateWeeklyOutlookNotification = async (notificationId: string) => {
		await UpdateNotificationStatus(notificationId, 'opened')
		mutate()
	}

	useEffect(() => {
		if (!anchorEl && !initialRef.current)
			handleCloseAllAnnouncement()
	}, [anchorEl])

	useEffect(() => {
		if (!openAnnouncement && !initialRef.current)
			handleCloseAllAnnouncement()
		initialRef.current = false
	}, [openAnnouncement])

	useEffect(() => {
		return () => {
			(openAnnouncement || anchorEl) && handleCloseAllAnnouncement()
		}
	}, [anchorEl, openAnnouncement])

	useEffect(() => {
		setGarminLoading(userGarminDetails ? false : true)
		if (userInfo?.account_id && !userGarminDetails)
			getGarminDetails()

	}, [userInfo])

	useEffect(() => {

		const outlookNotificationResult = outlookNotification?.data?.result?.[0]
		const outlookContents = outlookNotificationResult?.contents ? JSON.parse(outlookNotificationResult?.contents) : {}
		const notifStausReady = localStorage.getItem('notification-status')

		const announcementData = [!watchConnected && !garminLoading ? {
			title: 'Sync your training',
			description: 'Bring your training plan to life with real-time insights and advance tracking',
			icon: 'fa-sharp fa-light fa-watch',
			storageName: 'syncTraining',
			selectButton: 'Sync watch',
			onClick: () => setDisplayPopupConnectWatch(true)
		} : {},
			outlookContents && watchConnected && !garminLoading ? {
			title: 'Weekly Outlook',
			description: outlookContents?.contents?.en || outlookContents?.subtitle?.en || '',
			rsIcon: <RSIcon type='weekly-outlook'/>,
			isUnread: outlookNotificationResult?.status === 'opened' ? false : true,
			onClick: () => setShowWeeklyOutlookDrawer(true),
			date: outlookNotificationResult?.date_created,
			onRead: () => {
				outlookNotificationResult?.notification_id && updateWeeklyOutlookNotification(outlookNotificationResult.notification_id)
			}
		} : {},
		!notificationStatus && isNativeApp && notifStausReady === "1" ? {
			title: 'Turn on notifications',
			description: 'Training made simple: real-time updates to keep you moving forward',
			icon: 'fa-sharp fa-light fa-bell',
			storageName: 'announcementNotif',
			selectButton: 'Turn on',
			onClick: () => {
				const dataToSend = {
					requestType: 'notification-permission',
					payload: {
						requestPermission: true
					},
					timestamp: new Date().getTime(),
				}
				sendDataToReactNative(dataToSend)
				handleCloseAnnouncement('announcementNotif')
				//setDisplayPopupNotification(false)
			}
		} : {}, {
			title: 'Share & Earn Store Cred.',
			description: `Share your link and earn $${SHARE_REWARD_AMOUNT} on the RunSmart store for every friend who joins`,
			icon: 'fa-sharp fa-light fa-paper-plane',
			storageName: 'shareRS',
			selectButton: 'Take me there',
			onClick: () => navigate('/profile', { replace: true })
		}
		].filter((d: any) => d.title)

		handleGetStoredAnnouncement(announcementData)
	}, [notificationStatus, watchConnected, garminLoading, outlookNotification])

	const renderAnnouncement = () => {
		return <>
			<RSHeaderCont isStatic={!isMobile} backClick={isMobile ? () => setOpenAnnouncement(false) : undefined} barSX={{borderRadius: isMobile ? 0 : '5px 5px 0px 0px'}} title='Notifications' />
			<Grid container xs={12}>
				{announcementData?.length > 0 && hasLoaded ? <Box sx={{ p: 2 }}>
					{announcementData?.map((aData: any, aIndex: number) => {
						const isUnread = aData?.isUnread
						return <Box key={aIndex + '-plan-announcement'} sx={{ pb: isMobile ? 0 : 1, pt: isMobile ? 2 : 1}}>
							<RSCardCont onClick={aData?.onClick} isLoading={!hasLoaded} sx={{ border: 'none !important', height: 'auto', p: 0, alignItems: 'center', flexDirection: 'row' }}>
								<Box sx={{display: 'flex', width: '100%'}}>
									<Box className={isUnread ? 'pink-bg' : 'white-bg'} sx={{width: '8px'}} />
									<Box sx={{ width: '35px', ml: 1, textAlign: 'left', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
										{
											aData?.rsIcon || (aData?.logo ? <img src={aData?.logo} style={{ scale: '1.1' }} />
												: (aData?.icon ?
													<i className={aData?.icon} style={{ fontSize: '25px' }} />
													: <></>
												))
										}
									</Box>
									<Box sx={{ width: `calc(100% - 51px)`, pr: 2, pt: '13px', pb: '12px', display: 'flex', flexDirection: 'column' }}>
										<SH4 title>{aData?.title}</SH4>
										<P2 className='ellipsis-3l' subtitle sx={{pb: '1px'}}>{aData?.description}</P2>
										{aData.date && <P2 className='gray-disabled-text' size={-1} sx={{pb: '1px', width: '100%', textAlign: 'right', mt: '4px', mb: '-4px'}}>{moment(aData.date).format('MMMM, D')}</P2>}
									</Box>
								</Box>
							</RSCardCont>
						</Box>
					})}
				</Box> : <></>}
			</Grid>
		</>
	}

	const renderBellButton = () => {
		return <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<Button onClick={isMobile ? ()=>setOpenAnnouncement(true) : handleClick} sx={{color: '#010101', minWidth: '0px', py: 0, px: isMobile ? 0 : 1, borderRadius: isMobile ? '50%' : '0px', flexDirection: 'column'}}>
				<Box sx={{display: 'flex', position: 'relative'}}>
					{unreadAnnouncement > 0 && <Box className='pink-bg white-text' sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, right: 0, width: isMobile ? '12px' : '15px', height: isMobile ? '12px' : '15px', borderRadius: '50%'}}>
						<P1 size={isMobile ? -2 : -1}>{unreadAnnouncement}</P1>
					</Box>}
					<i className="fa-light fa-bell" style={{fontSize: isMobile ? '25px' : '27px'}} />
				</Box>
				{!isMobile && <P1 desc>Notifications</P1>}
			</Button>
		</Box>
	}

	return (
		<RSNavCont additionalChildren={renderBellButton()} loaderProps={{ active: !hasLoaded || runningLoading, isBehindHeader: false }} hideHeader={isMobile} contClass='background-bottom' contSX={{backgroundImage: isMobile ? `url(${isEmpty && !tab ? '/img-new/homepage/cover_images/runsmart_no_plan_half.jpg' : '/img-new/race-events/Runner-Group-Mobile.jpg'})` : ''}}>
			{isMobile && <RSHeaderCont hasLogo title="Training">
				{renderBellButton()}
			</RSHeaderCont> }

			<Grid xs={12} sx={{ px: 0, pt: 2, height: '100%', pb: '100px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) 85%, transparent 100%)' }}>
				{/*renderAnnouncement2()*/}
				{tabsUI}
			</Grid>

			<RSDrawer fullWidth open={openAnnouncement} onClose={() => setOpenAnnouncement(false)}>
				{renderAnnouncement()}
			</RSDrawer>


			<RSDrawer bottom fitContent open={displayPopupConnectWatch} onClose={() => setDisplayPopupConnectWatch(false)}>
				<Box sx={{ py: 2, px: 3 }}>
					<IntegrationsMainPage userInfo={userInfo} />
				</Box>
			</RSDrawer>
			{/*<RSDrawer bottom fitContent open={displayPopupNotification} onClose={() => setDisplayPopupNotification(false)}>
				<Box sx={{ py: 2, px: 3 }}>
					<Notification userId={userInfo?.account_id} isAllowed={notificationStatus} autoOpen={true} />
				</Box>
			</RSDrawer>*/}


			{!isMobile && <Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							maxWidth: 400,
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&::before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: isMobile ? 14 : 43,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					},
				}}
				sx={{
					'& .MuiMenu-list': {
						padding: 0,
						backgroundColor: '#f6f6f6',
						borderRadius: '5px'
					}
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{renderAnnouncement()}
			</Menu>}
		</RSNavCont>
	)
}

export default WithAuth(Home);
