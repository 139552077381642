/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForwardIos'
import Avatar from '@mui/material/Avatar';

import ProfileImage from './ProfileImage'
import HeightWeight from './HeightWeight'
import Nutrition from './Nutrition'
import MeasurementSystem from './MeasurementSystem'
import Gender from './Gender'
import DOB from './DOB'
import LongRunDay from './LongRunDay'
import RunningStyle from './RunningStyle'
import PhysicalActivity from './PhysicalActivity'
import AverageRun from './AverageRun'
import { AuthPageProps } from '../../modules/types'
import { getHeight, getWeight, capCase } from '../../modules/miscUtils'
import { UpdateAccountProfile, getAllAccountInfo, getSubscriptionInfo, signOut } from '../../modules/accountActions'
import RSDrawer from '../../components/layout/RSDrawer'
import RSCardCont from '../../components/cards/RSCardCont'
import RSAccordion from '../../components/cards/RSAccordion'
import { H3, SH4, P2 } from '../../components/text/RSTypography'
import RSButton from '../../components/buttons/RSButton'
import { RSButton1, RSButton2 } from '../../components/buttons'
import { AccountProfile } from '../../api/v2/account/Account.types'
import IntegrationsMainPage from '../integrations/IntegrationsMainPage'
import { getReq } from '../../modules/apiConsume'
import NameProfileImage from './NameProfileImage'

import textHelperUtils from '../../utils/textHelperUtils'
import ChangePassword from '../../components/page-specific/account/ChangePassword'
import useAlert from '../../hooks/useAlert'
import Subscription from '../../components/page-specific/account/Subscription'
import { navReq } from '../../modules/apiConsume'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { RECURLY_PUBLIC_KEY, SHARE_REWARD_AMOUNT } from '../../modules/cliEnvValues'
import Billing from '../../components/page-specific/account/Billing'
import ImageLogo from '../../components/logos/ImageLogo'
import Email from './Email'
import RSChurrosButton from '../../components/buttons/RSChurrosButton'
import Location from './Location'
import secureLocalStorage from 'react-secure-storage'
import YourTrainingJourney from '../training-plan/YourTrainingJourney'
import { MISC_LOGOS } from '../../modules/miscImages'
import AccountDeletion from './AccountDeletion'
import { getCurrentAmount, getCurrentPlanCode } from '../../modules/subscriptionUtils'
import useQueryParams from '../../hooks/useQueryParams'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import useRNBridge from '../../hooks/useRNBridge'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import ShareInvite from './ShareInvite'
import ShareCredit from './ShareCredit'
import Notification from './Notification'

type InfoListProps = {
	label: string,
	data?: any,
	logo: any,
	component?: any,
	hidden?: boolean
	disabled?: boolean
}

type GroupListProps = {
	title: string,
	infoList: Array<InfoListProps>
}

type PersonalProps = AuthPageProps & {
	accountProfile: any,
	isLoading: boolean,
	mutate: any,
	isMobile: boolean,
	setMainLoading: any
}


const Personal: FC<PersonalProps> = ({ userInfo, hasLoaded, accountProfile, isLoading, mutate, isMobile, setMainLoading }) => {
	const [showContent, setShowContent] = useState<Array<number>>([])
	const [refreshImageKey, setRefreshImageKey] = useState(new Date().getTime());
	const [userGarminDetails, setUserGarminDetails] = useState<any>()
	const [planType, setPlanType] = useState<string>('')
	const [paymentType, setPaymentType] = useState<string>('')

	const { subscription, billingInfo, userCoords, notificationStatus } = useStore((state: ICurrentUser) => state)

	const accountProfileData = accountProfile?.data.result[0] as AccountProfile || undefined
	const { physical_activity, running_activity, running_style, dob, gender, run_day, measurement_system, weight_lbs, height_cm, name, user_id, profile_image_path, dietary_preferences, location } = accountProfileData || {}
	const isMetric = measurement_system === 'metric' ? true : false

	const { feet = 0, inch = 0, cm = 0 } = height_cm ? getHeight(true, { feet: 0, inch: 0, cm: height_cm }) : {}
	const { kg = 0, lbs = 0 } = weight_lbs ? getWeight(false, { kg: 0, lbs: weight_lbs }) : {}

	const isCoupon = userInfo?.paymentType && userInfo?.paymentType.includes('coupon') ? true : false

	const nameSP = name ? name : (userInfo?.first_name || "") + " " + (userInfo?.last_name || "") || ""

	const { changeLocation, changeUnits, oauth_token, oauth_verifier, timeline, code, state, garmin_connect } = useQueryParams()

	const { capitalizeEachWord } = textHelperUtils()

	const onCancelSubscription = () => navReq('/#/cancel/step-1')

	const closeDrawer = () => setShowContent([])

	const { sendDataToReactNative } = useRNBridge()

	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false

	const appleHealthPermission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')

	const notifStausReady = localStorage.getItem('notification-status')

	const [enableDevMode, setEnableDevMode] = useState(true)

	const [activeSetting, setActiveSetting] = useState('')

	const [openShareCredit, setOpenShareCredit] = useState(false)

	const [imgLoading, setImgLoading] = useState(true)

	const { mobileType } = useDeviceDetect()

	// console.log(accountProfileData)
	// console.log(userInfo)

	const handleUpdateProfile = async (dataOBJ: object) => {
		if ((user_id || userInfo?.account_id) && dataOBJ && Object.keys(dataOBJ)?.length) {
			setMainLoading(true)
			await UpdateAccountProfile({ user_id: user_id || userInfo?.account_id, ...dataOBJ }).then(() => {
				mutate?.()
				closeDrawer()
			})
			setMainLoading(false)
		}
	}

	const handleGetNativeLocation = () => {
		const dataToSend = {
			requestType: 'geolocation-enable',
			payload: true,
			timestamp: new Date().getTime(),
		}
		sendDataToReactNative(dataToSend);
	}

	const handleGetZipcode = async (coords: GeolocationCoordinates) => {
		const { latitude, longitude } = coords;
		const zipcodes = await getReq(`/v2/findNearbyPostalCodes?latitude=${latitude}&longitude=${longitude}`)
		if (zipcodes?.data?.postalCodes?.length > 0) {
			const address = zipcodes.data.postalCodes[0]
			await handleUpdateProfile({ location: address.placeName + ', ' + address.countryCode })
		}
	}

	const getGarminDetails = async () => {
		const response = await getReq('/v2/garmin/user')
		const data = response.data.result[0]
		// console.log(data)
		setUserGarminDetails(data)
	}

	const getData = async () => {
		const activeDetails = subscription?.active || {}
		const planCode = getCurrentPlanCode(activeDetails)
		const rate = getCurrentAmount(activeDetails)
		setPlanType('$' + rate + '/' + (planCode.includes('month') ? 'month' : planCode.includes('annual') ? 'year' : ''))
		setPaymentType(capitalizeEachWord((billingInfo?.paymentType ? billingInfo?.paymentType : userInfo?.paymentType ? userInfo.paymentType : 'None' || '')?.replace(/_/g, ' ')))
	}


	const {
		onAlertOpen,
		setAlertMessage,
		setAlertVariant,
	} = useAlert()

	const allProps = { data: accountProfileData || {}, handleUpdateProfile }

	const groupList: Array<GroupListProps> = [
		{
			title: 'PERSONAL',
			infoList: [
				{
					label: '',
					data: { accountProfileData },
					logo: '',
					component: <NameProfileImage userInfo={userInfo} isMobile={isMobile} {...allProps} reload={() => {
						mutate()
						setRefreshImageKey(new Date().getTime())
						closeDrawer && closeDrawer()
					}} />,
					hidden: true
				},
				{
					label: '',
					data: '',
					logo: '',
					component: <YourTrainingJourney userInfo={userInfo} onCloseModal={closeDrawer} />,
					hidden: true
				},
				{
					label: 'Gender',
					data: gender || 'None',
					logo: <i className="fa-light fa-person-half-dress" />,
					component: <Gender {...allProps} />
				}, {
					label: 'Birthday',
					data: dob ? moment(dob).format('MMM DD, YYYY') : 'None',
					logo: <i className="fa-light fa-cake-candles" />,
					component: <DOB {...allProps} />
				}, {
					label: 'Ht/Wt',
					data: isMetric ? `${kg || 0}kg | ${cm || 0} cm` : `${lbs || 0}lbs | ${feet || 0}'${inch || 0}"`,
					logo: <i className="fa-light fa-weight-scale" />,
					component: <HeightWeight {...allProps} />
				}, {
					label: 'Run Style',
					data: running_style || 'None',
					logo: <i className="fa-sharp fa-light fa-person-running" />,
					component: <RunningStyle {...allProps} />
				}, {
					label: 'Activity',
					data: physical_activity || 'None',
					logo: <i className="fa-sharp fa-light fa-person-running" />,
					component: <PhysicalActivity {...allProps} />,
					hidden: true
				}, {
					label: 'Ave. run',
					data: running_activity?.replace(isMetric ? 'mi' : 'km', isMetric ? 'km' : 'mi') || 'None',
					logo: <i className="fa-sharp fa-light fa-person-running" />,
					component: <AverageRun {...allProps} />,
					hidden: true
				}
			]
		}, {
			title: 'INTEGRATIONS',
			infoList: [
				{
					label: 'Watch',
					data: userGarminDetails && userGarminDetails['status'] == 1 ? 'Garmin' : appleHealthPermission?.status === "granted" ? 'Apple' : 'None',
					logo: <i className={`fa-kit fa-light-watch-smart-circle-${userGarminDetails && userGarminDetails['status'] == 1 || appleHealthPermission?.status === "granted" ? 'check' : 'question'}`} />,
					component: <IntegrationsMainPage reload={async () => await getGarminDetails()} userInfo={userInfo} />,
				}, {
					// hidden: secureLocalStorage.getItem("isAppleSignup") ? true : false,
					label: !secureLocalStorage.getItem("isAppleSignup") || secureLocalStorage.getItem("isGoogleSignup") ? "Sign In" : 'Update Email',
					data: !secureLocalStorage.getItem("isAppleSignup") ? "Sign in with Apple" : secureLocalStorage.getItem("isGoogleSignup") ? "Sign in with Google" : accountProfileData?.email || userInfo?.email || 'None',
					logo: <i className="fa-duotone fa-at duo-tone" />,
					disabled: secureLocalStorage.getItem("isAppleSignup") || secureLocalStorage.getItem("isGoogleSignup") ? true : false,
					component: <Email
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						setAlertVariant={setAlertVariant}
						reload={() => {
							mutate()
							closeDrawer && closeDrawer()
						}}
						{...allProps}
						userInfo={userInfo}
					/>,
					hidden: true
				}, {
					label: 'Update Password',
					logo: <i className="fa-duotone fa-lock-keyhole duo-tone" />,
					component: <ChangePassword
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						setAlertVariant={setAlertVariant}
					/>,
					hidden: true
				}, {
					label: 'Delete Account',
					logo: <i className="fa-duotone fa-trash-can duo-tone" />,
					component: <AccountDeletion />,
					hidden: true
				}, {
					label: 'Location',
					data: location || 'None',
					logo: <i className={`fa-light fa-location-dot${location ? '' : '-slash'} duo-tone`} />,
					component: <Location {...allProps} getLocation={isNativeApp ? handleGetNativeLocation : undefined} />,
				}, {
					label: 'Notification',
					data: notificationStatus || 'None',
					logo: <i className={`fa-light fa-bell${notificationStatus ? '-ring' : '-slash'} duo-tone`} />,
					component: <Notification {...allProps} userId={userInfo?.account_id} isAllowed={notificationStatus} />,
					hidden: isNativeApp && notifStausReady === "1" ? false : true
				}
			]
		}, {
			title: 'PREFERENCES',
			infoList: [
				{
					label: 'Nutrition',
					data: capCase(dietary_preferences) || 'None',
					logo: <i className="fa-light fa-salad" />,
					component: <Nutrition {...allProps} />
				}, {
					label: 'Long Run',
					data: run_day || 'None',
					logo: <i className="fa-light fa-person-running-fast" />,
					component: <LongRunDay {...allProps} />
				}, {
					label: 'Metric',
					data: capCase(measurement_system) || 'None',
					logo: <i className="fa-light fa-ruler" />,
					component: <MeasurementSystem {...allProps} />
				}
			]
		}, {
			title: 'BILLING',
			infoList: [
				{
					hidden: isCoupon,
					label: 'Manage Subscription',
					data: planType,
					logo: <ImageLogo type="subscription" height={25} />,
					component: <Subscription
						isRenew={false}
						isExpiring={userInfo?.is_expiring}
						isCoupon={isCoupon}
						billingIsValid={userInfo?.is_valid}
						onSubmit={undefined}
						onCancel={onCancelSubscription}
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						setAlertVariant={setAlertVariant}
					/>
				}, {
					hidden: localStorage.getItem('deviceRequestKey') ? true : false,
					label: 'Manage Billing',
					data: paymentType,
					logo: <i className="fa-light fa-credit-card" />,
					component: <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
						<Elements>
							<Billing
								userInfo={userInfo}
								afterBillingProcess={closeDrawer}
								isUpdate={false}
								onAlertOpen={onAlertOpen}
								setAlertMessage={setAlertMessage}
								setAlertVariant={setAlertVariant}
								isAccount={true}
								hideDue
							/>
						</Elements>
					</RecurlyProvider>
				}
			]
		}]


	const handleUpdatePassword = (e: any) => {
		e?.stopPropagation()
		setShowContent([2, 3])
	}

	const handleClickShop = () => {
		if (isNativeApp && mobileType.toLowerCase() === "ios") {
			const dataToSend = {
				requestType: 'open-rs-shop',
				// payload: { url: "https://shop.runsmartonline.com" },
				payload: { url: "https://shop.runsmartonline.com" },
				timestamp: new Date().getTime(),
			}
			sendDataToReactNative(dataToSend);
		} else
			// window.open('https://shop.runsmartonline.com', '_blank')
			window.open('https://shop.runsmartonline.com', '_blank')
	}

	const renderDrawer = () => {
		let drawer = ''
		if (showContent.length == 2)
			drawer = groupList[showContent[0] - 1]?.infoList?.[showContent[1] - 1]?.component
		return drawer
	}

	const renderCard = (title: string, desc: string, iconClass: string, style2?: boolean, onClick?: any) => {
		return <RSButton2 secondary={!style2} sx={{ p: 1, mt: 1, justifyContent: 'flex-start' }} onClick={() => onClick?.()}>
			<Box sx={{ width: '28px', mx: '10px' }}>
				<i className={iconClass} style={{ fontSize: '25px' }} />
			</Box>
			<Box sx={{ width: `calc(100% - ${style2 ? 75 : 45}px)`, px: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', py: '5px' }}>
				<SH4 title>{title}</SH4>
				<P2 subtitle>{desc}</P2>
			</Box>
			<Box sx={{ width: '20px', position: 'absolute', right: 10 }}>
				<i className="fa-solid fa-chevron-right" style={{ fontSize: '20px' }} />
			</Box>
		</RSButton2>
	}

	useEffect(() => {
		if (userInfo?.account_id)
			getGarminDetails()

	}, [userInfo])

	useEffect(() => {
		if (billingInfo && subscription)
			getData()
	}, [billingInfo, subscription])

	useEffect(() => {
		if (timeline) {
			setShowContent([1, 2])
			window.location.replace('/#/profile')
		}
	}, [timeline])

	useEffect(() => {
		if (changeLocation && accountProfileData) {
			setShowContent([2, 5])
			window.location.replace('/#/profile')
		}
	}, [changeLocation, accountProfileData])

	useEffect(() => {
		if (changeUnits && accountProfileData) {
			setShowContent([3, 3])
			window.location.replace('/#/profile')
		}
	}, [changeUnits, accountProfileData])

	useEffect(() => {
		if (oauth_token && oauth_verifier || garmin_connect) {
			// const temp_oauth_token = localStorage.getItem('garmin_oauth_token')
			// const temp_oauth_verifier = localStorage.getItem('garmin_oauth_verifier')
			// if (temp_oauth_token && temp_oauth_verifier) {
			// 	localStorage.removeItem('garmin_oauth_token')
			// 	localStorage.removeItem('garmin_oauth_verifier')
			// }
			setShowContent([2, 1])
		}
	}, [oauth_token, oauth_verifier, garmin_connect])

	useEffect(() => {
		if (code && state) {
			setShowContent([2, 1])
		}
	}, [code, state])

	useEffect(() => {
		if (userCoords) {
			handleGetZipcode(userCoords)
		}
	}, [userCoords])

	useEffect(() => {
		if (isNativeApp) {
			handleGetNativeLocation()
		}
	}, [isNativeApp])

	return (
		<Grid container xs={12} sx={{ py: 2 }}>
			<RSCardCont disabled={isLoading} onClick={() => setShowContent([1, 1])} editable={!isLoading} sx={{ justifyContent: 'flex-start', px: 2 }}>
				<Box sx={{ pr: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
					{(isLoading || (profile_image_path && imgLoading)) && <Box className='loading-days toRight' sx={{ border: 'none !important', height: '82px', width: '82px', top: '50%', left: 'calc(50% - 8px)', transform: 'translate(-50%, -50%)', borderRadius: '50%' }} />}
					<ProfileImage refreshImageKey={refreshImageKey} firstName={accountProfileData?.first_name || userInfo?.first_name || ""} lastName={accountProfileData?.last_name || userInfo?.last_name || ""} src={profile_image_path} setLoading={setImgLoading} />
				</Box>
				<Box sx={{ width: 'calc(100% - 115px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
					{isLoading && <Box className='loading-days toRight' sx={{ border: 'none !important', width: 'calc(100% + 20px)' }} />}
					<SH4 size={3} sx={{ textAlign: 'left', ...!nameSP?.trim() && { fontStyle: 'italic', fontFamily: 'Poppins-Light !important' } }}>{nameSP?.trim() ? nameSP : 'First Name, Last Name'}</SH4>
					<P2 className='ellipsis-1l' sx={{ textAlign: 'left', mt: '2px' }}>{accountProfileData?.email || userInfo?.email || 'None'}</P2>
					<SH4 size={1} className='pointer' onClick={handleUpdatePassword} sx={{ width: '100%', textAlign: 'left', pt: '5px', pb: '2px', color: '#2b9fcd' }}>Update Password</SH4>
				</Box>
			</RSCardCont>

			<Grid container xs={12} sx={{ pt: 2 }}>
				<H3 sx={{ width: '100%' }}>REFER & EARN</H3>
				{renderCard('YOUR REWARDS', `Get $${SHARE_REWARD_AMOUNT} store credit when a runner joins from your link.`, 'fa-sharp fa-light fa-party-horn', true, () => setOpenShareCredit(true))}
				<Grid container xs={12} justifyContent='space-between'>
					<ShareInvite newPage />
				</Grid>
			</Grid>

			<Grid container xs={12} sx={{ pt: 2 }}>
				<H3>SHOP</H3>
				{renderCard('SHOP MERCH', 'Gear up for running or recovery.', 'fa-light fa-shirt', false, handleClickShop)}
			</Grid>

			<Grid container xs={12} sx={{ pt: 2 }}>
				<H3>SETTINGS</H3>

				{groupList?.map((gData: any, gIndex: number) => {
					const { title, infoList } = gData
					const isActive = title === activeSetting
					const newInfoList = infoList?.filter((f: any) => !f.hidden)
					const iconsPerRow = Math.floor((window.innerWidth - 80) / 73)
					const listLength = newInfoList.length
					const rows = Math.ceil(listLength / iconsPerRow)
					return <RSAccordion key={`g-${gIndex}`} sx={{ mt: 1 }} title={title} isActive={isActive} setActive={setActiveSetting} height={(rows * 78) + 32}>
						<Grid container xs={12} sx={{ px: 2, py: 1, height: 'fit-content' }}>
							{infoList?.map((iData: any, iIndex: number) => {
								const { logo, label, hidden, component, disabled } = iData
								return hidden ? '' : <Box sx={{ mr: 1, mt: 1, width: '65px', height: '70px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={'infoList-' + iIndex}>
									<RSButton2 ghost disabled={disabled} selected onClick={() => component && setShowContent([gIndex + 1, iIndex + 1])} sx={{ width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '30px !important', flexDirection: 'column', py: '5px', px: 0, minWidth: '0px !important', mb: '5px' }}>
										<Box sx={{ '& *': { fontSize: '30px !important' } }}>{logo}</Box>
									</RSButton2>
									<P2 size={-1} sx={{ width: '100%', height: '15px', textAlign: 'center' }}>{label}</P2>
								</Box>
							})}
						</Grid>
					</RSAccordion>
				})}
			</Grid>
			<Grid container xs={12} justifyContent="center" alignItems="center" sx={{ mt: 5, mb: 3 }}>
				<RSButton1 sx={{ mt: '10px', maxWidth: '200px' }} onClick={signOut} >
					Logout
				</RSButton1>
			</Grid>
			<Grid container xs={12} justifyContent="center" alignItems="center" >
				<RSButton1 tertiary sx={{ maxWidth: '200px' }} onClick={() => setShowContent([2, 4])}>
					Delete Account
				</RSButton1>
			</Grid>


			<RSDrawer bottom fitContent={showContent[0] == 1 && showContent[1] == 2 && isMobile ? false : true} popUpWidth={showContent[0] == 1 && showContent[1] == 2 && !isMobile ? '700px' : '400px'} popUpHeight={showContent[0] == 1 && showContent[1] == 2 && !isMobile ? '90%' : 'auto'} open={showContent.length ? true : false} onClose={closeDrawer}>
				<Box sx={{ py: showContent[0] == 1 && showContent[1] == 2 ? 0 : 3, px: 3 }}>
					{renderDrawer()}
				</Box>
			</RSDrawer>

			<RSDrawer
				open={openShareCredit}
				onClose={() => setOpenShareCredit(false)}
			>
				<ShareCredit setOpen={setOpenShareCredit} />
			</RSDrawer>
		</Grid >
	)
}

export default Personal;
