import { FC } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {RSButton2} from '../buttons'

export type RSCardContProps = {
	className?: string,
	children?: any,
	onClick?: any,
	onClose?: any,
	sx?: object,
	disabled?: boolean
	isLoading?: boolean,
	editable?: boolean,
	ghost?: boolean
}

const RSCardCont: FC<RSCardContProps> = ({ className = '', children, onClick, onClose, sx, disabled, isLoading, editable, ghost }) => {
	const renderChildren = () => {
		return editable ? <Grid xs={12} sx={{display: 'flex', width: `calc(100% - ${editable ? 25: 0}px)`}}>
			{children}
		</Grid> : children
	}

	const renderButtonChildren = () => {
		return <>
			{!isLoading && renderChildren()}
			{(editable || onClose) &&  <Box className={onClose ? 'pointer' : ''} onClick={onClose ? (e) => {
				e.stopPropagation()
				onClose()
			} : undefined} sx={{position: 'absolute', right: onClose ? 5 : 10, top: onClose ? 5 : 10, fontSize: '16px', width: onClose ? 'auto' :'21px', display: 'flex'}}>
				<i className={`${ editable ? 'fa-light fa-pen' : 'fa-regular fa-xmark'}`} style={{...onClose && {fontSize: '20px'}}}/>
			</Box>}
		</>
	}
	return !disabled && !isLoading && onClick ? <RSButton2 secondary={!ghost} ghost={ghost} className={`${className} ${isLoading ? 'loading-days toRight' : ''}`} onClick={() => onClick?.()} sx={{ p: '10px', alignItems: 'flex-start', overflow: 'hidden', ...sx }}>
		{renderButtonChildren()}
	</RSButton2> : <Box className={`${className} rsButtonStyle white-bg`} sx={{p: '10px', display: 'flex', position: 'relative', ...sx}}>
		{renderButtonChildren()}
	</Box>
}

export default RSCardCont;
