import { FC, useState } from 'react'
import moment from 'moment'
import is_numeric from 'locutus/php/var/is_numeric'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { BarChart } from '@mui/x-charts/BarChart'
import { legendClasses } from '@mui/x-charts/ChartsLegend';

import ProvideFeedback from './ProvideFeedback'
import RSDrawer from '../../components/layout/RSDrawer'
import RSCardCont from '../../components/cards/RSCardCont'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import { RSButton1 } from '../../components/buttons'
import { H3, SH2, SH3, SH4, P1, P2 } from '../../components/text/RSTypography'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useStore from '../../store/useStore'
import { IAiTrainingPlanStore } from '../../store/createAITrainingPlanStore'

export type WeeklyOutlookProps = {
	showWeeklyOutlook: boolean,
	setShowWeeklyOutlook: any,
	weekData?: any,
	weekPace?: number,
	isAfterRaceDay?: boolean
}

const WeeklyOutlook: FC<WeeklyOutlookProps> = ({ weekData, weekPace, showWeeklyOutlook, setShowWeeklyOutlook, isAfterRaceDay }) => {
	const [open, setOpen] = useState(false)
	const [isHelpful, setIsHelpful] = useState(false)

	const isMobile = useIsMobileScreen()
	const { weekFirstDay, weekLastDay, weekCalendarData, totalWeekDistance, hasSeeDetails } = weekData || { weekFirstDay: '', weekLastDay: '', weekCalendarData: [], hasSeeDetails: false}
	const { weeklyOutlook, setWeeklyOutlook } = useStore((state: IAiTrainingPlanStore) => state)

	const addPlural = (num: number, word: string) => num > 1 && word !== 'km' ? `${word}s` : word

	const renderBody = () => {

		console.log('>>> weeklyOutlook', weeklyOutlook)

		//const summaryHeadLine = weeklyOutlook?.current?.headline
		const recap = weeklyOutlook?.current?.recap
		const unit = weeklyOutlook?.isKm ? 'km' : 'mile'

		//const upcomingHeadline = weeklyOutlook?.upcoming?.headline
		const upcomingSplit = weeklyOutlook?.upcoming?.recap?.replaceAll('**', '')?.split("*")?.filter((x: string) => x)
		const upcomingRecap = upcomingSplit?.[0]
		const upcomingBulletPoints = upcomingSplit?.splice(1)

		const defaultPace = weeklyOutlook?.currentGraphData?.reduce(
			(accumulator: any, currentValue: any) => accumulator + (currentValue?.total_planned_time || 0), 0
		) || 0
		const defaultPaceHR = defaultPace > 59 ? Math.floor(defaultPace / 60) : 0
		const defaultPaceMIN = Math.round(defaultPace % 60)

		const defaultDistance = weeklyOutlook?.currentGraphData?.reduce(
			(accumulator: any, currentValue: any) => accumulator + (Number(currentValue?.planned_distance) || 0), 0
		) || 0

		const completedDistance = weeklyOutlook?.currentGraphData?.reduce(
			(accumulator: any, currentValue: any) => accumulator + (Number(currentValue?.completed_distance) || 0), 0
		) || 0

		const upcomingDistance = totalWeekDistance || 0
		const upcomingPace = weekPace || 0
		const upcomingPaceHR = upcomingPace > 59 ? Math.floor(upcomingPace / 60) : 0
		const upcomingPaceMIN = Math.round(upcomingPace % 60)

		const totalCompletedTime = weeklyOutlook?.currentGraphData?.reduce(
			(accumulator: any, currentValue: any) => accumulator + (Number(currentValue?.total_completed_time) || 0), 0
		) || 0

		const completedPaceHR = totalCompletedTime > 59 ? Math.floor(totalCompletedTime / 60) : 0
		const completedPaceMIN = Math.round(totalCompletedTime % 60)

		const hasLastWeek = (weeklyOutlook?.details?.week_number || 0) > 0

		// console.log('///weeklyOutlook', weeklyOutlook)

		return <Box>
			<RSHeaderCont secondary={!isMobile} isStatic={!isMobile} noShadow={!isMobile} backClick={() => setShowWeeklyOutlook(false)} title='Weekly Outlook' barSX={{ backgroundColor: !isMobile ? 'transparent' : '' }} />
			<Grid xs={12} sx={{ p: 3, pt: 1 }}>
				<Grid container xs={12} sx={{ mt: 2 }}>
					<SH2 size={1} sx={{ mr: '5px' }}>Published:</SH2>
					<P1 size={1}>{moment(weeklyOutlook?.details?.date_created?.split('T')?.[0]).subtract(1, "days").format('MMMM D, YYYY')}</P1>
				</Grid>
				<Grid container xs={12} sx={{ mt: 2 }}>
					<RSCardCont className='weekly-bar-chart' sx={{ px: 2, py: '13px' }}>
						<Grid container xs={12}>
							<SH3 title>Running</SH3>
							<Box sx={{ width: '100%', position: 'relative' }}>
								<BarChart
									borderRadius={5}
									margin={{ left: 20, right: 0, top: 45, bottom: 25 }}
									sx={{
										[`& .${legendClasses.mark}`]: {
											ry: '50%',
										}
									}}
									slotProps={{
										legend: {
											itemMarkWidth: 15,
											itemMarkHeight: 15,
											markGap: 5,
											itemGap: 20,
											padding: 0,
											position: {
												vertical: 'top',
												horizontal: 'left',
											}, labelStyle: {
												fontSize: '14px !important',
											},
										}
									}}
									dataset={weeklyOutlook?.currentGraphData || []}
									xAxis={[{ dataKey: 'day', scaleType: 'band', valueFormatter: (day: any) => day?.charAt(0) }]}
									series={[
										{
											dataKey: 'completed_distance',
											label: 'Results'
										},
										{
											dataKey: 'planned_distance',
											label: 'Plan'
										}
									]}
									colors={['#9fcd2b', '#0000003b']}
									height={270}
								/>
							</Box>
						</Grid>
					</RSCardCont>
				</Grid>
				{hasLastWeek ? <>
					<H3 sx={{ width: '100%', mt: 2 }}>Last Week</H3>
					<P1 desc>{moment(weekFirstDay).subtract(7, 'd').format('MMM D')}{weekLastDay ? moment(weekLastDay).subtract(7, 'd').format(' - MMM D') : ''}</P1>
					<RSCardCont className='weekly-bar-chart' sx={{ p: 2 }}>
						<Grid container xs={12}>
							<Grid container xs={6} sx={{ flexDirection: 'column' }} alignItems='center'>
								<SH4>Training Time</SH4>
								<Box sx={{ display: 'flex', mt: '2px' }}>
									{completedPaceHR ? <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<SH4 size={5}>{completedPaceHR}</SH4>
										<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(completedPaceHR, 'hr')}</P1>
									</Box> : <></>}
									{completedPaceMIN ? <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: completedPaceHR ? '3px' : 0 }}>
										<SH4 size={5}>{completedPaceMIN}</SH4>
										<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(completedPaceMIN, 'min')}</P1>
									</Box> : <></>}
									{!(completedPaceMIN || completedPaceHR) ? <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: completedPaceMIN ? '3px' : 0 }}>
										<SH4 size={5}>--</SH4>
									</Box> : <></>}
								</Box>
								<P1 size={-2} className='gray-disabled-text'>
									{`Total Estimate:${defaultPaceHR ? ` ${defaultPaceHR}${addPlural(defaultPaceHR, ' hr')}` : ''}${defaultPaceMIN ? ` ${defaultPaceMIN}${addPlural(defaultPaceMIN, ' min')}` : ''}`}
								</P1>
							</Grid>

							<Grid container xs={6} sx={{ flexDirection: 'column' }} alignItems='center'>
								<SH4>Distance Ran</SH4>
								<Box sx={{ display: 'flex', alignItems: 'flex-end', mt: '2px' }}>
									<SH4 size={5}>{completedDistance && is_numeric(completedDistance) ? +Number(completedDistance).toFixed(1) : 0}</SH4>
									<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(completedDistance || 0, unit)}</P1>
								</Box>
								<P1 size={-2} className='gray-disabled-text'>
									{`Total Estimate: ${defaultDistance && is_numeric(defaultDistance) ? +Number(defaultDistance).toFixed(1) : 0} ${addPlural(defaultDistance, unit)}`}
								</P1>
							</Grid>
						</Grid>
					</RSCardCont>
					<Grid container xs={12} sx={{ mt: 2 }}>
						{recap && <P2 card>{recap}</P2>}
					</Grid>
				</> : <></>}

				{!isAfterRaceDay ? <>
					<H3 sx={{ width: '100%', mt: 2 }}>This Week</H3>
					<P1 desc>{moment(weekFirstDay).format('MMM D')}{weekLastDay ? moment(weekLastDay).format(' - MMM D') : ''}</P1>
					<RSCardCont sx={{ p: 2, flexDirection: 'column' }}>
						<Grid container xs={12}>
							{weekCalendarData}
						</Grid>
						<Grid container xs={12} sx={{ pt: 2 }}>
							{hasSeeDetails ? <Grid container xs={12} justifyContent='center'>
								<SH4 size={5}>See Details</SH4>
							</Grid> : <>
								<Grid container xs={6} sx={{ flexDirection: 'column' }} alignItems='center'>
									<SH4>Training Time</SH4>
									<Box sx={{ display: 'flex', mt: '4px' }}>
										{upcomingPaceHR ? <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
											<SH4 size={5}>{upcomingPaceHR}</SH4>
											<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(upcomingPaceHR, 'hr')}</P1>
										</Box> : <></>}
										{upcomingPaceMIN ? <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: upcomingPaceHR ? '3px' : 0 }}>
											<SH4 size={5}>{upcomingPaceMIN}</SH4>
											<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(upcomingPaceMIN, 'min')}</P1>
										</Box> : <></>}
									</Box>
								</Grid>

								<Grid container xs={6} sx={{ flexDirection: 'column' }} alignItems='center'>
									<SH4>Running</SH4>
									<Box sx={{ display: 'flex', alignItems: 'flex-end', mt: '5px' }}>
										<SH4 size={5}>{+Number(upcomingDistance).toFixed(1)}</SH4>
										<P1 size={-1} sx={{ mb: '3px' }} className='gray-disabled-text'>{addPlural(upcomingDistance, unit)}</P1>
									</Box>
								</Grid>
							</>}
						</Grid>
					</RSCardCont>

					<Grid container xs={12} sx={{ mt: 2 }}>
						{/*upcomingHeadline && <SH3 card>{upcomingHeadline}</SH3>*/}
						<P2 card>{upcomingRecap}</P2>
						{upcomingBulletPoints?.map((ubp: any, ubpI: number) => <Grid container key={`ubp-${ubpI}`} xs={12} sx={{ mt: 1 }}>
							<i className="fa-regular fa-circle-arrow-right" style={{ width: '20px', fontSize: '20px', marginRight: '5px', marginTop: '3px', height: '100%' }} />
							<P2 card sx={{ width: 'calc(100% - 25px)' }}>{ubp}</P2>
						</Grid>)}
					</Grid>
				</> : <></>}

				<Grid container xs={12} sx={{ pt: 10 }} justifyContent='center' alignItems='center'>
					<P1 size={1} sx={{ fontStyle: 'italic' }}>Was this helpful?</P1>
					<Grid container xs={12} justifyContent='center' alignItems='center'>
						<RSButton1 sx={{ width: '100px', my: 2, mx: 1 }} onClick={() => {
							setIsHelpful(true)
							setOpen(true)
						}}>Yes</RSButton1>
						<RSButton1 secondary sx={{ width: '100px', my: 2, mx: 1 }} onClick={() => {
							setIsHelpful(false)
							setOpen(true)
						}}>No</RSButton1>
					</Grid>
				</Grid>
			</Grid>

			<RSDrawer
				bottom
				open={open}
				onClose={() => setOpen(false)}
				popUpWidth='auto'
				popUpHeight='auto'
				maxWidth='425px'
				fitContent
			>
				<ProvideFeedback setOpen={setOpen} weeklyOutlook={weeklyOutlook} setWeeklyOutlook={setWeeklyOutlook} isHelpful={isHelpful} />
			</RSDrawer>
		</Box>
	}
	// console.log('///showWeeklyOutlook', isMobile, showWeeklyOutlook)
	return (isMobile ? <RSDrawer fullWidth open={showWeeklyOutlook} noClose={isMobile} onClose={() => setShowWeeklyOutlook(false)}>
		{renderBody()}
	</RSDrawer> : (showWeeklyOutlook ? renderBody() : <></>))
}

export default WeeklyOutlook;
