/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment"
import { GetPromptWeeklyOutlook } from "./weeklyOutlookActions"

const useAIPlanOutlookPrompts = () => {


    const defaultSystemPrompt = "You are a trainer who is helping a marathon runner with their weekly plan. The runner is following a plan for a marathon. \n Return results in json format. Sample JSON format to follow is {message:{headline:'', recap:''}}"

    const buildPromptWeekOutlookCurrentSummary = (userPlanInfo: any, weekDataCurrent: any) => {

        const dob = userPlanInfo?.profile?.dob
        const age = dob ? moment().diff(moment(dob), 'years') : 36
        const gender = userPlanInfo?.profile?.gender
        const raceType = userPlanInfo?.race_type?.toLowerCase().includes('k') ? userPlanInfo?.race_type : userPlanInfo?.race_type + ' Marathon'
        // const weeksLeft = ''
        const unit = userPlanInfo?.is_km ? 'Kilometers' : 'Miles'
        const weeklyMileage = weekDataCurrent?.weekly_mileage || 0
        const dailyRuns = weekDataCurrent?.daily_runs || ''

        // console.log('>>> weeklyMileage: ', weeklyMileage)
        // console.log('>>> dailyRuns: ', dailyRuns)


        const prompt = `
        I am a ${age}-year-old ${gender} marathon runner following a weekly plan for a ${raceType} race.

        I am running on ${unit} and my weekly mileage for this week is ${weeklyMileage}

        The plan I followed for this week is provided below:
        ${dailyRuns}
        
        In the message you send me, please do the following:
        - Give me a detailed summary of what I did.
        - Tell me some extra tips following this week's plan. 
        - Praise me on the things I did.

        In the end, give me a random quote for people who like to Jog, or for Marathon Runners.

        Example Output in JSON: { message: '' }
        `

        return prompt
    }

    const buildPromptsAllWeeklyOutlook = (userPlanInfo: any, weekData: any, prompt: any, selectedWeek?: number, AImessage?: string) => {
        const dob = userPlanInfo?.profile?.dob
        const age = dob ? moment().diff(moment(dob), 'years') : 36
        const raceDate = userPlanInfo?.race_date ? moment(userPlanInfo?.race_date).format('MMMM DD, YYYY') : 'the next month'
        const height = userPlanInfo?.profile?.height_cm || 5.8
        const weight = userPlanInfo?.profile?.weight_lbs || 170
        const unit = userPlanInfo?.is_km ? 'Kilometers' : 'Miles'
        const dailyRunsWeekCurrent = weekData?.current?.daily_runs || ''
        const dailyRunsWeekUpcoming = weekData?.upcoming?.daily_runs || ''
        const planType = userPlanInfo?.planType || ''
        const planName = userPlanInfo?.planName || ''
        const goal = userPlanInfo?.goal || ''
        const trainingPeak = userPlanInfo?.trainingPeak || ''
        const currentWeek = selectedWeek || userPlanInfo?.currentWeek || ''
        const totalWeeks = userPlanInfo?.totalWeeks || ''
        const longRunDay = userPlanInfo?.longrun_day || ''
        const message = AImessage || ''
        const templateLiteral = `\`${prompt}\``;
        const builtPrompt = eval(templateLiteral)
        // console.log('!!!>>> dailyRunsWeekCurrent: ', dailyRunsWeekCurrent)
        // console.log('>>> builtPrompt: ', builtPrompt)

        return builtPrompt;

    }


    const getPromptWeeklyOutlookDefault = (promptStep: number) => {


        // old local prompts
        const promptsWeeklyOutlook = [
            `Analyze the runner's past week's performance data. Include the following:

- Completed distance vs. planned distance
- Variations in pace, heart rate, or effort compared to planned metrics
- Missed, skipped, or modified workouts (e.g. swapped intervals for an easy run)
- Notes on perceived effort or feedback from the runner's logs (e.g. 'felt great', 'tired', or 'sore')

Summarize this data in bullet points, highlighting achievements, inconsistencies, and any potential warning signs (e.g. fatigue or injury). Keep it motivational but realistic.

Here is the runner's performance data for the past week (distances in ${'${unit}'}):
${'${dailyRunsWeekCurrent}'}

Example Output in JSON: { message: '' }
`
            ,
            `Based on the runner's upcoming race or training goal (e.g. marathon on ${'${raceDate}'}, recovery focus),
provide a short overview of the purpose and mindset for the upcoming week's training.

Example purposes:
- Build endurance for longer runs
- Sharpen speed and agility through intervals
- Focus on recovery and mobility to avoid overtraining

Provide this in 2-3 sentences, keeping it motivational and aligned with the runner's goals.

Here is the overview for the upcoming week's training (distances in ${'${unit}'}):
${'${dailyRunsWeekUpcoming}'}

Example Output in JSON: { message: '' }
`,
            `Compared the upcoming week's planned workouts with previous week's data. Suggest specific adjustments if needed, such as:

- Reducing intensity due to signs of fatigue
- Focusing on mobility or strength if recent runs indicate tightness or soreness
- Maintaining consistency if performance aligns with the plan.

Suggest fueling tips, hydration strategies, or rest days based on the runner's profile (height, weight, age) and recent performance. Write this as concise, actionable advice.

Runner's Profile:
- Age: ${'${age}'}
${"${height != 0 ? `- Height: ${height} cm` : ''}"}
${"${weight != 0 ? `-Weight: ${weight} lbs` : ''} "}

Here is the previous week's data (distances in ${'${unit}'}):
${'${dailyRunsWeekCurrent}'}

Here is the upcoming week's planned workouts (distances in ${'${unit}'}):
${'${dailyRunsWeekUpcoming}'}

Example Output in JSON: { message: '' }
`
        ]

        return promptsWeeklyOutlook[promptStep - 1]

    }


    return { defaultSystemPrompt, buildPromptWeekOutlookCurrentSummary, buildPromptsAllWeeklyOutlook, getPromptWeeklyOutlookDefault }
}

export default useAIPlanOutlookPrompts