/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react"
import RSButton from "../../components/buttons/RSButton"
import useRSPlanWeekOutlookAI from "../../hooks/useRSPlanWeekOutlookAI"
import useSWR from "swr"
import { GetTrainingPlanInfo } from "../../modules/aiTrainingPlanActions"
import useGPTTrainingPlan from "../race/useGPTTrainingPlan"
import useBuildTrainingPlan from "../training-plan/hooks/useBuildTrainingPlan"
import useTrainingPlanUtils from "../../hooks/useTrainingPlanUtils"
import moment from "moment"
import { getReq } from '../../modules/apiConsume'
import Form from "react-bootstrap/esm/Form"
import useAIPlanOutlookPrompts from "../../modules/useAIPlanOutlookPrompts"
import Loader from "../../components/animation/Loader"
import useTrainingTools from "./useTrainingTools"
import Grid from "@mui/material/Grid"
import SelectPill from "../../components/input/SelectPill"
import RSDrawer from "../../components/layout/RSDrawer"
import RSAccordion from "../../components/cards/RSAccordion"
import { GetPromptWeeklyOutlook, GetTrainingPlansActiveInfo, SavePromptWeeklyOutlook } from "../../modules/weeklyOutlookActions"
import { CreateAppleHealtActivity, GetAppleHealtActivity, UpdateAppleHealtActivity } from '../../modules/appleHealthKitActions';
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Input from "@mui/material/Input/Input"
import RSInputAdornment from "../../components/input/RSInputAdornment"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from "@mui/material/AccordionDetails"
import { Table } from "react-bootstrap"
import { ArrowLeft, ArrowRight, ReplayRounded, SendRounded } from '@mui/icons-material'
import ENV_VARS from './adminDashVars.json'
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import { CorosWorkout } from "../../api/v2/coros/coros.types"
import { getUserWorkoutsByRange } from "../../modules/corosActions"
import { GetSearchedAccountProfile } from "../../modules/accountActions"
import { GetUserCustomPace, GetUserNotes } from "../../modules/trainingPlanActions"



const WeeklyOutlook = (props: any) => {

    const { userInfo } = props

    const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const gptModels = ['gpt-3.5-turbo-0125', 'gpt-4o', 'gpt-4o-mini', 'gpt-4o-2024-08-06', 'gpt-4o-2024-05-13']
    const env = ENV_VARS.ADMIN_ENV.toString().toUpperCase()

    const { fetchResponseWeekOutlook } = useRSPlanWeekOutlookAI()
    const { convertToLegacyTrainingInfo } = useGPTTrainingPlan()
    const { buildPromptWeekOutlookCurrentSummary, buildPromptsAllWeeklyOutlook, getPromptWeeklyOutlookDefault } = useAIPlanOutlookPrompts()

    const { buildPaceInitial } = useTrainingTools(props)
    const { ConvertMetersToMiles, getGarminPace, ConvertM2H2 } = useTrainingPlanUtils()

    // const { data: userJourney } = useSWR( accountID ? `/training-plan/userinfo?uid=${accountID}` : null)

    const [configRunDataParams, setConfigRunDataParams] = useState<any>({ xt: false })
    const [displayConfigRunData, setDisplayConfigRunData] = useState<boolean>(false)
    const [showButtonsPlanSelectionMethod, setShowButtonsPlanSelectionMethod] = useState<boolean>(false)
    const [weekOutlookMessage, setWeekOutlookMessage] = useState<any>(null)
    const [planIdActive, setPlanIdActive] = useState<any>(null)
    const [weekNumCurrent, setWeekNumCurrent] = useState<any>(null)
    const [weekDataSelected, setWeekDataSelected] = useState<any>(null)
    const [trainingPlanData, setTrainingPlanData] = useState<any>(null)
    const [promptFormText, setPromptFormText] = useState<any>('')
    const [promptStep, setPromptStep] = useState<any>(1)
    const [dataRace, setDataRace] = useState<any>(null)
    const [dataUserTrainingPlans, setDataUserTrainingPlans] = useState<any>(null)

    // if ever admin wants to select a different week
    // currently not used
    const [selectedWeekCurrent, setSelectedWeekCurrent] = useState<boolean>(true)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [isEditedPrompt, setIsEditedPrompt] = useState<boolean>(false)
    const [stepNumberAiResponse, setStepNumberAiResponse] = useState<number>(1)
    const [aiResponsePerStep, setAiResponsePerStep] = useState<any>([])
    const [displayPopupAllResponses, setDisplayPopupAllResponses] = useState<boolean>(false)

    const [userEmailSelected, setUserEmailSelected] = useState<any>('')
    const [userIdSelected, setUserIdSelected] = useState<any>(null)
    const [userEmailsListActive, setUserEmailsListActive] = useState<any>([])
    const [planListAllActiveInfo, setPlanListAllActiveInfo] = useState<any>([])


    const [weekNumSelected, setWeekNumSelected] = useState<any>(null)

    const [displayPromptConfig, setDisplayPromptConfig] = useState<boolean>(false)
    const [promptDefault, setPromptDefault] = useState<any>('')
    const [promptInitialState, setPromptInitialState] = useState<any>('')
    const [promptBuiltState, setPromptBuiltState] = useState<any>('')

    const [selectedGPTModel, setSelectedGPTModel] = useState(gptModels[3])
    const [gptTemp, setGPTTemp] = useState('0')

    const [hasFetchedUsers, setHasFetchedUsers] = useState<boolean>(false)
    const [promptsHistory, setPromptsHistory] = useState<any>([])
    const [modeManualSearch, setModeManualSearch] = useState<boolean>(true)
    const [userListFiltered, setUserListFiltered] = useState<any>([])
    const [dataAppleActivity, setDataAppleActivity] = useState<any>(null)
    const [parsedAIResponse, setParsedAIResponse] = useState<string>()
    const [weekNotes, setWeekNotes] = useState<any>([])
    const [watchType, setWatchType] = useState<any>('')

    const accountID = 4948
    const { data: userTrainingPlans, isLoading: isLoadingPlans } = useSWR(userIdSelected ? `/gpt-training-plan-info/all?userId=${userIdSelected}` : null)


    const fillPromptsWeeklyOutlook = async (responseText?: string) => {

        // const prompt = weekDataSelected ? buildPromptsAllWeeklyOutlook(dataUserTrainingPlans, weekDataSelected, promptStep) : ''

        // to refactor, just get all prompts at once then set them in an array state
        GetPromptWeeklyOutlook(promptStep).then(res => {
            // setIsLoadingData(false)

            const prompt = res?.data?.result[0]?.prompt

            setPromptInitialState(prompt)
            const filledPrompt = buildPromptsAllWeeklyOutlook(dataUserTrainingPlans, weekDataSelected, prompt, weekNumSelected, responseText)

            // console.log('>>> filledPrompt: ', filledPrompt)
            // console.log('!!!!!!!! changed prompt !!!!!!!!')

            filledPrompt && setPromptBuiltState(filledPrompt)
            filledPrompt && setPromptDefault(filledPrompt)

        }).catch(err => {
            console.log('>>> GetPromptWeeklyOutlook err: ', err)
        })
    }


    const handleDBgetPromptsWeeklyOutlook = () => {

        GetPromptWeeklyOutlook(promptStep).then(res => {
            console.log('>>> GetPromptWeeklyOutlook res: ', res)
        }).catch(err => {
            console.log('>>> GetPromptWeeklyOutlook err: ', err)
        })

    }


    const handleSearchEnter = (e: any) => {

        setUserEmailSelected(null)
        setUserIdSelected(null)

        try {
            if (e.target.value === "") {
                setUserListFiltered([])
            }
            else if (e.target.value.length < 4) {
                alert('Please enter at least 4 characters')
            }
            else {
                getUsersByEmail(e.target.value)
            }
        }
        catch (error) {
            console.log('Error searching', error)
        }


    }


    const handleDBsavePromptWeeklyOutlook = async () => {

        displayPromptConfig && setIsLoadingData(true)

        displayPromptConfig && SavePromptWeeklyOutlook(promptStep, promptFormText).then(res => {
            // console.log('>>> SavePromptWeeklyOutlook res: ', res)

            setTimeout(() => {
                setIsLoadingData(false)
            }, 1000)

            setDisplayPromptConfig(false)
            fillPromptsWeeklyOutlook()
        }).catch(err => {
            console.log('>>> SavePromptWeeklyOutlook err: ', err)
        })

    }

    const handlePromptRestoreDefault = () => {

        setPromptFormText(getPromptWeeklyOutlookDefault(promptStep))

    }



    const getUsersWithActivePlans = async () => {

        setIsLoadingData(true)

        const usersWithActivePlans: any = []

        const activePlans = await GetTrainingPlansActiveInfo().then((res: any) => {

            setIsLoadingData(false)

            // console.log('>>> GetTrainingPlansActiveInfo res: ', res)

            setPlanListAllActiveInfo(res?.data?.result)

            res?.data?.result.forEach((x: any) => {
                usersWithActivePlans.push(x.plan_info?.account?.email)
            })

            usersWithActivePlans.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()))

            return usersWithActivePlans
        })

        activePlans && setUserEmailsListActive(usersWithActivePlans)
        // console.log('>>> usersWithActivePlans: ', usersWithActivePlans)

    }


    const getUsersByEmail = async (email: string) => {

        setIsLoadingData(true)

        const usersList = await getReq(`/v2/weekly-outlook/list-email?email=${email}`).then((res: any) => {

            setIsLoadingData(false)

            // console.log('>>> email list: ', res?.data?.result)

            setUserListFiltered(res?.data?.result)

        })
    }

    // alternate existing way of getting plan info, though another fetch call
    // plan_id required
    const getUserPlanInfo = async () => {

        setHasFetchedUsers(true)
        setModeManualSearch(false)

        const abortController = new AbortController()
        GetTrainingPlanInfo(planIdActive, abortController.signal).then((res: any) => {

            const allTrainingData = res?.data?.result[0]
            const uInfo = convertToLegacyTrainingInfo(res?.data?.result[0])
            setDataRace(uInfo)

            const planDedatails = {
                planType: allTrainingData.race_type.toLowerCase() === 'half' ? 'Half Marathon' : allTrainingData.race_type.toLowerCase() === "full" ? 'Full Marathon' : allTrainingData.race_type.toLowerCase(),
                planName: allTrainingData?.event_name,
                currentWeek: weekNumSelected,
                totalWeeks: allTrainingData?.weeks,
                goal: ConvertM2H2(allTrainingData?.target_time),
                trainingPeak: allTrainingData?.max_distance,
                unit: allTrainingData?.is_km ? 'Kilometers' : 'Miles',
            }

            const mergedData = { ...allTrainingData, ...planDedatails }
            setDataUserTrainingPlans(mergedData)

            // setUserInfo(uInfo)

            // console.log('>>> userTrainingPlans: ', userTrainingPlans)

            console.log('>>> GetTrainingPlanInfo allTrainingData res: ', res?.data?.result[0])
            console.log('>>> GetTrainingPlanInfo uInfo: ', uInfo)
        })
    }

    const getUserNotes = async (marathonDayValue: any, userIdSelected: any, abort: AbortSignal) => {
        console.log('!!! getUserNotes marathonDayValue', marathonDayValue)

    }

    const getCorosDailyRuns = async (weekDateStart: string, corosData: any, isKm: boolean) => {

        console.log('>>> coros daily runs')

        const dailyRunsNewCurrent: any[] = []

        const weekDataSelected = dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected) && dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected)[0]
        const weekDataUpcoming = dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected + 1) && dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected + 1)[0]

        const dailyRuns = JSON.parse(weekDataSelected?.daily_runs)
        console.log('>>> dailyRuns: ', dailyRuns)
        dailyRuns.forEach((x: any, i: any) => {
            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
            const corosDataOnDate = corosData?.find((x: any) => x.activity_date == thisDate)
            const marathonPaces = dataRace?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'

            let activities = corosDataOnDate?.activities && corosDataOnDate?.activities.length ? JSON.parse(corosDataOnDate?.activities) as CorosWorkout[] : []
            //get only running activities
            activities = activities.filter(x => x.mode == 8)

            let totalDistance: any = 0
            let totalAvgSpeed = 0
            let totalAvgHeartRate = 0
            let totalDuration = 0

            //sum up all runnning activities
            activities?.forEach(activity => {
                totalDistance += activity.distance
                totalAvgSpeed += activity.avgSpeed
                totalAvgHeartRate += activity.avgHeartRate ? activity.avgHeartRate : 0
                totalDuration += activity.duration
            })

            totalAvgSpeed = totalAvgSpeed / activities.length
            totalAvgHeartRate = Number((totalAvgHeartRate / activities.length).toFixed(0))

            const completedPace = totalDistance / totalDuration
            const paceDuration = completedPace ? getGarminPace(isKm ? true : false, completedPace) : 'NA'
            const planned_pace = dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(marathonPaces, plannedDistance, dataRace, userIdSelected)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(marathonPaces, plannedDistance, dataRace, userIdSelected)?.paceSpeed)) + ' /mile' || 'NA'
            const convertedDistance = totalDistance ? (!isKm ? ConvertMetersToMiles(totalDistance as number)?.toFixed(1) : Number(totalDistance as number / 1000)?.toFixed(1)) : 0

            const completedDistanceUserMarkedComplete = weekNotes[i] && weekNotes[i]?.completed === 1 && plannedDistance
            console.log('>>> completedDistanceUserMarkedComplete: ', completedDistanceUserMarkedComplete)



            if (dailyRuns[i]) {
                dailyRunsNewCurrent.push({
                    "day_name": dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "completed_distance": (completedDistanceUserMarkedComplete && !convertedDistance && completedDistanceUserMarkedComplete) || convertedDistance ? convertedDistance : 0,
                    "planned_run_type": dailyRuns[i]?.run_type,
                    "cross_training": dailyRuns[i]?.cross_training,
                    "planned_pace": planned_pace,
                    "completed_pace": paceDuration || 'NA',
                    "completed_heart_rate": totalAvgHeartRate || 'NA',
                    "notes": weekNotes[i] ? weekNotes[i]?.notes : '',
                })

                !configRunDataParams.xt && delete dailyRunsNewCurrent[i].cross_training
            }
        })

        setIsLoadingData(false)

        weekDataSelected.daily_runs = JSON.stringify(dailyRunsNewCurrent)
        corosData && corosData.length && setWeekDataSelected({ current: weekDataSelected, upcoming: weekDataUpcoming })

    }

    const getPlanSelectedWeekInfo = async () => {

        console.log('>>> getting plan info for week: ', weekNumSelected)
        // console.log('>>> weekNumSelected: ', weekNumSelected)
        // console.log('!!! planIdActive: ', planIdActive)

        const abortController = new AbortController()
        const customPaces = await GetUserCustomPace(planIdActive, abortController.signal).then((res: any) => {
            console.log('!!! customPace: ', res?.data?.result)
            return res?.data?.result

        }).catch(err => {
            console.log('!!! customPace err: ', err)
        })


        let hasGarminData = false

        const selectedDay = selectedWeekCurrent ? moment().format('YYYY-MM-DD') : moment().add(1, 'week').format('YYYY-MM-DD')

        // const weekNumSelected = weekNumSelected != '' ? weekNumSelected : weekNumCurrent

        const weekDataSelected = dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected) && dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected)[0]
        const weekDataUpcoming = dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected + 1) && dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumSelected + 1)[0]

        const weekDataCurrent = dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumCurrent) && dataUserTrainingPlans?.training?.filter((x: any) => x.week == weekNumCurrent)[0]

        const weekDateStart = dataRace?.training_plan.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        const planDetailsMoreInfo = dataRace?.training_plan.filter((x: any) => x.week == weekNumSelected)

        // to implem reflecting day reordering
        // put all mar ids into an array
        // const marIds = planDetailsMoreInfo.map((x: any) => x.activitiesValue?.marathonPaces?.map((y: any) => y.mar_id)).flat()
        // foreach dailyRuns, append the mar_id
        // get overrideWeek data
        // sort dailyRuns by overrideWeek's mar_id

        const isDev = env.includes('DEV')
        let userProfile = await GetSearchedAccountProfile(userIdSelected)
        userProfile = userProfile?.data?.result[0]
        let isKm = false;
        if (userProfile) {
            isKm = userProfile?.distance_unit == 'km' ? true : false
        }

        // customPaces && console.log('>>> customPaces: ', customPaces)
        //coros data
        const corosData = await getUserWorkoutsByRange(dataUserTrainingPlans?.user_id, weekDateStart, moment(weekDateStart).add(1, 'week').format('YYYY-MM-DD'))
        if (corosData && corosData.length > 0) {
            await getCorosDailyRuns(weekDateStart, corosData, isKm)
            setWatchType('Coros')
        }
        else
            // can refactor to have an endpoint where garmin details are part/inside of the fetched trainingPlanInfo
            customPaces && getGarminDetailsSelected(dataUserTrainingPlans?.user_id).then(async (userGarminDetails: any) => {

                console.log('!!! GD for w: ', weekDataSelected)

                // console.log('>>> userGarminDetails: ', userGarminDetails)

                // const garminID = '9239c1eb-5bfd-490a-8459-47b9ccf3d66d'
                const garminID = userGarminDetails?.garmin_id

                const currentActivity = await getReq(`/v2/garmin/activities/details?user_id=${garminID}&activity_date_from=${moment(weekDateStart).format('YYYY-MM-DD')}&activity_date_to=${moment(weekDateStart).endOf('week').format('YYYY-MM-DD')}`)

                // temporarily using an early date to get some garmin activity for testing purposes
                // moment().startOf('week').format('YYYY-MM-DD') to get this week's activities
                const pastActivity = await getReq(`/v2/garmin/activities/details?user_id=${garminID}&activity_date_from=${'2023-05-05'}&activity_date_to=${moment().endOf('week').format('YYYY-MM-DD')}`)


                console.log('>>> pastActivity: ', pastActivity)
                console.log('>>> currentActivity: ', currentActivity)


                const dailyRuns = weekDataSelected?.daily_runs ? JSON.parse(weekDataSelected?.daily_runs) : []
                console.log('>>> dailyRuns: ', dailyRuns)

                const dailyRunsNewCurrent: any[] = []
                let mergedData: any[] = []

                // console.log('>>> dataUserTrainingPlans: ', dataUserTrainingPlans)

                // const isKm = dataUserTrainingPlans?.is_km

                const currentActivityData = currentActivity.data.result

                // console.log('>>> currentActivityData: ', currentActivityData)

                hasGarminData = currentActivityData.length > 0
                !hasGarminData && console.log('>>> no currentActivity')

                let hasActivityFiltered = false

                // if you want to only have a run data if the user has synced details, then use this instead
                // currentActivity.data.result.forEach
                let i = 0
                if (currentActivity || pastActivity) for(const day of dailyRuns) {

                    const garminDataRunning = currentActivityData.filter((x: any) => x.activity_details?.summary?.activityType?.toLowerCase().includes('run') || x.activity_details?.summary?.activityType?.toLowerCase().includes('treadmill') || x.activity_details?.summary?.activityName?.toLowerCase().includes('run') || x.activity_details?.summary?.activityName?.toLowerCase().includes('treadmill'))
                    // console.log('>>> garminDataRunning: ', garminDataRunning)

                    const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
                    console.log('>>> thisDate: ', thisDate)

                    const allActivities = pastActivity.data.result
                    // incase fetching data from the selected week yielded no results, we try getting from all activities data
                    const activityFallbackThisDate = !hasGarminData && allActivities && allActivities.filter((x: any) => x.activity_date == thisDate)
                    const activityFallbackFilter = activityFallbackThisDate && activityFallbackThisDate.filter((x: any) => x.activity_details?.summary?.activityType?.toLowerCase().includes('run') || x.activity_details?.summary?.activityType?.toLowerCase().includes('treadmill') || x.activity_details?.summary?.activityName?.toLowerCase().includes('run') || x.activity_details?.summary?.activityName?.toLowerCase().includes('treadmill'))
                    console.log('>>> activityfallbackFilter: ', activityFallbackFilter)

                    const garminDataOnDate = garminDataRunning && garminDataRunning?.find((x: any) => x.activity_date == thisDate) ? garminDataRunning?.find((x: any) => x.activity_date == thisDate) : activityFallbackFilter
                    // const garminDataOnDate = activityFallbackFilter ? activityFallbackFilter : garminDataRunning && garminDataRunning?.find((x: any) => x.activity_date == thisDate) 
                    console.log('>>> garminDataOnDate: ', garminDataOnDate)


                    // const garminDataMultipleOnDate = currentActivityData?.filter((x: any) => x.activity_date == thisDate)
                    const garminDataMultipleOnDate = activityFallbackFilter && activityFallbackFilter.length > 1 ? activityFallbackFilter : garminDataRunning?.filter((x: any) => x.activity_date == thisDate)
                    console.log('!!!>>> garminDataMultipleOnDate: ', garminDataMultipleOnDate)

                    if (activityFallbackFilter && activityFallbackFilter.length > 0) hasActivityFiltered = true
                    activityFallbackFilter && console.log('!!! fallback-filter garminDataOnDate: ', garminDataOnDate)

                    const hasMultipleSyncedActivity = garminDataMultipleOnDate.length > 1
                    // hasMultipleSyncedActivity && console.log('>>> garminDataMultipleOnDate: ', garminDataMultipleOnDate)


                    const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'
                    const pacesOnThisDay = dataRace?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

                    const plannedPace = dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(pacesOnThisDay, plannedDistance, dataRace, userIdSelected, customPaces)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(pacesOnThisDay, plannedDistance, dataRace, userIdSelected, customPaces)?.paceSpeed)) + ' /mile' || 'NA'
                    // const plannedPace = buildPaceInitial(marathonPaces, plannedDistance, dataRace, userIdSelected)?.paceSpeed

                    const completedDistance = garminDataOnDate && (isKm ? garminDataOnDate?.activity_details?.summary?.distanceInMeters * 0.001 : garminDataOnDate?.activity_details?.summary?.distanceInMeters * 0.000621371)
                    console.log('!!!>>> completedDistance: ', completedDistance)
                    
                    const completedPace = garminDataOnDate?.activity_details?.summary?.averagePaceInMinutesPerKilometer

                    const completedDistanceUserMarkedComplete = weekNotes[i] && weekNotes[i]?.completed === 1 && plannedDistance
                    console.log('>>> completedDistanceUserMarkedComplete: ', completedDistanceUserMarkedComplete)


                    let dayDataUserCompleted: any = []
                    let dayDataPlanDetails: any = []

                    dayDataPlanDetails = {

                        "day_name": dayOrder[i] || dailyRuns[i]?.day_name,
                        "planned_distance": plannedDistance,
                        "planned_pace": plannedPace,

                        "planned_run_type": dailyRuns[i]?.run_type,
                        "notes": weekNotes[i] ? weekNotes[i]?.notes : '',
                        // "cross_training": dailyRuns[i]?.cross_training,
                    }


                    hasMultipleSyncedActivity
                        ? garminDataMultipleOnDate.forEach((x: any, i: number) => {

                            // just in case we want the keys to be numbered
                            const keyNameDistance = `${'completed_distance_' + i}`
                            const keyNamePace = `${'completed_pace_' + i}`
                            const keyNameHeartRate = `${'heartRate_' + i}`

                            const completedDistance = isKm ? x?.activity_details?.summary?.distanceInMeters * 0.001 : x?.activity_details?.summary?.distanceInMeters * 0.000621371
                            const completedPace = x?.activity_details?.summary?.averagePaceInMinutesPerKilometer

                            dayDataUserCompleted.push({
                                'completed_distance': (completedDistanceUserMarkedComplete && !completedDistance && completedDistanceUserMarkedComplete) || completedDistance.toFixed(2) || x?.activity_details?.summary?.distanceInMeters,
                                'completed_pace': (isKm ? convertMinutesToMinutesAndSeconds(completedPace) + ' /km' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(completedPace)) + ' /mile') || x?.activity_details?.summary?.averagePaceInMinutesPerKilometer || 'NA',
                                'completed_heart_rate': x?.activity_details?.summary?.averageHeartRateInBeatsPerMinute || 'NA',
                            })

                        })
                        : dayDataUserCompleted = {
                            "completed_distance": (completedDistanceUserMarkedComplete && !completedDistance && completedDistanceUserMarkedComplete) || (completedDistance ? completedDistance.toFixed(2) : '0'),
                            "completed_pace": completedPace ? isKm ? convertMinutesToMinutesAndSeconds(completedPace) + ' /km' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(completedPace)) + ' /mile' : 'NA',
                            "completed_heart_rate": garminDataOnDate?.activity_details?.summary?.averageHeartRateInBeatsPerMinute || 'NA',
                        }

                    console.log('>>> dayDataUserCompleted: ', dayDataUserCompleted)


                    let multipleRunningActivity: any = []
                    if (hasMultipleSyncedActivity) multipleRunningActivity = { running_activity: dayDataUserCompleted }

                    hasMultipleSyncedActivity && console.log('>>> day: ', dailyRuns[i]?.day_name)
                    hasMultipleSyncedActivity && console.log('>>> multiple garminDataRunning: ', multipleRunningActivity)
                    hasMultipleSyncedActivity ? mergedData = { ...dayDataPlanDetails, ...multipleRunningActivity } : mergedData = { ...dayDataPlanDetails, ...dayDataUserCompleted }

                    dailyRunsNewCurrent.push(mergedData)

                    // just logging
                    // const dailyRunsBuildInProgress = [...dailyRunsNewCurrent]
                    // console.log('!!!!!! building dailyRuns... ', dailyRunsBuildInProgress)

                    !configRunDataParams.xt && delete dailyRunsNewCurrent[i].cross_training

                    i++
                }

                console.log('>>> dailyRunsNewCurrent: ', dailyRunsNewCurrent)

                if (!hasGarminData && !hasActivityFiltered) {
                    console.log('>>> searching apple data')
                    buildDataRunAppleDetails(weekDateStart, weekDataSelected, weekDataUpcoming)
                } else setIsLoadingData(false)

                if (hasGarminData || hasActivityFiltered) setWatchType('Garmin')

                // messes up assigning of dayDataUserCompleted with dayDataPlanDetails ???
                // const sortedRuns = dailyRunsNewCurrent?.sort((a: any, b: any) => {
                //     return dayOrder.indexOf(a.day_name) - dayOrder.indexOf(b.day_name)
                // })
                console.log('!!! no sort')


                weekDataSelected.daily_runs = JSON.stringify(dailyRunsNewCurrent)

                currentActivity && setWeekDataSelected({ current: weekDataSelected, upcoming: weekDataUpcoming })
            })

    }


    const buildDataRunAppleDetails = (weekDateStart: any, weekDataSelected: any, weekDataUpcoming: any) => {

        setIsLoadingData(true)

        console.log('>>> building apple details...')


        const dailyRunsNewCurrentApple: any[] = []
        const dailyRuns = weekDataSelected?.daily_runs ? JSON.parse(weekDataSelected?.daily_runs) : []

        dailyRuns.forEach((x: any, i: any) => {

            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
            const currentDate = moment().format('YYYY-MM-DD')
            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'
            const isKm = dataUserTrainingPlans?.is_km

            const marathonPaces = dataRace?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            // console.log('>>> marathonPaces: ', marathonPaces)
            // console.log('>>> thisDate: ', thisDate)

            GetAppleHealtActivity(userIdSelected, thisDate).then(res => {
                // console.log('>>> GetAppleHealtActivityData res: ', res)

                const appleActivityResult = res?.data?.result?.length > 0 && res?.data?.result[0]

                appleActivityResult && console.log('!!! appleActivityResult: ', appleActivityResult)

                const appleActivityData = appleActivityResult && JSON.parse(appleActivityResult?.activity)

                // might replace || to &&
                const appleActivityRunningData = appleActivityData.length > 0 && appleActivityData.find((x: any) => x.activityName.toLowerCase().includes('running') || x.distanceInMeters > 5)
                // const appleActivityRunningData = appleActivityData.length > 0 && appleActivityData[0]

                // console.log('>>> heartRate: ', appleActivityRunningData.heartRate)

                const hasAppleDataSynced = appleActivityRunningData?.distanceInMeters || appleActivityRunningData?.pace
                console.log('>>> hasAppleDataSynced: ', hasAppleDataSynced)

                const completedDistanceUserMarkedComplete = weekNotes[i] && weekNotes[i]?.completed === 1 && plannedDistance


                dailyRunsNewCurrentApple.push({

                    "day_name": dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "completed_distance": (completedDistanceUserMarkedComplete && !appleActivityRunningData.distanceInMeters && completedDistanceUserMarkedComplete) || appleActivityRunningData.distanceInMeters ? !isKm ? convertMetersToMiles(appleActivityRunningData.distanceInMeters).toFixed(2) : convertMetersToKm(appleActivityRunningData.distanceInMeters).toFixed(2) : '0',
                    "planned_run_type": dailyRuns[i]?.run_type,
                    // "cross_training": dailyRuns[i]?.cross_training,
                    "planned_pace": dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(marathonPaces, plannedDistance, dataRace, userIdSelected)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(marathonPaces, plannedDistance, dataRace, userIdSelected)?.paceSpeed)) + ' /mile' || 'NA',
                    "completed_pace": appleActivityRunningData?.pace ? isKm ? convertMinutesToMinutesAndSeconds(appleActivityRunningData.pace * 1000) + ' /km' : convertMinutesToMinutesAndSeconds(convertMinPerMeterToMinPerMile(appleActivityRunningData.pace)) + ' /mile' : 'NA',
                    "completed_heart_rate": appleActivityRunningData.heartRate ? appleActivityRunningData.heartRate : 'NA',
                    "notes": weekNotes[i] ? weekNotes[i]?.notes : '',
                })

                hasAppleDataSynced && setDataAppleActivity({ current: dailyRunsNewCurrentApple, upcoming: weekDataUpcoming })

                setIsLoadingData(false)

                // console.log('>>> dailyRunsNewCurrentApple: ', dailyRunsNewCurrentApple)

            }).catch(err => {
                console.log('>>> GetAppleHealtActivityData err: ', err)
            })
        })
    }


    const changePlanIdActive = () => {

        const allPlans = userTrainingPlans?.data?.result || []
        setPlanIdActive(allPlans[allPlans.length - 1]?.plan_id)
    }


    // endpoint currently getting only logged in users garmin details
    // make new endpoint that passes in an input of a user_id
    const getGarminDetails = async () => {
        const response = await getReq('/v2/garmin/user')
        const data = response.data.result[0]
        return data
    }


    const getGarminDetailsSelected = async (userId: number) => {
        const response = await getReq(`/v2/garmin/user/id?userId=${userId}`)
        const data = response.data.result[0]
        return data
    }


    const compileNotes = async () => {

        setIsLoadingData(true)

        const abortController = new AbortController()
        const userNotes: any = []

        dayOrder.forEach((day: any, i: any) => {

            // dayId = marId
            const dayId = dataRace?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathon?.id
            console.log('!!!>>> getting notes, dayId', dayId)

            GetUserNotes(planIdActive, dayId, abortController.signal)
                .then((res: any) => {
                    const notes = res?.data?.result[0]?.comments
                    const markedCompleted = res?.data?.result[0]?.wo_complete
                    console.log('[ userId, marId ]', [planIdActive, dayId])
                    console.log('!!! userNotes res', res)

                    userNotes.push({ day: day, notes: notes, completed: markedCompleted })

                    if (userNotes.length == dayOrder.length) {

                        const sortedNotes = userNotes?.sort((a: any, b: any) => {
                            return dayOrder.indexOf(a.day_name) - dayOrder.indexOf(b.day_name)
                        })

                        setWeekNotes(sortedNotes)
                    }
                    // return comments

                }).catch(err => {
                    console.log('!!! userNotes err', err)
                })
        })
    }


    const handleButtonNextResponse = () => stepNumberAiResponse < 3 && setStepNumberAiResponse((prev: number) => prev + 1)

    const handleButtonPrevResponse = () => stepNumberAiResponse > 1 && setStepNumberAiResponse((prev: number) => prev - 1)


    const handleGetPromptWeekOutlook = async () => {

        // getUsersByEmail('ceaz')

        // changePlanIdActive()

        // getUserPlanInfo()

        // getUsersWithActivePlans()
    }


    const handleChangeUserSelected = () => {

        if (modeManualSearch) {
            // setHasFetchedUsers(true)
            // getUserPlanInfo()
            return
        }

        const planUserSelected = planListAllActiveInfo.find((x: any) => x.plan_info?.account?.email == userEmailSelected)?.plan_info
        // console.log('>>> planUserSelected: ', planUserSelected)

        const uInfo = convertToLegacyTrainingInfo(planUserSelected) || null
        if (!uInfo) return

        setDataRace(uInfo)
        setDataUserTrainingPlans(planUserSelected)

        // console.log('>>> dataRace: ', uInfo)
        // console.log('>>> dataUserTrainingPlans: ', planUserSelected)

        // console.log('training', planUserSelected?.training)

        // const weekNumSelected = dataRace?.training_plan.find((x: any) => x.monthData?.dateVal == selectedDay)?.week
        const weekNumCurrent = uInfo?.training_plan.find((x: any) => x.monthData?.dateVal == moment().format('YYYY-MM-DD'))?.week

        // console.log('>>> weekNumCurrent: ', weekNumCurrent)

        setWeekNumSelected(weekNumCurrent)

    }


    const handleChangePromptStep = (step: any) => {

        const stepNum = step.match(/-?\d+(\.\d+)?/)
        setPromptStep(stepNum ? parseInt(stepNum[0]) : promptStep)

    }

    const handleReset = () => {
        setPromptStep(1)
        setParsedAIResponse('')
        setAiResponsePerStep(['', '', ''])
    }

    const parseAIresponse = (response: any) => {
        const headline = response?.headline || ""
        const recap = response?.recap || ""

        if (headline && recap) {
            const parsedResponse: string = headline + '\n' + recap as string
            return parsedResponse
        }
        return ''
    }


    const handleSubmitPrompt = async () => {

        // console.log('>>> promptFormText: ', promptFormText)

        setIsLoadingData(true)

        const editedPrompt = isEditedPrompt ? promptFormText : ''

        console.log('!!! weekDataSelected: ', weekDataSelected)
        console.log('!!! dataUserTrainingPlans: ', dataUserTrainingPlans)
        console.log('!!! promptDefault: ', promptDefault)

        fetchResponseWeekOutlook(dataUserTrainingPlans, weekDataSelected, promptDefault, editedPrompt, selectedGPTModel).then(res => {
            setWeekOutlookMessage(res)
            // week data should now be for the upcoming week
            setSelectedWeekCurrent(false)

            aiResponsePerStep[promptStep - 1] = res
            console.log(JSON.stringify(res))
            setAiResponsePerStep(aiResponsePerStep)

            setStepNumberAiResponse(promptStep)

            setPromptStep((prev: number) => prev + 1)
            // then useEffect triggers to get the next prompt

            setIsLoadingData(false)
        })
    }

    const computeTokensTotal = (data: any) => {
        let total = 0;
        data.forEach((msg: any) => {
            if (msg.total_tokens)
                total += msg.total_tokens
        })

        const number = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        // return number.format(total).split('.')[0].replace('$', '') + ' tokens'
        return ''
    }


    // implem function for dynamic # of steps
    const generateHeadersPromptSteps = () => {

        const headerSteps = []

        // promptTotalSteps.map((x: any, i: number) => headerSteps.push(`Step ${i + 1}`))

        return ['Step 1', 'Step 2', 'Step 3']
    }

    const convertMinutesToHoursAndMinutes = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60); // Calculate whole hours
        const minutes = totalMinutes ? Math.floor(totalMinutes % 60) : 0         // Calculate remaining minutes
        return `${hours} hrs ${minutes} mins`;
    }

    const convertToMinPerMile = (minPerKm: any) => {

        if (isNaN(Number(minPerKm))) return minPerKm

        minPerKm = minPerKm + ''
        minPerKm = minPerKm.replace('min/km', '')

        const kmToMile = 0.621371; // Conversion factor from kilometers to miles
        const paceInMinPerMile = minPerKm / kmToMile

        return paceInMinPerMile

        // const returnedPace = !isNaN(Number(paceInMinPerMile.toFixed(2))) ? paceInMinPerMile.toFixed(2) : 0
        // return returnedPace + ' min/mile'

    }

    const convertMinPerMeterToMinPerMile = (minPerMeter: any) => {

        const metersPerMile = 1609.344; // Conversion factor: meters in a mile
        const paceInMinPerMile = minPerMeter * metersPerMile
        return paceInMinPerMile
    }


    const convertMetersToMiles = (meters: number) => {
        const metersPerMile = 1609.344
        const miles = meters / metersPerMile
        return miles
    }

    const convertMetersToKm = (meters: number) => {
        const metersPerKm = 1000
        const km = meters / metersPerKm
        return km
    }

    const convertMinutesToMinutesAndSeconds = (minutes: any) => {

        if (isNaN(Number(minutes))) return minutes

        const wholeMinutes = Math.floor(minutes); // Get the whole minutes
        const seconds = Math.round((minutes - wholeMinutes) * 60); // Convert fractional part to seconds
        return `${wholeMinutes} min ${seconds} sec`;
    }


    useEffect(() => {
        handleGetPromptWeekOutlook()
    }, [])


    useEffect(() => {

        weekDataSelected && fillPromptsWeeklyOutlook()
        // weekDataSelected && setIsLoadingData(false)

    }, [weekDataSelected])


    // useEffect(() => {
    //     fillPromptsWeeklyOutlook()
    // }, [promptStep])


    useEffect(() => {

        // console.log('selected week num: ', weekNumSelected)

        dataRace && dataUserTrainingPlans && setIsLoadingData(true)
        dataRace && dataUserTrainingPlans && compileNotes()
        handleReset()
    }, [weekNumSelected])


    useEffect(() => {

        console.log('!!! weekNotes: ', weekNotes)
        weekNotes && weekNotes.length && getPlanSelectedWeekInfo()

    }, [weekNotes])


    useEffect(() => {

        setAiResponsePerStep(['', '', ''])
        handleChangeUserSelected()

    }, [userEmailSelected])

    useEffect(() => {

        displayPromptConfig && setPromptFormText(promptInitialState)
        !displayPromptConfig && promptBuiltState && setPromptFormText(promptBuiltState)

    }, [displayPromptConfig, promptBuiltState, promptInitialState])

    useEffect(() => {

        planIdActive && getUserPlanInfo()

    }, [planIdActive])

    useEffect(() => {
        const value = parseAIresponse(aiResponsePerStep[promptStep - 2])
        setParsedAIResponse(value)
        fillPromptsWeeklyOutlook(value)
    }, [promptStep, aiResponsePerStep, stepNumberAiResponse])

    useEffect(() => {

        // console.log('configRunDataParams: ', configRunDataParams)

        setWeekDataSelected(null)

        dataRace && dataUserTrainingPlans && setIsLoadingData(true)
        dataRace && dataUserTrainingPlans && getPlanSelectedWeekInfo()

    }, [configRunDataParams])


    useEffect(() => {

        if (dataAppleActivity?.current?.length > 5) {

            setWatchType('Apple')

            const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

            const sortedRuns = dataAppleActivity?.current?.sort((a: any, b: any) => {
                return dayOrder.indexOf(a.day_name) - dayOrder.indexOf(b.day_name)
            })

            console.log('!!!!!! sortedRuns: ', sortedRuns)

            weekDataSelected.daily_runs = JSON.stringify(sortedRuns)
            setWeekDataSelected({ current: weekDataSelected, upcoming: dataAppleActivity?.upcoming })
        }

    }, [dataAppleActivity])


    const renderSidePanelPromptingConfig = () => <>

        <RSButton secondary sx={{ mb: 4 }}
            onClick={() => {
                setWeekNumSelected(null)
                setWeekNotes([])
                setPlanIdActive(null)
                setModeManualSearch(true)
                setHasFetchedUsers(false)
                setUserEmailSelected(null)
                setUserIdSelected(null)
                setDataUserTrainingPlans(null)
                setDataRace(null)
            }}
        >Back</RSButton>

        <FormControl sx={{ m: 1 }}>
            <Input inputProps={{ enterkeyhint: 'search' }} placeholder={dataUserTrainingPlans ? dataUserTrainingPlans?.account?.email : 'User Email'}
                // onKeyDown={async (e) => e.key == 'Enter' ? console.log('helo') : undefined}
                // onChange={(e) => setSearchValue(e.target.value)}
                value={dataUserTrainingPlans && dataUserTrainingPlans?.account?.email}
                sx={{ py: '0.6rem' }} /
            >
        </FormControl>

        {/* user email selector dropdown, showing only users with active plans */}
        {/* <FormControl sx={{ m: 1, minWidth: 120 }} >
            <InputLabel id="demo-simple-select-helper-label">User Email</InputLabel>
            <Select
                value={userEmailSelected}
                label="User Email"
                onChange={(e) => setUserEmailSelected(e.target.value)}
                sx={{ height: '50px', backgroundColor: 'white' }}
            >
                {userEmailsListActive?.map((id: any, index: number) => (<MenuItem key={index} value={id} style={{ border: 'none' }}>{id}</MenuItem>))}
            </Select>
        </FormControl> */}

        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <div id="demo-simple-select-helper-label">Week</div>
            <Select
                value={weekNumSelected}
                onChange={(e) => setWeekNumSelected(e.target.value)}
                sx={{ height: '50px', backgroundColor: 'white' }}
            >
                {dataUserTrainingPlans?.training?.map((x: any, i: number) => <MenuItem key={i} value={x.week} style={{ border: 'none' }}>{x.week}</MenuItem>)}
            </Select>
        </FormControl>

        <div style={{ margin: '2rem 10px' }}>
            <Table responsive hover size="sm">
                <thead>
                    <tr>
                        <th colSpan={2}>Week Start</th>
                    </tr>
                    <tr><td>{dataRace?.race_date && dataRace.training_plan?.find((day: any) => day.week == weekNumSelected)?.monthData?.dateVal
                        && moment(dataRace.training_plan?.find((day: any) => day.week == weekNumSelected)?.monthData?.dateVal).format('MMMM DD, YYYY') || ''}</td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover size="sm">
                <thead>
                    <tr>
                        <th colSpan={2}>Race Date</th>
                    </tr>
                    <tr><td>{dataRace?.race_date && moment(dataRace?.race_date).format('MMMM DD, YYYY') || ''}</td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover size="sm" style={{ marginTop: '0.5rem' }}>
                <thead >
                    <tr>
                        <th colSpan={2} >Race Name</th>
                    </tr>
                    <tr><td>{dataUserTrainingPlans?.event_name || ''}</td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover size="sm" style={{ marginTop: '0.5rem' }}>
                <thead >
                    <tr>
                        <th colSpan={2} >Race Goal</th>
                    </tr>
                    <tr><td>{dataUserTrainingPlans?.target_time ? convertMinutesToHoursAndMinutes(dataUserTrainingPlans?.target_time) : ''}</td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover size="sm" style={{ marginTop: '0.5rem' }}>
                <thead >
                    <tr>
                        <th colSpan={2} >Age</th>
                    </tr>
                    <tr><td>{dataUserTrainingPlans?.profile?.dob && moment().diff(moment(dataUserTrainingPlans?.profile?.dob), 'years') || ''}</td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover size="sm" style={{ marginTop: '0.5rem' }}>
                <thead >
                    <tr>
                        <th colSpan={2} >Long Run Day</th>
                    </tr>
                    <tr><td>{dataUserTrainingPlans?.longrun_day || ''}</td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover size="sm" style={{ marginTop: '0.5rem' }}>
                <thead >
                    <tr>
                        <th colSpan={2} >Unit</th>
                    </tr>
                    <tr><td>{dataUserTrainingPlans?.is_km ? 'Km' : 'Miles' || ''}</td>
                    </tr>
                </thead>
            </Table>
            <Table responsive hover size="sm" style={{ marginTop: '0.5rem' }}>
                <thead >
                    <tr>
                        <th colSpan={2} >Watch Type</th>
                    </tr>
                    <tr><td>{watchType}</td>
                    </tr>
                </thead>
            </Table>

        </div>

    </>


    const renderListUsersEmailFiltered = () => userListFiltered.map((x: any) =>

        <Grid key={x.account_id} sx={{ width: '100%', height: '3rem', mb: '0.5rem', ml: userEmailSelected == x.email ? '0rem' : '0rem', fontFamily: 'Poppins-Light !important', color: '#555', p: 0, overflow: 'hidden', transitionDuration: '300ms' }}>
            <RSButton customized secondary card sx={{ width: '100%', height: '100%', overflow: 'hidden', backgroundColor: userEmailSelected == x.email ? '#E4F0E4' : 'white', border: userEmailSelected == x.email ? '1px solid #4CAF50' : '' }}
                onClick={() => {
                    setUserEmailSelected(x.email)
                    setUserIdSelected(x.account_id)
                    console.log(x.email)
                    console.log(x.account_id)
                }}>
                {x.email}
            </RSButton>
        </Grid>

    )


    const renderListUsersPlan = () => userTrainingPlans && userTrainingPlans?.data?.result?.length > 0 ? userTrainingPlans?.data?.result.map((x: any) =>

        <Grid key={x.plan_id} sx={{ width: '100%', height: '3rem', mb: '0.5rem', fontFamily: 'Poppins-Light !important', color: '#555', p: 0, overflow: 'hidden', transitionDuration: '300ms' }}>
            <RSButton customized secondary card sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
                onClick={() => {
                    setPlanIdActive(x.plan_id)
                    console.log(x.event_name)
                }}>
                {x.event_name}
            </RSButton>
        </Grid>

    )
        :
        userEmailSelected && userEmailSelected != '' && <Grid sx={{ width: '100%', height: '3rem', mb: '0.5rem', fontFamily: 'Poppins-Light !important', color: '#555', p: 0, overflow: 'hidden', transitionDuration: '300ms' }}>
            <RSButton customized secondary card sx={{ width: '100%', height: '100%', overflow: 'hidden', color: '#999' }}>
                {!isLoadingPlans ? 'No Plans' : 'Loading...'}
            </RSButton>
        </Grid>



    const renderPromptArea = () => <>

        {/* <RSButton secondary sx={{ mb: 4 }}
            onClick={handleGetPromptWeekOutlook}>Click Me To Generate Prompt</RSButton> */}

        {/* <RSButton secondary sx={{ width: '8rem', height: '4rem', position: 'absolute', top: 0, right: 0 }}
            onClick={() => setDisplayPromptConfig(prev => !prev)}>{!displayPromptConfig ? <>Prompt<br />Config</> : <>Prompt<br />Testing</>}</RSButton> */}

        {/* <RSButton secondary sx={{ width: '8rem', height: '4rem', position: 'absolute', top: 0, right: 0 }}
            onClick={() => setDisplayConfigRunData(true)}>{<>Run Data<br />Config</>}</RSButton> */}

        <Form.Control
            style={{ marginBottom: '0.4rem' }}
            value={promptFormText}
            type="text"
            as="textarea"
            rows={24}
            placeholder=""
            onChange={(e) => {
                setPromptFormText(e.target.value)
                setIsEditedPrompt(true)
            }}
        // onBlur={() => handleUpdateSale("subtext")}
        // disabled={isLoading || isLoadingExercises}
        />

        {renderTableWeekSummary()}
        {renderTableUserNotes()}


        {!displayPromptConfig && renderButtonsPromptControls()}
        {displayPromptConfig && <div style={{ display: 'flex', flexDirection: 'row' }}>

            <RSButton secondary sx={{ width: '30rem', mr: 2 }} onClick={handlePromptRestoreDefault}>Restore Default</RSButton>
            <RSButton sx={{ backgroundColor: '#FFD823' }} onClick={handleDBsavePromptWeeklyOutlook}>Save Prompt Edits</RSButton>

        </div>}


    </>


    const renderTableWeekSummary = () => <Accordion defaultExpanded sx={{ mb: 2 }}>

        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
        >
            User Running Data
        </AccordionSummary>

        <AccordionDetails>

            <Table responsive hover size="sm">

                <thead>
                    {promptStep != 2 ?
                        <tr>
                            <th>Day</th>
                            <th>Run Type</th>
                            <th>Planned Distance</th>
                            <th>Completed Distance</th>
                            <th>Planned Pace</th>
                            <th>Completed Pace</th>
                            <th>Heart Rate</th>
                            {configRunDataParams.xt && <th>Cross Training</th>}
                        </tr>

                        :

                        <tr>
                            <th>Day</th>
                            <th>Run Distance</th>
                            <th>Run Type</th>
                            <th>Cross Training</th>
                        </tr>
                    }

                </thead>
                <tbody>
                    {promptStep != 2 ?
                        weekDataSelected?.current?.daily_runs && JSON.parse(weekDataSelected?.current?.daily_runs).map((x: any, i: number) => <tr key={i}>
                            <td>{x.day_name}</td>

                            <td>{x.planned_run_type}</td>

                            <td>{x.planned_distance || x.run_distance}</td>

                            <td>{x.running_activity && x.running_activity.length > 0 ? x.running_activity.map((userDetails: any, j: number) => <>
                                <tr>{userDetails.completed_distance}</tr>
                            </>)
                                :
                                x.completed_distance
                            }</td>
                            {/* <td>{x.completed_distance}</td> */}

                            <td>{x.planned_pace}</td>

                            <td>{x.running_activity && x.running_activity.length > 0 ? x.running_activity.map((userDetails: any, j: number) => <>
                                <tr>{userDetails.completed_pace}</tr>
                            </>)
                                :
                                x.completed_pace
                            }</td>
                            {/* <td>{x.completed_pace}</td> */}


                            <td>{x.running_activity && x.running_activity.length > 0 ? x.running_activity.map((userDetails: any, j: number) => <>
                                <tr>{userDetails.completed_heart_rate}</tr>
                            </>)
                                :
                                x.completed_heart_rate
                            }</td>
                            {/* <td>{x.completed_heart_rate}</td> */}


                            {configRunDataParams.xt && <td>{x.cross_training}</td>}
                        </tr>)

                        :
                        weekDataSelected?.upcoming?.daily_runs && JSON.parse(weekDataSelected?.upcoming?.daily_runs).map((x: any, i: number) => <tr key={i}>
                            <td>{x.day_name}</td>
                            <td>{x.planned_distance || x.run_distance}</td>
                            <td>{x.run_type || x.planned_run_type}</td>
                            <td>{x.cross_training}</td>
                        </tr>)

                    }
                </tbody>

            </Table>
        </AccordionDetails>
    </Accordion>


    const renderTableUserNotes = () => <Accordion defaultExpanded sx={{ mb: 2 }}>

        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
        >
            User Notes
        </AccordionSummary>

        <AccordionDetails>
            <Table responsive hover size="sm">
                <thead>
                    <tr>
                        <th style={{ width: '5rem', maxWidth: '6rem' }}>Day</th>
                        <th >Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {weekDataSelected?.current?.daily_runs && JSON.parse(weekDataSelected?.current?.daily_runs).map((x: any, i: number) => {
                        if (x.notes)
                            return <tr key={i}>
                                <td>{x.day_name}</td>
                                <td>{x.notes}</td>
                            </tr>
                    })
                    }
                </tbody>
            </Table>
        </AccordionDetails>
    </Accordion>


    const renderButtonsPromptControls = () => <Grid sx={{ display: 'flex' }}>
        <RSButton onClick={() => handleReset()}><ReplayRounded /> Reset</RSButton>
        <RSButton sx={{ ml: 2 }} onClick={() => setPromptStep((prev: number) => prev > 1 ? prev - 1 : prev)}><ArrowLeft /> Back</RSButton>
        <RSButton sx={{ ml: 2 }} onClick={() => setPromptStep((prev: number) => prev < 3 ? prev + 1 : prev)}>Next <ArrowRight /></RSButton>
        <RSButton sx={{ ml: 2 }} onClick={handleSubmitPrompt}><SendRounded /> Submit</RSButton>
    </Grid>



    const renderHeaderPanelAIresponse = () => {

        const isFirstStep = stepNumberAiResponse == 1
        const isLastStep = stepNumberAiResponse == 3

        return <>

            <div className='text-center' style={{ display: 'flex', width: '100%', alignContent: 'center', justifyContent: 'center', marginBottom: '0.2rem' }}>

                {/* <RSButton sx={{ position: 'absolute', width: '14rem', right: '0', top: '0', backgroundColor: '#DDFCFF', color: '#555' }}
                    onClick={() => setDisplayPopupAllResponses(true)}>Popup</RSButton> */}

                <i className="fa-solid fa-arrow-left" style={{ fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem', marginTop: '0.2rem', marginRight: '0.5rem', color: isFirstStep ? '#BBB' : 'black' }}
                    onClick={handleButtonPrevResponse}></i>
                <h4>Step {stepNumberAiResponse} Response</h4>
                <i className="fa-solid fa-arrow-right" style={{ fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem', marginTop: '0.2rem', marginLeft: '0.5rem', color: isLastStep ? '#BBB' : 'black' }}
                    onClick={handleButtonNextResponse}></i>
            </div>

        </>
    }

    const renderSidePanelAIresponse = () => {

        const isFirstStep = stepNumberAiResponse == 1
        const isLastStep = stepNumberAiResponse == 3

        return <>


            <div className='text-center' style={{ display: 'flex', flexDirection: 'column', width: '100%', alignContent: 'center', justifyContent: 'center', marginBottom: '1.5rem', marginTop: '0.4rem' }}>

                <Grid sx={{ fontFamily: 'Poppins-Medium', fontSize: '18px', lineHeight: '1em', mb: '0.4rem' }}>Response</Grid>
                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', marginTop: '0.4rem' }}>
                    {/* <i className="fa-solid fa-arrow-left" style={{ fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem', marginTop: '0.2rem', marginRight: '0.5rem', color: isFirstStep ? '#BBB' : 'black' }}
                        onClick={handleButtonPrevResponse}></i> */}
                    <Grid sx={{ fontFamily: 'Poppins-Medium', fontSize: '18px', lineHeight: '1em' }}>Step {stepNumberAiResponse}</Grid>
                    {/* <i className="fa-solid fa-arrow-right" style={{ fontSize: '1.6rem', fontWeight: 'bold', lineHeight: '1rem', marginTop: '0.2rem', marginLeft: '0.5rem', color: isLastStep ? '#BBB' : 'black' }}
                        onClick={handleButtonNextResponse}></i> */}
                </div>
            </div>

            {/* <RSButton secondary sx={{ color: '#555', borderRadius: '30px', marginBottom: '2rem' }}
                onClick={() => setDisplayPopupAllResponses(true)}>Popup</RSButton> */}

        </>
    }

    const renderAIresponseWeekOutlook = () => <>

        <Form.Control
            style={{ marginBottom: '1rem' }}
            value={parsedAIResponse}
            type="text"
            as="textarea"
            rows={20}
            placeholder=""
        />
    </>


    const renderPopupAiResponse = () => {

        const allResponses: any[] = []
        aiResponsePerStep.map((x: any, i: number) => {
            allResponses.push(`\nStep ${i + 1}`)
            allResponses.push(`\n\n` + x + `\n\n`)
        })

        return <>
            <Form.Control
                style={{ marginBottom: '1rem', height: '400%' }}
                value={allResponses}
                type="text"
                as="textarea"
                placeholder=""
            />
        </>
    }


    const renderConfigRunData = () => <Grid sx={{ height: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked={configRunDataParams.xt} onClick={() => setConfigRunDataParams({ ...configRunDataParams, xt: !configRunDataParams.xt })} />} label="Cross Training" />
            <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Completed Distance" />
            <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Completed Pace" />
            <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Heart Rate" />
        </FormGroup>

    </Grid>


    const renderConfigGPT = () => <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid xs={8} md={8} item>
            <InputLabel className='mt-2' id="demo-multiple-name-label">Select GPT Model</InputLabel>
            <Select className='w-100' placeholder='Select Program' value={selectedGPTModel} onChange={(e) => setSelectedGPTModel(e.target.value)} >
                {gptModels.map((gpt, index) => (<MenuItem key={index} value={gpt}>{gpt}</MenuItem>))}
            </Select>
        </Grid>
        <Grid xs={4} md={4} item>
            <InputLabel className='mt-2' id="demo-multiple-name-label">Temperature</InputLabel>
            <Grid container sx={{ maxWidth: '120px' }}>
                <RSInputAdornment value={gptTemp} setValue={setGPTTemp} label={''} arial='' />
            </Grid>
        </Grid>
    </Grid>


    const renderTokensUsed = () => <Grid container sx={{ mb: 3 }}>
        <Grid xs={12} md={12}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Token Usage:<span style={{ marginLeft: '5px' }}><b>{computeTokensTotal(promptsHistory.filter((x: any) => x.role === "output"))}</b></span>
                </AccordionSummary>
                <AccordionDetails>
                    {history.length > 0 ? (
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Output</th>
                                    <th>Input Tokens</th>
                                    <th>Output Tokens</th>
                                    <th>Total Tokens</th>
                                </tr>
                            </thead>
                            <tbody>
                                {promptsHistory.filter((x: any) => x.role === "output").map((msg: any, i: number) => (
                                    <tr key={i}>
                                        <td>{msg.role}</td>
                                        <td style={{ maxWidth: '300px' }} >{msg.content}</td>
                                        <td>{msg.input_token}</td>
                                        <td>{msg.output_token}</td>
                                        <td>{msg.total_tokens}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><b>Total</b></td>
                                    <td><b>{computeTokensTotal(promptsHistory.filter((x: any) => x.role === "output"))}</b></td>
                                </tr>
                            </tbody>
                        </Table>
                    ) : undefined}
                </AccordionDetails>
            </Accordion>
        </Grid>
    </Grid>


    const renderButtonsHeaderSteps = () => <Grid sx={{ mb: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>

        <SelectPill
            index={promptStep - 1}
            key={promptStep - 1}
            options={generateHeadersPromptSteps()}
            color={'#85E0F2'}
            onChange={e => handleChangePromptStep(e)}
        />
    </Grid>


    return <>
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>

            <Grid item xs={2}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ height: modeManualSearch ? '60vh' : '100%' }}>

                    {hasFetchedUsers ? renderSidePanelPromptingConfig()
                        :
                        showButtonsPlanSelectionMethod ? <>
                            <RSButton secondary sx={{ my: 2 }}
                                onClick={() => {
                                    !hasFetchedUsers && getUsersWithActivePlans()
                                    setHasFetchedUsers(true)
                                }}>Get Active Plans</RSButton>

                            <RSButton secondary onClick={() => setModeManualSearch(true)} sx={{ mb: 2 }}>Get My Plans</RSButton>
                        </>
                            :
                            <></>
                    }
                </div>
            </Grid>

            <Grid item xs={10}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0 mb-2' style={{ height: '100%' }}>

                    {modeManualSearch ? <>

                        <FormControl sx={{ m: 1 }}>
                            <Input autoFocus inputProps={{ enterkeyhint: 'search' }} placeholder="Search User Email"
                                onKeyDown={async (e) => e.key == 'Enter' ? handleSearchEnter(e) : undefined}
                                // onChange={(e) => setSearchValue(e.target.value)}
                                // value={searchValue}
                                sx={{ py: '0.6rem' }} /
                            >
                        </FormControl>


                        <Grid container spacing={2} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>

                            <Grid item xs={5} sx={{ height: '100%', backgroundColor: '#E6EBF0', p: 2, borderRadius: '10px', mr: 2 }}>

                                {renderListUsersEmailFiltered()}

                            </Grid>

                            <Grid item xs={6} sx={{ height: '100%', backgroundColor: '#E4F0E4', p: 2, borderRadius: '10px' }}>

                                {renderListUsersPlan()}

                            </Grid>

                        </Grid>

                    </>
                        :
                        <>
                            {/* {renderButtonsHeaderSteps()} */}

                            {renderConfigGPT()}

                            {renderTokensUsed()}

                            {renderPromptArea()}
                        </>
                    }

                </div>
            </Grid>

        </Grid>

        {!modeManualSearch && <Grid container spacing={1} sx={{ mb: '6rem' }} >

            <Grid item xs={2}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                    {renderSidePanelAIresponse()}
                </div>
            </Grid>

            <Grid item xs={10}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ height: '100%' }}>
                    {renderAIresponseWeekOutlook()}
                </div>
            </Grid>

        </Grid>}

        <div style={{ height: '40px' }}></div>

        <RSDrawer bottom open={displayPopupAllResponses} onClose={() => setDisplayPopupAllResponses(false)} popUpHeight="90%">

            {/* <RSAccordion sx={{ mt: 1 }} title={'Step' + stepNumberAiResponse} isActive={true} setActive={setActiveSetting} height={150}>
                <Grid container xs={12} sx={{ px: 2, py: 1, height: 'fit-content' }}>
                    fefe
                </Grid>
            </RSAccordion> */}

            {renderPopupAiResponse()}

        </RSDrawer>

        <RSDrawer bottom open={displayConfigRunData} onClose={() => setDisplayConfigRunData(false)} popUpHeight="20%" popUpWidth="40%">

            {renderConfigRunData()}

        </RSDrawer>

        <Loader active={isLoadingData} />
    </>
}

export default WeeklyOutlook