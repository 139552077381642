/* eslint-disable @typescript-eslint/no-unused-vars */

import { SendRounded, RestoreRounded } from '@mui/icons-material'
import { Box, Card, Grid, InputLabel } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Table } from 'react-bootstrap'
import { FaBoxArchive } from 'react-icons/fa6'
import { WeeklyOutlookSetting } from '../../api/v2/weekly-outlook/weekly-outlook.types'
import RSButton from '../../components/buttons/RSButton'
import RSInput from '../../components/input/RSInput'
import { H3 } from '../../components/text/RSTypography'
import { DeleteWeeklyOutlookSettings, GetWeeklyOutlookSettings, SaveWeeklyOutlookSettings } from '../../modules/weeklyOutlookActions'

const WeeklyOutlookSettings = () => {

    const [settings, setSettings] = useState<WeeklyOutlookSetting[]>([])
    const [selectedSetting, setSelectedSetting] = useState<WeeklyOutlookSetting | null>(null)
    const [settingValue, setSettingValue] = useState(selectedSetting?.value || '')
    const [settingType, setSettingType] = useState(selectedSetting?.type || '')
    const [isLoading, setIsLoading] = useState(false)

    const hasFetched = useRef(false);

    const getAllSettings = () => {
        setIsLoading(true)
        GetWeeklyOutlookSettings().then((res) => {
            if (res && res.data.result.length)
                setSettings(res.data.result as WeeklyOutlookSetting[])
            else
                setSettings([])

            setSelectedSetting(null)
            setIsLoading(false)
        })
    }

    const handleSubmit = () => {
        const data: WeeklyOutlookSetting = {
            type: settingType,
            value: settingValue
        }

        setIsLoading(true)
        SaveWeeklyOutlookSettings(data.type, data.value).then((res) => {
            if (res) {
                getAllSettings()
                setSettingType('')
                setSettingValue('')
            }
            setIsLoading(false)
        })
    }

    const handleDelete = (id: number) => {
        setIsLoading(true)
        DeleteWeeklyOutlookSettings(id).then((res) => {
            if (res) {
                getAllSettings()
            }
            setSelectedSetting(null)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (selectedSetting) {
            setSettingType(selectedSetting.type)
            setSettingValue(selectedSetting.value)
        }
    }, [selectedSetting])

    useEffect(() => {
        if (!hasFetched.current) {
            getAllSettings()
            hasFetched.current = true;
        }
    }, [])

    return (
        <Grid container spacing={1}>
            <Grid xs={12} md={12}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                    <Grid className='mb-1' container spacing={1}>
                        <Grid item xs={3}>
                            <h5><b>{`${selectedSetting ? 'Update' : 'Create New'} Setting`}</b></h5>
                        </Grid>
                    </Grid>

                    <Grid className='mb-1' container spacing={1}>
                        <Grid className='mt-1' item xs={3}>
                            <RSInput value={String(settingType)} setValue={setSettingType} title='Setting Name' />
                        </Grid>
                        <Grid className='mt-1' item xs={3}>
                            <RSInput value={String(settingValue)} setValue={setSettingValue} title={`Setting Value ${settingType.toLowerCase().includes('date') ? '(MM/DD/YYYY)' : ""}`} />
                        </Grid>
                        <Grid item xs={2}>
                            <RSButton disabled={isLoading || !settingType || !settingValue} className='mt-2' onClick={handleSubmit}><SendRounded />  {selectedSetting ? 'Update' : 'Save'} </RSButton>

                        </Grid>
                        <Grid item xs={2}>
                            <RSButton disabled={isLoading || !settingType && !settingValue} className='mt-2' onClick={() => {
                                setSettingType('')
                                setSettingValue('')
                                setSelectedSetting(null)
                            }}><RestoreRounded />Reset</RSButton>
                        </Grid>
                    </Grid>

                    <Table responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th></th>
                            </tr>
                        </thead >
                        <tbody>
                            {settings?.map((setting, index: number) => (
                                <tr onClick={() => setSelectedSetting(setting)} style={{ cursor: 'pointer' }} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{setting.type}</td>
                                    <td>{setting.value}</td>
                                    <td>{moment(setting.date_created).format('MM/DD/YY h:mm A')}</td>
                                    <td>{moment(setting.last_modified).format('MM/DD/YY h:mm A')}</td>
                                    <td><RSButton danger disabled={isLoading} tooltip='Delete Setting' onClick={async (e: any) => {
                                        e.stopPropagation(); handleDelete(setting.id as number)
                                    }} ><FaBoxArchive /></RSButton></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table >
                </div>
            </Grid>
        </Grid>
    )
}

export default WeeklyOutlookSettings