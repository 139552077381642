/* eslint-disable @typescript-eslint/no-unused-vars */

import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from "../training-plan/build/DaysOptions"
import functionRules from "./TrainingPlanFunctionRules.json"

const useManualCalculations = () => {

    const RUN_TYPES = ["Rest", "Base", "Progression", "Easy", "Tempo", "Interval", "Race Pace"]
    const CROSS_TRAININGS = ["None", "Strength Training", "Yoga", "Stretching"]

    const convertWeeklyMileageData = (data: any) => {

        // return data?.map((item: any) => ({
        //     Week: item.w,
        //     Long_run: item.lr,
        //     Weekly_mileage: item.m,
        //     Taper: item.t == 1 ? true : false,
        //     Recovery: item.r == 1 ? true : false
        // }));

        if (data) {
            const weeks = data[0]?.w;
            const mileage = data[0]?.m;
            const longRun = data[0]?.l;
            const taper = data[0]?.t;
            const recovery = data[0]?.r;

            const transformedData = weeks?.map((week: any, index: number) => ({
                Week: week,
                Long_run: longRun[index],
                Weekly_mileage: mileage[index],
                Taper: taper[index] === 1,
                Recovery: recovery[index] === 1
            }));

            return transformedData;
        }

        return [];
    }

    const convertDailyMileageData = (data: any) => {
        return data?.map((item: any) => ({
            week: item.w,
            weekly_mileage: item.m,
            sunday: item['1'],
            monday: item['2'],
            tuesday: item['3'],
            wednesday: item['4'],
            thursday: item['5'],
            friday: item['6'],
            saturday: item['7']
        }));
    }

    const convertRunTypesData = (data: any) => {
        return data?.map((item: any) => ({
            week: item.w,
            weekly_mileage: item.m,
            long_run: item.lr,
            days: item.ds.map((day: any) => ({
                day_name: DAYS_OF_WEEK[day.d - 1]?.toLowerCase(),
                run_distance: day.rd,
                run_type: RUN_TYPES[day.rt]
            }))
        }))
    }

    const convertCrossTrainingData = (data: any) => {
        return data?.map((item: any) => ({
            week: item.w,
            weekly_mileage: item.m,
            long_run: item.lr,
            days: item.ds.map((day: any) => ({
                day_name: DAYS_OF_WEEK[day.d - 1]?.toLowerCase(),
                run_distance: day.rd,
                run_type: RUN_TYPES[day.rt],
                cross_training: CROSS_TRAININGS[day.c]
            }))
        }))
    }

    const morphAddRunTypes = (prevData: any, AIresults: any) => {
        const data = prevData?.modified ?? prevData;

        return data?.map((item: any) => ({
            week: item?.week,
            weekly_mileage: item.weekly_mileage,
            days: DAYS_OF_WEEK.map((day: any, dI: number) => {

                const isUnassigned = AIresults.length ? false : true

                const runDistance = (item[day?.toLowerCase()] || item[day?.toLowerCase()] === 0) ? item[day?.toLowerCase()] : item?.days[dI]?.run_distance
                const runType = !isUnassigned && RUN_TYPES[Number(AIresults[item?.week - 1][dI + 1])] || (item[day?.toLowerCase()] == 0 ? 'Rest' : 'Base')

                const runTypeValidated = runType === 'Rest' && runDistance > 0 ? 'Base' : runType

                return ({
                    day_name: day,
                    run_distance: runDistance,
                    run_type: runTypeValidated
                })
            })
        }))
    }

    const appendCrossTrainingData = (prevData: any, AIresults: any) => {
        const data = prevData?.modified ?? prevData;
        return data?.map((item: any) => ({
            week: item.week,
            weekly_mileage: item.weekly_mileage,
            days: item.days.map((day: any, dI: number) => ({
                day_name: day.day_name,
                run_distance: day.run_distance,
                run_type: day.run_type,
                cross_training: AIresults.length > 0 ? CROSS_TRAININGS[Number(AIresults[item.week - 1][dI + 1])] : CROSS_TRAININGS[0]
            }))
        }))
    }

    const getInitialLongRun = (recentLongRun: number, raceType: string, isKm: boolean) => {
        const rules = functionRules.find((rule) => rule.type === raceType.toLowerCase());

        let initialLongRun = recentLongRun;
        let initMax = rules?.longRun?.initMax || 0;
        initMax = isKm ? Number(convertMilesToKm(initMax).toFixed()) : initMax;
        if (rules)
            if (recentLongRun >= initMax)
                initialLongRun = Math.round(recentLongRun * rules.longRun.initMaxPercentage)
            else
                initialLongRun = recentLongRun

        return initialLongRun
    }

    const convertMilesToKm = (miles: number) => {
        return miles * 1.60934;
    }

    const convertKmToMiles = (km: number) => {
        return km / 1.60934;
    }

    const getLongRunCapping = (maxRunPerWeek: number, raceType: string, isKm: boolean) => {

        let capping = maxRunPerWeek;

        const maxRun = isKm ? Number(convertKmToMiles(maxRunPerWeek).toFixed()) : maxRunPerWeek;
        const rules = functionRules.find((rule) => rule.type === raceType.toLowerCase());


        if (rules && rules.longRun?.capping.length) {
            const cappingList = rules?.longRun?.capping;

            for (const longRunCap of cappingList) {
                if (maxRun >= longRunCap.min && maxRun <= longRunCap.max) {
                    capping = longRunCap.cap;
                    break;
                }
            }
        }

        capping = isKm ? Number(convertMilesToKm(capping).toFixed()) : capping;
        return capping;
    }

    const applyRecoveryWeeks = (tableData: any, raceType: string) => {

        const { planTable, highestWeeklyMileage } = tableData;
        const rules = functionRules.find((rule) => rule.type === raceType.toLowerCase());

        const recoveryWeekMileage = Math.ceil(highestWeeklyMileage * (rules?.recovery.weekPercentage || 1));
        const recoveryWeekFinalMileage = Math.ceil(highestWeeklyMileage * (rules?.recovery.lastWeekPercentage || 1));
        for (let i = 0; i < planTable.length; i++) {
            // console.log(planTable[i])
            if ((i + 1) % 3 === 0 && planTable[i]?.Taper == false) {
                if (planTable[i + 1]?.Taper) {
                    if (raceType.toLowerCase() === 'full')
                        planTable[i].Weekly_mileage = recoveryWeekFinalMileage;
                    else
                        planTable[i].Weekly_mileage = recoveryWeekMileage;
                    planTable[i].Recovery = true
                } else {
                    planTable[i].Weekly_mileage = recoveryWeekMileage;
                    planTable[i].Recovery = true
                }

            } else {
                planTable[i].Recovery = false
            }
        }

        return planTable;
    }

    const getWithHighestWeeklyMileage = (planTable: any) => {
        let highestWeeklyMileage = 0;
        planTable.forEach((week: any) => {
            if (week.Weekly_mileage > highestWeeklyMileage) {
                highestWeeklyMileage = week.Weekly_mileage;
            }
        });

        return { planTable, highestWeeklyMileage };
    }

    const getHighestLongRun = (plan: any) => {
        return plan.reduce((max: any, current: any) => current.Long_run > max.Long_run ? current : max, plan[0]);
    }

    const applyTaperWeeks = (tableData: any, raceType: string) => {
        const isMaintenance = raceType.toLowerCase() === "maintenance";
        const { planTable, highestWeeklyMileage } = tableData;

        if (isMaintenance) {
            for (let i = 0; i < planTable.length; i++) {
                planTable[i].Taper = false;
            }
            const peakWeek = planTable[planTable.length - 3];
            const lastWeek = planTable[planTable.length - 1];
            lastWeek.Weekly_mileage = peakWeek.Weekly_mileage + (peakWeek.Weekly_mileage * 0.15);
            lastWeek.Long_run = peakWeek.Long_run + (peakWeek.Long_run * 0.15);

            return { planTable, highestWeeklyMileage };
        }
        const rules = functionRules.find((rule) => rule.type === raceType.toLowerCase());


        const taperRules = rules?.taper || [];

        if (planTable.length < 3)
            return { planTable, highestWeeklyMileage };

        for (const taperRule of taperRules) {
            const index = planTable.length - taperRule.weekOffset;
            if (index >= 0) {
                planTable[index].Weekly_mileage = Math.round(highestWeeklyMileage * taperRule.mileageFactor);
                planTable[index].Taper = true;
            }
        }

        return { planTable, highestWeeklyMileage }

    }

    const getLongRuns = (plan: any, raceType: string, maxRunPerWeek: number, recentLongRun: number, highestLongRun: any, isKm: boolean) => {
        let longRun = 0;
        const rules = functionRules.find((rule) => rule.type === raceType.toLowerCase());


        if (rules && raceType.toLowerCase() !== 'full') {
            const minRun = rules.longRun.weekly.minRun;
            const percentage = rules.longRun.weekly.maxPercentage;
            const peakPercentage = rules.longRun.weekly.peakPercentage;
            for (let i = 0; i < plan?.length; i++) {
                const peakWeeks = plan.slice(plan.length - 3, plan.length - 2);
                // console.log(peakWeeks)
                const week = plan[i];
                const maxLongRun = getLongRunCapping(maxRunPerWeek, raceType, isKm);
                // console.log(maxLongRun)
                if (week.Taper) {
                    if (peakWeeks.find((week: any) => week.Week === i + 1)) {
                        longRun = Math.floor(week.Weekly_mileage * peakPercentage)
                        week.Long_run = Math.min(longRun, maxLongRun);
                    }
                    else {
                        let taperRun = Math.floor(week.Weekly_mileage * percentage)
                        taperRun = taperRun < minRun ? minRun : taperRun;
                        week.Long_run = Math.min(taperRun, maxLongRun);
                    }
                } else if (week.Recovery) {
                    if (peakWeeks.find((week: any) => week.Week === i + 1)) {
                        longRun = Math.floor(week.Weekly_mileage * peakPercentage)
                        week.Long_run = Math.min(longRun, maxLongRun);
                    }
                    else {
                        let recoveryRun = Math.floor(week.Weekly_mileage * percentage)
                        recoveryRun = recoveryRun < minRun ? minRun : recoveryRun;
                        week.Long_run = Math.min(recoveryRun, maxLongRun);
                    }
                } else {
                    if (week.Week == 1) {
                        // const longRunVal = Math.floor(week.Weekly_mileage * percentage)
                        const firstWeekRun = getInitialLongRun(recentLongRun < minRun ? minRun : recentLongRun, raceType, isKm);
                        week.Long_run = firstWeekRun;
                        longRun = Math.min(firstWeekRun, maxLongRun);
                    }
                    else {
                        let normalRun = Math.floor(week.Weekly_mileage * percentage) + 1
                        if ((normalRun - longRun) > 2)
                            normalRun = longRun + 2
                        normalRun = normalRun < minRun ? minRun : normalRun;
                        longRun = Math.min(normalRun, maxLongRun);

                        if (raceType.toLowerCase() === 'half') {
                            longRun = peakWeeks.find((week: any) => week.Week === i + 1) ? Math.floor(week.Weekly_mileage * peakPercentage) : longRun;
                            week.Long_run = Math.min(longRun, maxLongRun);
                        } else
                            week.Long_run = longRun;
                    }
                }
            }
        }
        else if (rules && raceType.toLowerCase() === "full") {
            const peakWeeks = plan.slice(plan.length - 3, plan.length - 2);
            const maxLongRun = getLongRunCapping(maxRunPerWeek, raceType, isKm);
            const mileagePercent = rules.longRun.weekly.maxPercentage;
            const minRun = rules.longRun.weekly.minRun;
            for (let i = 0; i < plan?.length; i++) {
                const week = plan[i];
                if (week.Week == 1) {
                    const longRunVal = getInitialLongRun(recentLongRun, raceType, isKm);
                    week.Long_run = longRunVal > minRun ? longRunVal : minRun;
                }
                else if (week.Recovery || week.Taper) {
                    if (week.Recovery && plan[i + 1].Taper) {
                        const longRunVal = Math.min(week.Long_run, maxLongRun);
                        week.Long_run = longRunVal > minRun ? longRunVal : minRun;
                    }
                    else {
                        const longRunVal = peakWeeks.find((week: any) => week.Week === i + 1) ? Math.min(Math.round(week.Weekly_mileage * 0.5), 16) : Math.min(Math.round(week.Weekly_mileage * mileagePercent), maxLongRun);
                        week.Long_run = longRunVal > minRun ? longRunVal : minRun;
                    }
                }
                else {
                    const longRunVal = Math.min(week.Long_run, maxLongRun);
                    week.Long_run = longRunVal > minRun ? longRunVal : minRun;
                }
            }

            peakWeeks
        }

        return plan;
    }

    const assignRunType = (tableData: any, longRunDay: any) => {

        tableData.forEach((week: any, wI: number) => {

            (function assignRunAsBase() {
                week?.days?.forEach((day: any) => {
                    day.run_type = 'Base'
                })
            })();


            (function assignRunAsLong() {
                week?.days?.forEach((day: any) => {
                    if (day.name.toLowerCase().includes(longRunDay.toLowerCase().slice(0, 3)))
                        day.run_type = 'Long Run'
                })
            })();


            (function assignRunAsEasy() {

                // Easy Run rules:
                // Placed after hard workouts or long runs, limited to one per week

                if (wI > 0 && tableData[wI - 1]?.days[6].run_type === 'Long') {
                    week.days[0].run_type = 'Easy'
                } else {
                    week?.days?.forEach((day: any, dI: number) => {
                        if (dI > 0 && week?.days[dI - 1].run_type === 'Long')
                            day.run_type = 'Easy'
                    })
                }
            })();


            (function assignRunAsTempo() {

                // Tempo Run rules:
                // Start 8 weeks before race day, spaced at least two days from long runs, and between 4 and 8 miles.

                const weekStart = 8
                if (wI < (tableData.length - weekStart)) return
                let skipWeek = false
                week?.days?.forEach((day: any, dI: number) => {
                    if (!skipWeek && day.long_run >= 4 && day.long_run <= 8) {
                        if (dI > 1 && week?.days[dI - 2].run_type === 'Long') {
                            skipWeek = true
                            day.run_type = 'Tempo'
                        } else if (dI < week?.days.length - 3 && week?.days[dI + 2].run_type === 'Long') {
                            skipWeek = true
                            day.run_type = 'Tempo'
                        } else if (wI > 0 && dI < 2 && tableData[wI - 1]?.days[dI + 5].run_type === 'Long') {
                            skipWeek = true
                            day.run_type = 'Tempo'
                        }
                    }
                })
            })();


            (function assignRunAsInterval() {

                // Interval Run rules:
                // Start 6 weeks before, limited to 6 miles or less
                // ?? no min/max per week => assumed 1 per week

                const weekStart = 6
                if (wI < (tableData.length - weekStart)) return
                let skipWeek = false
                week?.days?.forEach((day: any, dI: number) => {
                    if (!skipWeek && day.long_run <= 6 && day.run_type == 'Base') {
                        day.run_type = 'Interval'
                        skipWeek = true
                        return
                    }

                })
            })();

            (function assignAsMixed() {
                let skipWeek = false
                week?.days?.forEach((day: any, dI: number) => {
                    if (!skipWeek && day.run_type == 'Base') {
                        day.run_type = 'Mixed'
                        skipWeek = true
                        return
                    }
                })
            })();

        })

        return tableData || []
    }


    const getDailyRuns = (plan: any, runnerPreferences: any) => {
        const dayOrder = runnerPreferences.long_run_day === 'Sunday' ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK

        const newPlan = plan.map((week: any) => {
            return {
                week: week.Week,
                total_mileage: week.Weekly_mileage,
                long_run: week.Long_run,
                ...dayOrder.reduce((acc: any, day: string) => {
                    // console.log(day)
                    acc[day] = day === runnerPreferences?.long_run_day ? week.Long_run : 0;
                    return acc;
                }, {} as any),
            }
        })

        return newPlan
    }

    const assignLongRunDay = (plan: any, longRunDay: string) => {
        const newPlan: any = []
        plan.forEach((week: any, index: number) => {
            const planRow =
            {
                week: week.week,
                weekly_mileage: week.total_mileage,
                long_run: week.long_run,
                days: [
                    {
                        name: "Mon",
                        long_run: week["Monday"] || 0,
                        run_type: ''
                    },
                    {
                        name: "Tues",
                        long_run: week["Tuesday"] || 0,
                        run_type: ''
                    },
                    {
                        name: "Wed",
                        long_run: week["Wednesday"] || 0,
                        run_type: ''
                    },
                    {
                        name: "Thurs",
                        long_run: week["Thursday"] || 0,
                        run_type: ''
                    },
                    {
                        name: "Fri",
                        long_run: week["Friday"] || 0,
                        run_type: ''
                    },
                    {
                        name: "Sat",
                        long_run: week["Saturday"] || 0,
                        run_type: ''
                    },
                    {
                        name: "Sun",
                        long_run: week["Sunday"] || 0,
                        run_type: ''
                    }

                ]
            }
            newPlan.push(planRow)
        })

        return newPlan;
    }

    const adjustLowMileageDays = (plan: any) => {
        for (let i = 0; i < plan.length - 1; i++) {
            const week = plan[i];
            for (const day in week) {
                if (day !== 'week' && day !== 'weekly_mileage') {
                    if (week[day] === 1) {
                        week[day] = 0;
                    } else if (week[day] === 2) {
                        week[day] = 3;
                    }
                }
            }
        }
        return plan;
    }

    const reArrangeMileage = (plan: any, raceType?: string) => {
        if (raceType && (raceType.toLowerCase() === '10k' || raceType.toLowerCase() === "5k" || raceType.toLowerCase() === "8k" || raceType.toLowerCase() === "maintenance")) {
            return plan
        }

        const data = plan[plan.length - 1];

        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const nonZeroDays = days.filter(day => data[day] !== 0);

        const sortedValues = nonZeroDays.map(day => data[day]).sort((a, b) => b - a);

        let currentValue = sortedValues[0];
        const descendingValues: any = [];
        for (let i = 0; i < sortedValues.length; i++) {
            descendingValues.push(currentValue);
            currentValue = Math.max(currentValue - 1, 1);
        }

        nonZeroDays.forEach((day, index) => {
            data[day] = descendingValues[index];
        });
        return plan;
    }


    const reArrangeMileageKRuns = (plan: any, preRaceWeek?: boolean, raceDay?: string) => {
        const data = plan[plan.length - (preRaceWeek ? 2 : 1)]
        const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const nonZeroDays = days.filter(day => data[day] !== 0);

        const sortedValues = nonZeroDays.map(day => data[day]).sort((a, b) => b - a);

        let currentValue = sortedValues[0];
        const descendingValues: any = [];
        for (let i = 0; i < sortedValues.length; i++) {
            descendingValues.push(currentValue);
            currentValue = Math.max(currentValue - 1, 1);
        }
        nonZeroDays.forEach((day, index) => {
            if (raceDay === "sunday" && day === "saturday" || raceDay === "saturday" && day === "friday" || raceDay === "friday" && day === "thursday" || raceDay === "thursday" && day === "wednesday" || raceDay === "wednesday" && day === "tuesday" || raceDay === "tuesday" && day === "monday") {
                data[day] = Math.min(descendingValues[index] - 1, 2);
            }
            else
                data[day] = descendingValues[index];
        });

        return plan;
    }


    const hasOneOrTwoValueRuns = (data: any) => {
        for (let i = 0; i < data.length - 1; i++) {
            const weekData = data[i];
            for (const day in weekData) {
                if (day !== "week" && day !== "weekly_mileage") {
                    if (weekData[day] === 1 || weekData[day] === 2) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const syncLongRunDay = (plan: any, longRunDay: string, initLongRuns: any[], recoveryTaper: any[], suggestedStartingLongRun: number) => {
        plan.forEach((x: any) => {
            const recoveryTaperWeek = recoveryTaper.find((rt: any) => rt.Week === x.week)
            const isFirstWeek = x.week === plan[0].week
            const isLastWeek = x.week === plan[plan.length - 1].week
            if (!recoveryTaperWeek && !isLastWeek) {
                const initLongRun = initLongRuns?.find((longRun: any) => longRun.w === x.week)
                x[longRunDay.toLowerCase()] = initLongRun?.lr != x[longRunDay.toLowerCase()] ? initLongRun?.lr : x[longRunDay.toLowerCase()]
                if (isFirstWeek && suggestedStartingLongRun != 0) {
                    x[longRunDay.toLowerCase()] = suggestedStartingLongRun
                    initLongRuns[0].lr = suggestedStartingLongRun
                }
            }
        })

        return plan
    }


    const patchWarmupDay = (plan: any, raceDayName: any) => {
        const preRaceWeek = raceDayName.toLowerCase() === 'monday'
        plan = reArrangeMileageKRuns(plan, preRaceWeek, raceDayName.toLowerCase())

        plan.forEach((x: any) => {
            const isLastWeek = x.week === plan[plan.length - 1].week
            if (isLastWeek && preRaceWeek && x['sunday'] > 0)
                x['sunday'] = 1
        })

        raceDayName.toLowerCase() !== 'sunday' ? cleanupRaceWeek(plan, raceDayName) : undefined

        return plan
    }

    const cleanupRaceWeek = (plan: any, raceDay: any) => {
        plan.forEach((x: any) => {
            const isLastWeek = x.week === plan[plan.length - 1].week
            if (isLastWeek) {
                const days = Object.keys(x);
                const startIndex = days.indexOf(raceDay.toLowerCase())
                for (let i = startIndex; i < days.length; i++) {
                    x[days[i]] = 0
                }
            }
        })
        return plan
    }

    const setLastWeekToRacePace = (plan: any) => {
        const lastWeekData = plan[plan.length - 1];
        for (const key in lastWeekData) {
            if (lastWeekData[key] !== 0) {
                lastWeekData[key] = 6;
            }
        }
        return plan;
    }

    // run distance based on how far/near it is towards race day
    const patchPreRaceDays = (plan: any, raceDayName: any) => {

        const daysOfWeekFixed = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
        const preRaceWeek = raceDayName.toLowerCase() === 'sunday'
        const indexRaceDay = preRaceWeek ? 7 : daysOfWeekFixed.indexOf(raceDayName.toLowerCase())

        plan.forEach((x: any, i: any) => {
            const isLastWeek = x.week === plan[ plan.length - 1 ].week
            if (isLastWeek) {
                for(const day of daysOfWeekFixed) {

                    const newDistance = Math.max(indexRaceDay - daysOfWeekFixed.indexOf(day), 0)
                    x[day] = x[day] != 0 ? newDistance : 0

                }
            }
        })

        return plan
    }


    return {
        getLongRuns, applyRecoveryWeeks, getWithHighestWeeklyMileage, applyTaperWeeks, assignLongRunDay, assignRunType, getDailyRuns, reArrangeMileage, syncLongRunDay, hasOneOrTwoValueRuns, getHighestLongRun,
        convertWeeklyMileageData, convertDailyMileageData, convertRunTypesData, convertCrossTrainingData, morphAddRunTypes, appendCrossTrainingData, adjustLowMileageDays, patchWarmupDay, cleanupRaceWeek, setLastWeekToRacePace,
        patchPreRaceDays
    }
}

export default useManualCalculations