/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import useSWR from 'swr'

import Grid from '@mui/material/Grid'

import Personal from './Personal'
import YourTrainingJourney from '../training-plan/YourTrainingJourney'
import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import {H1} from '../../components/text/RSTypography'
import { AuthPageProps, RSTabsDataProps } from '../../modules/types'
import useRSTabs from '../../hooks/useRSTabs'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

const Profile: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {
	const isMobile = useIsMobileScreen()
	const [mainLoading, setMainLoading] = useState(false)
	const { data: accountProfile, isLoading, mutate } = useSWR(hasLoaded && userInfo?.account_id ? `/account-profile?account_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })
	const allLoader = !hasLoaded || isLoading || mainLoading
	const tabList: RSTabsDataProps = [{
		title: 'PERSONAL',
		body: <Personal
			userInfo={userInfo}
			hasLoaded={hasLoaded}
			accountProfile={accountProfile}
			isLoading={allLoader} mutate={mutate}
			isMobile={isMobile}
			setMainLoading={setMainLoading}
		/>
	}, {
		title: 'TRAINING',
		body: <YourTrainingJourney
			userInfo={userInfo}
			mainLoading={mainLoading}
			setMainLoading={setMainLoading}
		/>
	}]

	const { tabsUI } = useRSTabs({
		data: tabList,
		btnContSX: { width: '100%', justifyContent: 'space-evenly' },
	})



	return <RSNavCont loaderProps={{ active: allLoader, isBehindHeader: false }} hideHeader={isMobile}>
		{isMobile && <RSHeaderCont hasLogo title="Profile"/>}
		<Grid xs={12} sx={{ px: 3, pt: 2 }}>
			{tabsUI}
		</Grid>
	</RSNavCont >
}
export default WithAuth(Profile);
