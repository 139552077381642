/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import useRNBridge from '../../hooks/useRNBridge'


export type SharePlanProps = {
	activityName?: string
	distance?: string
	unit?: string
	duration?: string
	calories?: number
	heartRate?: number
	date?: string
	pace?: string,
	test?: boolean
}

const SharePlan: FC<SharePlanProps> = ({
	activityName,
	distance,
	unit,
	duration,
	calories,
	heartRate,
	date,
	pace,
	test
}) => {

	const { sendDataToReactNative } = useRNBridge()

	const generatePayload = () => [
		{ text: date, textSize: 'size2', space: true },
		{ text: "Distance", textSize: 'size2' },
		{ text: `${distance} ${unit}`, textSize: 'size1' },
		{ text: "Total Time", textSize: 'size2' },
		{ text: duration, textSize: 'size1' },
		{ text: "Avg Heart Rate", textSize: 'size2' },
		{ text: `${heartRate} bpm`, textSize: 'size1' },
		{ text: "Avg Pace", textSize: 'size2' },
		{ text: pace, textSize: 'size1' },
		{ text: "Total Calories", textSize: 'size2' },
		{ text: String(calories), textSize: 'size1' }
	]

	const testPayload = () => [
		{
			"text": "Monday, Jan 20, 2025",
			"textSize": 'size2',
			"space": true
		},
		{
			"text": "Distance",
			"textSize": 'size2',
			"isLabel": true
		},
		{
			"text": "3.0",
			"textSize": 'size1'
		},
		{
			"text": "Total Time",
			"textSize": 'size2',
			"isLabel": true
		},
		{
			"text": "29:41",
			"textSize": 'size1'
		},
		{
			"text": "Avg Heart Rate",
			"textSize": 'size2',
			"isLabel": true
		},
		{
			"text": "150 bpm",
			"textSize": 'size1'
		},
		{
			"text": "Avg Pace",
			"textSize": 'size2',
			"isLabel": true
		},
		{
			"text": "9:50/mi",
			"textSize": 'size1'
		},
		{
			"text": "Total Calories",
			"textSize": 'size2',
			"isLabel": true
		},
		{
			"text": "277",
			"textSize": 'size1'
		}
	]

	const handleClickShare = () => {
		const dataToSend = {
			requestType: 'image-picker',
			payload: test ? testPayload() : generatePayload()
		}

		sendDataToReactNative(dataToSend)
	}

	return <Button onClick={handleClickShare} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 0, minWidth: 0 }}>
		<Box sx={{ display: 'flex', position: 'relative', color: "#333" }}>
			<i className="fa-regular fa-share" style={{ fontSize: '24px' }}></i>
		</Box>
	</Button>
}

export default SharePlan
