import { FC } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { H1, SH2, SH3, P2 } from '../../../../components/text/RSTypography'
import RSCardCont from '../../../../components/cards/RSCardCont'

export type WelcomeProps = {
	bodyData?: any,
}

const Welcome: FC<WelcomeProps> = ({bodyData}) => {
	const {firstName, lastName} = bodyData || {}
	const list = [{
		title: 'Designed by Physical Therapists',
		subtitle: 'Training plans and workouts crafted by running Physical Therapists, powered by the latest tech.',
		icon: 'fa-light fa-microchip-ai'
	}, {
		title: 'Plans that Work for You',
		subtitle: 'Fully Personalized plans tailored to your goals, schedule, and fitness level.',
		icon: 'fa-light fa-calendar-clock'
	}, {
		title: 'A Coach in Your Pocket',
		subtitle: 'Cutting-edge tech keeps you on track and feeling your best from start to finish.',
		icon: 'fa-light fa-road-circle-exclamation'
	}, {
		title: 'Runner-Specific Exercises',
		subtitle: 'Guided cross-training to complement your running and prevent injuries.',
		icon: 'fa-light fa-dumbbell'
	}, {
		title: 'Help for Every Area',
		subtitle: 'Self-paced programming to improve every aspect of your running: run form, injury prevention, nutrition, and more!',
		icon: 'fa-light fa-handshake-angle'
	}]
	return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
		<Grid container sx={{ pb: 1 }} justifyContent='flex-start' alignItems='center'>
			<H1 card size={5} sx={{width: '100%'}}>
				Welcome to
			</H1>
			<H1 card size={5}>
				RunSmart, {firstName?.trim()?.split(' ')?.[0] || lastName}!
			</H1>
		</Grid>
		<SH2 card size={1} sx={{ pb: 1, width: '100%' }}>
			Our mission is to build lifelong runners through expert-led programming and cutting-edge tech.
		</SH2>
		{list.map((l: any, lIndex: number) => {
			return <RSCardCont key={lIndex+'ytu-li'} sx={{ p: '13px 8px', mt: 2 }}>
				<Grid container xs={12}>
					<Box sx={{ width: '28px', mx: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<i className={l.icon} style={{ fontSize: '25px' }} />
					</Box>
					<Box sx={{ width: 'calc(100% - 48px)', px: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
						<SH3 title>{l.title}</SH3>
						<P2 subtitle>{l.subtitle}</P2>
					</Box>
				</Grid>
			</RSCardCont>
		})}
	</Box>
}

export default Welcome
