import { FC } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { H1, SH2, P1 } from '../../../../components/text/RSTypography'
import RSIcon from '../../../../components/icons/RSIcon'

export type YourTrainingUnlockedProps = {
}

const YourTrainingUnlocked: FC<YourTrainingUnlockedProps> = () => {

	const list = [
		'Personalized plans tailored to your goals.',
		'Advanced tools to manage your training easily.',
		'Pacing insights to train smarter.',
		'Strength and yoga workouts for injury-free running.',
		'Expert tools to improve your run form and prevent injuries.'
	]
	return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
		<Grid container xs={12} sx={{mb: 1}}>
			<Grid container className='pink-bg' sx={{height: '40px', width: '40px', borderRadius: '50%', color: 'white', fontSize: '20px' }} justifyContent='center' alignItems='center'>
				<i className="fa-light fa-medal" />
			</Grid>
		</Grid>
		<Grid container sx={{ pb: 1 }} justifyContent='flex-start' alignItems='center'>
			<H1 card size={5} sx={{width: '100%'}}>
				Your Training,
			</H1>
			<H1 card size={5}>
				Unlocked
			</H1>
		</Grid>
		<SH2 card size={1} sx={{ pb: 2, width: '100%' }}>
			Explore everything RunSmart has to offer with 8 days of free Pro access. Keep training for free, or go Pro to unlock your full potential.
		</SH2>
		{list.map((l: any, lIndex: number) => {
			return <Grid key={lIndex+'ytu-li'} container xs={12} sx={{ pl: 2 }} justifyContent='flex-start' alignItems='flex-start'>
				<RSIcon type='circle' height={5} width={5} sx={{marginTop: '11px'}}/>
				<P1 size={1} desc sx={{ pl: 1, width: 'calc(100% - 10px)' }}>
					{l}
				</P1>
			</Grid>
		})}
	</Box>
}

export default YourTrainingUnlocked
