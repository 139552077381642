import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
//import Typography from '@mui/material/Typography';
import Favorite from '@mui/icons-material/Favorite'

import ExercisePage from '../exercises/ExercisePage';
import useFavorites from '../favorites/useFavorites'
import { UserInfoProps } from '../../modules/types'
import RSFlexCard from '../../components/cards/RSFlexCard'
import RSCardCont from '../../components/cards/RSCardCont'
//import ScrollRow from '../../components/layout/ScrollRow'
import RSDrawer from '../../components/layout/RSDrawer';
import { P1 } from '../../components/text/RSTypography'
import WorkoutVideoContainer from '../../pages/training-plan/components/WorkoutVideoContainer';
import { Program, ProgramVideo } from '../../api/v2/programs/programs.types'
import { Video } from '../../modules/videos/allVideos';

export type FavoritesHomeProps = UserInfoProps & {
	hasLoaded: boolean | undefined,
	mainLoading?: boolean,
	setMainLoading?: any,
}

const FavoritesHome: FC<FavoritesHomeProps> = ({ userInfo, hasLoaded, setMainLoading, mainLoading }) => {

	//const [isScrolling, setIsScrolling] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [favoriteList, setFavoriteList] = useState<Array<Video> | undefined>()
	const navigate = useNavigate()
	const { favorites, isLoading: isFavoritesLoading } = useFavorites(userInfo?.account_id as number, undefined, 'all', true)

	const { data: programVideos, isLoading: programVidLoading } = useSWR(hasLoaded ? `/program-videos` : undefined)
	const { data: programs, isLoading: programLoading } = useSWR(hasLoaded ? `/programs` : undefined)
	const [showExerciseVideo, setShowExerciseVideo] = useState(false)
	const [exerciseVideoId, setExerciseVideoId] = useState<number>()

	const getVideoDurations = async (vids: Array<Video>, programVideos?: ProgramVideo[], programs?: Program[]) => {
		setIsLoading(true)
		const finalData: Array<Video> = []
		for (const v of vids) {
			if (v.id && programVideos?.length) {
				let duration = '';
				let durationN = 0
				let durationNumber = 0
				let program_has_intro = false;
				let program_id = undefined
				if (v.duration) {
					durationN = v?.duration ? Math.round(v?.duration / 60) : 0
					durationNumber = v?.duration || 0
				} else {
					const video = programVideos?.find((x) => x.video_id == Number(v.id))
					const program = programs?.find((x) => x.program_id == video?.program_id)
					program_id = program?.program_id;
					program_has_intro = program?.program_tabs?.toLowerCase().includes('intro') ? true : false
					durationN = video?.video_length ? Math.round(video?.video_length / 60) : 0
					durationNumber = video?.video_length || 0
				}
				duration = durationN > 0 ? `${durationN} Minute${durationN > 1 ? 's' : ''}` : ''
				finalData.push({ ...v, duration, durationNumber, program_has_intro, program_id })
			} else
				finalData.push(v)
		}
		setIsLoading(false)
		setFavoriteList(finalData)
	}


	const handleNavigateToVideo = (url: string, video_id?: number, program_id?: number, intro?: number, program_has_intro?: boolean) => {
		if (!program_id && url && url.includes('exercise')) {
			setShowExerciseVideo(true)
			setExerciseVideoId(video_id)
		}
		else if (!program_id && url)
			navigate(url.replace('/#', ''), { replace: true })
		else if (program_id && video_id) {
			navigate(`/explore?videoId=${video_id}&p_id=${program_id}${program_has_intro ? (intro ? '&tab=1' : '&tab=2') : '&tab=1'}`, { replace: true })
		}

	}

	const parseProgramTitle = (title: string) => {
		if (title === "base3")
			return "Base 3"
		else if (title === "basesix")
			return "Base 6"
		else if (title === "yoga")
			return "RunSmart Yoga"
		else
			return title
	}

	const renderExercisePlayer = () => {
		return (
			<RSDrawer
				bottom
				noClose
				open={showExerciseVideo}
				onClose={() => setShowExerciseVideo(false)}
			>
				<Box>
					<ExercisePage closePlayer={() => {
						setShowExerciseVideo(false)
					}} isFromTP={true} userInfo={userInfo} videoIdProps={String(exerciseVideoId)} />
				</Box>
			</RSDrawer>
		)
	}

	const renderFavorites = () => {
		const favList: Video[] = favoriteList?.length ? favoriteList : []
		return favList?.length ? <Grid container xs={12}>
			<RSFlexCard sx={{ pt: 0 }}
				videoList={favList?.map((plan, planIndex: number) => <WorkoutVideoContainer
					key={`video-favorites-${planIndex}`}
					isDurationLoading={hasLoaded && !isLoading ? false : true}
					index={planIndex}
					headingTitle={parseProgramTitle(plan.category)}
					subTitle={plan.headingTitle}
					duration={plan.durationNumber || 0}
					thumbnail={plan.image}
					onClick={() => handleNavigateToVideo(plan?.url, Number(plan?.id), plan?.program_id, plan?.intro_status, plan?.program_has_intro)}
				/>) || []}
			/>
		</Grid> : <RSCardCont sx={{ width: '100%', p: 2, mt: 2, alignItems: 'center'}} >
			<P1 size={1}>
				No faves yet! Tap the
				<Favorite htmlColor='#fff' sx={{ fontSize: '28px', ml: '5px', mr: '4px', stroke: 'gray', cursor: 'pointer' }} />
				icon on any video to add them.
			</P1>
		</RSCardCont>
	}

	useEffect(() => {
		setMainLoading?.(isFavoritesLoading || isLoading || programVidLoading || programLoading)
	}, [isFavoritesLoading, isLoading, programVidLoading, programLoading])

	useEffect(() => {
		const progVids = programVideos?.data?.result as ProgramVideo[]
		const progs = programs?.data?.result as Program[]
		progVids?.length && getVideoDurations(favorites, progVids, progs)
	}, [favorites, programVideos, programs])

	return <Box id='favorites-home' sx={{px: 3}}>
		{!mainLoading && <Grid container xs={12}>
			{!hasLoaded || isLoading || programVidLoading || programLoading ? <Box className='loading-days toRight borderless default-bs' sx={{ maxWidth: '450px', width: '100%', mt: 2, minHeight: '80px'}}/>
				: renderFavorites()
			}
			{renderExercisePlayer()}
		</Grid>}
	</Box>
}

export default FavoritesHome;
