/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { getReq, navReq, postReq } from '../modules/apiConsume'
import { getAllAccountInfo, signOut, GetOnBoarding, autoLogin } from '../modules/accountActions'
import { AuthUserInfo } from '../api/types'
import { CSS_DEV_MODE, IS_PROD, HAS_SALE, RECURLY_PUBLIC_KEY } from '../modules/cliEnvValues'
import { getCurrentAmount, getCurrentPlanCode } from '../modules/subscriptionUtils'
import { urlWithOffer } from '../pages/account/SignIn'
import defaultUserInfo from '../modules/defaultUserInfo.json'
import useAppStorage from '../hooks/useAppStorage'

import {
	FC,
	ComponentClass,
	FunctionComponent,
	useEffect,
	useState,
	useContext,
} from 'react'
import useStore from '../store/useStore'
import { ICurrentUser } from '../store/createAccountStore'
import { useLocation } from 'react-router-dom'
import { IComponentStore } from '../store/createComponentStore'
// import useIsMobileScreen from '../hooks/useIsMobileScreen'
// import { Helmet } from 'react-helmet'
import useScript from '../hooks/useScript'
import sha1 from 'locutus/php/strings/sha1'
import useQueryParams from '../hooks/useQueryParams'
// import useWebSocket from 'react-use-websocket'
import useStoreWSMessages from '../hooks/useStoreWSMessages'
// import { WebSocketContext } from '../WebsocketProvider'
import { planCodes } from '../api/values'
import Billing from './page-specific/account/Billing'
import useAlert from '../hooks/useAlert'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import GenericAlert from './misc/GenericAlert'
import { Helmet } from 'react-helmet'
import { isIOS, isSafari } from 'react-device-detect'

// import useDeviceDetect from '../hooks/useDeviceDetect'
// import useVideoProgress from '../hooks/useVideoProgress'
// import { Video } from '../modules/videos/allVideos'
// import sha1 from 'locutus/php/strings/sha1'

/**
 * With Auth Component
 */

const WithAuth = <Props extends object>(
	C: ComponentClass<Props> | FunctionComponent<Props>,
	skipRedirects = false
) => {
	const AuthComponent: FC<Props> = (props) => {
		// State
		const url = useLocation();
		const isShow = url.pathname === '/renew' || url.pathname === '/suggested-programs' || url.pathname === '/signup' || url.pathname === '/login' ? false : true
		// const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);

		const { requestKey, newVersion, plan_code: planCode, p_id, garmin_connect, user_id } = useQueryParams()
		const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();
		const { storeWebSocketMessages, webSocketMessages, removeMessageByType } = useStoreWSMessages()

		// useScript('impact.js', url)
		useScript('identify.js', url)
		useScript('conversion.js', url)

		const [userInfo, setUserInfo] = useState<Partial<AuthUserInfo>>(
			CSS_DEV_MODE && !IS_PROD ? (defaultUserInfo.data as any) : {}
		)

		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		const garmin_oauth_token = localStorage.getItem('garmin_oauth_token')
		const garmin_oauth_verifier = localStorage.getItem('garmin_oauth_verifier')
		// const appVersion = localStorage.getItem('app-version')

		// const { sendJsonMessage, lastJsonMessage } = useWebSocket(WS_URL,
		// 	{
		// 		shouldReconnect: () => {
		// 			return true;
		// 		},
		// 		reconnectAttempts: 3,
		// 		reconnectInterval: 30000,
		// 	}, requestKey || localStorageRequestKey ? true : false);

		//impact click id
		const CLICK_ID = localStorage.getItem("irclickid") || undefined

		// const QUERY_STRING = localStorage.getItem("queryString") || undefined

		const [hasLoaded, setHasLoaded] = useState(false)
		const { setCurrentUser, hasOffer, setHasOffer, setSubscription, subscription, billingInfo, setBillingInfo, balanceInfo, setBalanceInfo, account, setAccount } = useStore((state: ICurrentUser) => state)
		const { setIsMobile, setShowAnnouncementPopup } = useStore((state: IComponentStore) => state)
		// const isMobile = useIsMobileScreen()
		const isMobile = localStorageRequestKey ? true : false

		const [showPastDue, setShowPastDue] = useState(false)


		// window.test("test")
		// Hooks
		useAppStorage()


		// const videos: Video[] = [];

		// useVideoProgress({ videos, userInfo })

		// Check Auth
		const checkAuth = async () => {
			if (!CSS_DEV_MODE || (CSS_DEV_MODE && !IS_PROD)) {
				let accountInfo: Partial<AuthUserInfo> = {}
				let loginResponse: any = undefined
				let subscriptionResponse: any = undefined
				let billingResponse: any = undefined
				let balanceResponse: any = undefined
				let subscriptionIsActive = false
				let billingIsValid = false
				let hasPastSubscription = false
				let hasActiveSubscription = false
				let hasRemainingSubscription = false
				let hasPastDuePayment = false
				let isAccountDeleted = false;
				let externalSubscription = false;

				try {
					// Get Account, Subscription, and Billing Info
					const responses = await getAllAccountInfo({ account: account ? false : true, subscription: subscription?.active ? false : true, billing: billingInfo ? false : true, balance: balanceInfo ? false : true })
					// console.log('subscription: ', responses.subscription)
					loginResponse = responses.account
					subscriptionResponse = responses.subscription
					billingResponse = responses.billing
					balanceResponse = responses.balance
					const accountId = loginResponse?.data?.account_id
					const abortControllerC = new AbortController();
					let onBoardingResponse: any = ''
					if (accountId)
						onBoardingResponse = await GetOnBoarding(accountId, abortControllerC.signal)
					const obDataString = onBoardingResponse?.data?.result?.[0]?.user_progress
					const onBoardingData = obDataString ? JSON.parse(obDataString) : {}
					// Get Most Recent Active and Expired Subscription Info
					const activeSubscription = subscriptionResponse?.data?.active || subscription?.active || undefined
					const expiredSubscription = subscriptionResponse?.data?.expired || subscription?.expired || undefined
					setSubscription({ active: activeSubscription, expired: expiredSubscription })
					setBillingInfo(billingResponse.data ? billingResponse.data : billingInfo ? billingInfo : undefined || undefined)
					setBalanceInfo(balanceResponse ? balanceResponse : balanceInfo ? balanceInfo : undefined || undefined)
					setAccount(loginResponse?.data ? loginResponse?.data : account ? account : undefined || undefined)

					if (!billingResponse?.data && billingInfo)
						billingResponse['data'] = billingInfo

					if (balanceInfo)
						balanceResponse = balanceInfo

					if (!subscriptionResponse?.data && subscription)
						subscriptionResponse['data'] = subscription

					if (!loginResponse?.data && account)
						loginResponse['data'] = account


					//check if there is an active external subscription
					externalSubscription = activeSubscription?.subscription_type === "apple" || activeSubscription?.subscription_type === "google" ? true : false

					isAccountDeleted = subscriptionResponse?.data?.active?.status === "deleted" || subscriptionResponse?.data?.expired?.status === "deleted"

					if (isAccountDeleted)
						signOut()

					// Check if has Active or Expired Subscriptions
					hasActiveSubscription = activeSubscription?.id ? true : false
					hasPastSubscription = expiredSubscription?.id ? true : false

					// Check if Expired Subscription has remaining days
					if (!hasActiveSubscription && hasPastSubscription) {
						const endDate = expiredSubscription?.end_date
						const curDate = new Date().toISOString()
						hasRemainingSubscription = curDate < endDate
					}

					// Check if User has pending payment
					hasPastDuePayment = balanceResponse?.data?.pastDue

					// Get Active and Expired Subscription Amounts
					const activeAmount = getCurrentAmount(activeSubscription)
					const expiredAmount = expiredSubscription?.amount || 0

					// Check if Subscription is Free
					const subscriptionIsFree =
						(hasActiveSubscription && activeAmount == 0) ||
						(activeAmount || expiredAmount) == 0

					// Get Current or Most Recent Subscripition Amount
					const subscriptionAmount = activeAmount || expiredAmount

					// Check if user has an Active Subscription
					subscriptionIsActive =
						hasActiveSubscription || hasRemainingSubscription

					// Check for valid Billing Info
					billingIsValid = billingResponse?.data?.paymentType === "coupon" || !billingResponse?.data?.isValid ? false : (requestKey || localStorageRequestKey) ? true : billingResponse?.data?.isValid || subscriptionIsFree

					// Set Account Info to return
					accountInfo = loginResponse?.data
						? {
							...loginResponse?.data,
							is_valid: billingIsValid,
							is_expiring: hasRemainingSubscription,
							is_inactive: !subscriptionIsActive,
							amount: subscriptionAmount,
							paymentType: billingResponse?.data?.paymentType,
							onBoardingData,
							plan_code: activeSubscription?.plan_code || expiredSubscription?.plan_code || planCode
						} : {}
					setCurrentUser(loginResponse?.data)
					setUserInfo(accountInfo)
				} catch (err) {
					console.log('checkAuth err: ', err)
					// Do Nothing
				}
				// Token is Invalid
				const TOKEN_IS_INVALID = accountInfo.account_id == null

				// Subscription Is Inactive
				const SUBSCRIPTION_IS_INACTIVE = !subscriptionIsActive

				// Subscription is Expired
				const SUBSCRIPTION_IS_EXPIRED =
					hasPastSubscription && SUBSCRIPTION_IS_INACTIVE

				// User Info is Invalid
				// const USER_INFO_IS_INVALID =
				// 	!accountInfo.first_name || !accountInfo.last_name

				if (!skipRedirects) {
					// Login Page
					if (TOKEN_IS_INVALID) {
						console.log("TOKEN IS INVALID")
						//check if user wants to connect garmin
						if (garmin_connect && user_id) {
							const response = await autoLogin({ user_id: Number(user_id) })
							if (response?.data?.account_id != null) {
								navReq('/#/profile?garmin_connect=true')
							}
							else
								navReq("/#/signup")
						}
						else
							navReq("/#/signup")
					}

					else if (!TOKEN_IS_INVALID && garmin_connect && user_id) {
						navReq('/#/profile?garmin_connect=true')
					}

					// Renew Page
					else if (SUBSCRIPTION_IS_EXPIRED) {
						if (hasOffer.hasOffer)
							navReq(urlWithOffer('/#/renew', hasOffer.offerParam === "?offer=2" ? 2 : hasOffer.offerParam === "?offer=1" ? 1 : undefined))
						else navReq('/#/renew' + url.search)
					}

					// Choose Plan Page
					else if (SUBSCRIPTION_IS_INACTIVE && !hasOffer.hasOffer) {
						console.log("SUBSCRIPTION INACTIVE")

						if (!p_id || accountInfo?.plan_code) navReq(urlWithOffer('/#/signup' + url.search, HAS_SALE ? 2 : undefined))
					}

					else if (SUBSCRIPTION_IS_INACTIVE && hasOffer.hasOffer) {
						console.log("SUBSCRIPTION INACTIVE 2")
						if (!p_id || accountInfo?.plan_code) navReq(urlWithOffer('/#/signup', hasOffer.offerParam === "?offer=2" ? 2 : hasOffer.offerParam === "?offer=1" ? 1 : undefined))
					}

					// Update billing info
					// hasPastDuePayment && !externalSubscription
					else if (hasPastDuePayment && !externalSubscription) {
						const activeDetails = subscriptionResponse?.data?.active || subscriptionResponse?.data?.expired || {}
						let newPlanCode = getCurrentPlanCode(activeDetails) || planCode;
						if (newPlanCode?.toLowerCase()?.includes('annual'))
							newPlanCode = planCodes.annual

						else if (newPlanCode?.toLowerCase()?.includes('monthly'))
							newPlanCode = planCodes.monthly

						if (newPlanCode) {

							// code for page redirect insted of popup
							// const updateParams: any = { renew: true, valid: 1, plan_code: newPlanCode }
							// const paramStr = new URLSearchParams(updateParams).toString()
							// navReq(`/#/update-billing?${paramStr}`)

							setShowPastDue(true)

						} else {
							console.log("NO PLAN CODE")
							navReq('/#/renew' + url.search)
						}
					}

					// User Info Page
					// else if (USER_INFO_IS_INVALID) {
					// 	navReq('/#/user-info' + url.search)
					// }

					else {
						setShowAnnouncementPopup()
					}
				}
			}

			setHasLoaded(true)
		}

		const handleSaveExternalSubscription = async (data: any) => {
			const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
			removeMessageByType("google-expired-sub-mobileapp")
			if (messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) {
				await getReq('/v2/external-subscription?user_id=' + userInfo?.account_id).then(async (res) => {
					if (res?.data?.result?.length > 0) {
						await postReq('/v2/external-subscription', data).then(async () => navReq('/'))
					}
				})
			}
		}

		useEffect(() => {
			const expiredMessage = webSocketMessages.find(x => x?.requestKey === requestKey && x?.requestType === "google-expired-sub-mobileapp")
			if (userInfo?.account_id && expiredMessage) {
				const data = { user_id: userInfo?.account_id, type: 'google', transaction_receipt: "", is_expired: 1 }
				handleSaveExternalSubscription(data)
			}

		}, [webSocketMessages]);

		useEffect(() => {
			if (requestKey && newVersion) {
				setIsMobile(isMobile)
				localStorage.setItem('appVersion', 'updated')
				// const versionKey = webSocketMessages.find(x => x?.requestKey === requestKey && x?.requestType === "app-version-verify")
				// if (versionKey || newVersion) {
				// 	localStorage.setItem('appVersion', versionKey.payload)
				// 	removeMessageByType("app-version-verify")
				// }
				// localStorage.removeItem('appVersion')
			}
			else if (messageData?.requestKey === requestKey && messageData?.requestType === "app-version-verify") {
				localStorage.setItem('appVersion', 'updated')
			}
		}, [requestKey, newVersion, messageData]);


		// useEffect(() => {
		// 	if (!appVersion && requestKey) {
		// 		if (device.mobileType === "Ios") {
		// 			window.location.assign('https://apps.apple.com/app/1507476659')
		// 		}
		// 		else if (device.mobileType === "Android")
		// 			window.location.assign('https://play.google.com/store/apps/details?id=com.runmsartonline');
		// 		// alert('you have to update')
		// 	}
		// }, [appVersion, requestKey,])

		// useEffect(() => {
		// 	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		// 	if (url.pathname === '/' && hasLoaded && (requestKey || localStorageRequestKey)) {
		// 		sendJsonMessage &&
		// 			sendJsonMessage({ requestType: 'webapp-loaded-home', requestKey: requestKey || localStorageRequestKey, payload: undefined, timestamp: new Date().getTime() })
		// 		removeMessageByType("webapp-loaded-home")
		// 	}
		// }, [url, hasLoaded, requestKey])

		// Mount
		useEffect(() => {
			setIsMobile(isMobile)
			const abortController = new AbortController();
			checkAuth()
			return () => {
				abortController.abort();
			}
		}, [])

		useEffect(() => {
			if (url.search === "?offer=2" || url.search === "?offer=1") {
				setHasOffer({
					offerParam: url.search,
					hasOffer: true
				})
			}
		}, [])

		useEffect(() => {
			if (requestKey) {
				localStorage.setItem("deviceRequestKey", requestKey)
			}
		}, [requestKey])

		// useEffect(() => {
		// 	if (userInfo?.account_id && garmin_oauth_token && garmin_oauth_verifier) {
		// 		if (garmin_oauth_token && garmin_oauth_verifier) {
		// 			navReq(`/#/profile?oauth_token=${garmin_oauth_token}&oauth_verifier=${garmin_oauth_verifier}`)
		// 		}
		// 	}
		// }, [userInfo, garmin_oauth_verifier, garmin_oauth_token]);


		// useEffect(() => {
		// 	// console.log(lastJsonMessage)
		// 	if (userInfo?.account_id && lastJsonMessage && lastJsonMessage.requestKey === requestKey) {
		// 		setMessageData(lastJsonMessage)
		// 		storeWebSocketMessages(lastJsonMessage)
		// 	}
		// }, [lastJsonMessage, userInfo]);

		useEffect(() => {
			if (userInfo?.account_id && CLICK_ID) {
				// console.log("Trigger Identify")
				window?.callIdentify?.(String(userInfo?.account_id), sha1(userInfo?.email))
			}
		}, [userInfo, url])

		// Update Current User Store
		useEffect(() => {
			setCurrentUser(userInfo)
		}, [userInfo])

		const { currentUser } = useStore((state: ICurrentUser) => state)

		const {
			showAlert,
			onAlertOpen,
			onAlertClose,
			alertTitle,
			alertMessage,
			setAlertMessage,
			alertVariant,
			setAlertVariant,
		} = useAlert()

		// document.addEventListener("DOMContentLoaded", function () {
		// 	var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		// 	var appBannerHeight = 50; // Adjust based on actual banner height

		// 	function adjustContentPadding() {
		// 		if (isIOS && isSafari) {
		// 			// Increase the top padding of the body or main content container
		// 			document.body.style.paddingTop = appBannerHeight + 'px';
		// 		}
		// 	}

		// 	if (isIOS && isSafari) {
		// 		adjustContentPadding();

		// 		// Adjust padding on orientation change
		// 		window.addEventListener("orientationchange", function () {
		// 			adjustContentPadding();
		// 		});
		// 	}
		// });

		return (

			// <WebSocketProvider requestKey={localStorage.getItem('deviceRequestKey') as string}>

			<>
				{
					isShow ? (<></>
						// <Helmet>
						// <meta name="apple-itunes-app" content="app-id=1507476659"></meta>
						// </Helmet>
					) : undefined
				}
				<C {...props} userInfo={userInfo} hasLoaded={hasLoaded} />

				{/* Alert */}
				{/* <GenericAlert
					show={showAlert}
					title={alertTitle}
					message={alertMessage}
					variant={alertVariant}
					onClose={onAlertClose}
					mustClose={true}
				/> */}

				{showPastDue ? (
					<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
						<Elements>
							<Billing
								userInfo={currentUser}
								afterBillingProcess={undefined}
								isUpdate={false}
								isPastDue={true}
								onAlertOpen={onAlertOpen}
								setAlertMessage={setAlertMessage}
								setAlertVariant={setAlertVariant}
								isAccount={true}
							/>

						</Elements>
					</RecurlyProvider>
				) : undefined}

			</>

			// </WebSocketProvider>

		)
	}

	return AuthComponent
}

export default WithAuth
