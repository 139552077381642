import { postReq } from "../modules/apiConsume"
import useAIPlanPrompts from "../modules/useAIPlanPrompts"
import CryptoJS from 'crypto-js';

const useRSRunAIsuggestions = () => {

    const CRYPTO_KEY = "MWT3BlbkFJG2PIu";

    const { defaultSytemPrompt, buildPromptSuggestedWeeklyMileage, buildPromptSuggestedGoalTime, buildUserRecommendation,
        buildPromptSuggestedPlanStartDistance, buildPromptSuggestedDistanceWeekStart } = useAIPlanPrompts()

    const defaultPromptCall = async (prompt: any) => {

        const message = [{
            "role": "system",
            "content": defaultSytemPrompt
        }, {
            "role": "user",
            "content": prompt
        }]

        // console.log('getting suggestions...')
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();
        const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o-2024-08-06', gptTemp: '0' })
        if (response && response.status === "error") {
            alert(response.error.error.error.message)
        }

        return response?.data?.output || []
    }

    const getSuggestedWeeklyMileage = async (stageData: any) => {
        const isMaintenance = stageData["1-0"]?.raceType?.toLowerCase() === 'maintenance' ? true : false
        const maintenanceType = stageData["0"]?.level == 1 ? 'maintain' : 'build'
        const isMaintenancePrompt = isMaintenance && maintenanceType === 'build'

        let latestResults: any = []
        // const weeklyRunData = stageData["1-0"] || {}
        const mergedStageDetails = { ...stageData["0"], ...stageData["1-0"], ...stageData["1-1"], ...stageData["1-2"] }

        const prompt = buildPromptSuggestedWeeklyMileage(mergedStageDetails, isMaintenancePrompt)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)

        // console.log('!!!!! run days: ', mergedStageDetails?.runDaysPerWeek)
        const cappedSuggestedWeeklyMileage = capSuggestedWeeklyMileage(response, mergedStageDetails?.runDaysPerWeek, mergedStageDetails?.unit, mergedStageDetails?.raceType, isMaintenancePrompt)
        // console.log('>>> cappedSuggestedWeeklyMileage: ', cappedSuggestedWeeklyMileage)

        latestResults = { average: cappedSuggestedWeeklyMileage?.average || response?.average || '19', peak: cappedSuggestedWeeklyMileage?.peak || response?.peak || '25' }
        // console.log('>>> suggested weekly mileages: ', latestResults)

        return latestResults
    }

    const getSuggestedGoalTime = async (userRunData: any) => {

        let latestResults: any = []

        const prompt = buildPromptSuggestedGoalTime(userRunData)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.goalFinishTime || { seconds: '0', minutes: '0', hours: '0' }

        return latestResults
    }


    // rename to getSuggestedStartLongRunDistance
    const getSuggestedPlanStartDistance = async (stageData: any) => {

        const isMaintenance = stageData["1-0"]?.raceType?.toLowerCase() === 'maintenance' ? true : false
        const maintenanceType = stageData["0"]?.level == 1 ? 'maintain' : 'build'
        const isMaintenancePrompt = isMaintenance && maintenanceType === 'build'

        let latestResults: any = []
        const mergedStageDetails = { ...stageData["0"], ...stageData["1-0"], ...stageData["1-1"], ...stageData["1-2"] }

        // console.log('isMaintenancePrompt: ', isMaintenancePrompt)

        const prompt = buildPromptSuggestedPlanStartDistance(mergedStageDetails, isMaintenancePrompt)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.first_long_run

        // console.log('>>> Suggested plan start distance: ', latestResults)

        return latestResults
    }


    const getSuggestedPlanStartDistanceSANDBOX = async (userDetails: any) => {

        let latestResults: any = []
        const prompt = buildPromptSuggestedPlanStartDistance(userDetails, false)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.first_long_run

        // console.log('>>> Suggested plan start distance: ', latestResults)

        return latestResults
    }


    const getSuggestedDistanceWeekStartAIcall = async (stageData: any, peakWeeklyMileage: any) => {

        let latestResults: any = []
        const mergedStageDetails = { ...stageData["0"], ...stageData["1-0"], ...stageData["1-1"], ...stageData["1-2"] }

        // console.log('isMaintenancePrompt: ', isMaintenancePrompt)

        const prompt = buildPromptSuggestedDistanceWeekStart(mergedStageDetails, peakWeeklyMileage)
        // console.log('>>> prompt: ', prompt)

        const response = await defaultPromptCall(prompt)
        latestResults = response?.first_week_total_distance

        // console.log('>>> Suggested plan start distance: ', latestResults)

        return latestResults
    }


    const checkPlanEligibility = async (userDetails: any) => {
        const isMaintenance = userDetails["1-0"]?.raceType?.toLowerCase() === 'maintenance' ? true : false
        const maintenanceType = userDetails["0"]?.level == 1 ? 'maintain' : 'build'
        const isMaintenancePrompt = isMaintenance && maintenanceType === 'build'
        const mergedStageDetails = { ...userDetails["1-0"], ...userDetails["1-1"], ...userDetails["1-2"], ...userDetails["2"], ...userDetails["3"] }
        const prompt = buildUserRecommendation(mergedStageDetails, isMaintenancePrompt)
        // console.log('>>> prompt: ', prompt)
        const message = [{
            "role": "system",
            "content": defaultSytemPrompt
        }, {
            "role": "user",
            "content": prompt
        }]
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(message), String(CRYPTO_KEY)).toString();
        if (isMaintenance) {
            return { ready: 'yes', 'reason': 'You are already in maintenance mode' }
        }
        else {
            const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: 'gpt-4o-2024-08-06', gptTemp: '0' })
            if (response && response.status === "error") {
                alert(response.error.error.error.message)
            }
            const latestResults = response?.data?.output || []

            return latestResults
        }
    }


    const capSuggestedWeeklyMileage = (response: any, runDaysPerWeek: any, unit: any, raceType: any, isMaintenancePrompt: any) => {

        const isKM = (unit?.toLowerCase() === 'km' || unit?.toLowerCase()?.includes('meter')) ? true : false

        const isMarathon = raceType?.toLowerCase().includes('half') || raceType?.toLowerCase().includes('full') || raceType?.toLowerCase().includes('mile')
        const isMaintenance = isMaintenancePrompt && !isMarathon

        // console.log('cap isM:', isMarathon)
        // console.log('cap isKM:', isKM)

        const responseAverage = response?.average
        const [aveMin, aveMax] = responseAverage && ((responseAverage.toString().split('-').map(Number)) || responseAverage)
        const fixedAverage = (responseAverage && aveMin && aveMax && (aveMin + aveMax) / 2) || responseAverage
        // console.log('>>> fixedAverage: ', fixedAverage)

        const responsePeak = response?.peak
        const [peakMin, peakMax] = responsePeak && ((responsePeak.toString().split('-').map(Number)) || responsePeak)
        const fixedPeak = (responsePeak && peakMin && peakMax && (peakMin + peakMax) / 2) || responsePeak
        // console.log('>>> fixedPeak: ', fixedPeak)

        let finalAverage = null
        let finalPeak = null

        // isMarathon
        // For input of 3 running days: Do not exceed 25 mi (40km)
        // For input of 4 running days: Do not exceed 35 mi (56km)
        // For input of 5 running days: Do not exceed 45 mi (72km)
        // For input of 6 running days: Do not exceed 60 mi (97km)
        // For input of 7 running days: Do not exceed 75 mi (113km)

        // isMaintenance
        // For input of 3 running days: Do not exceed 18 mi (29km)
        // For input of 4 running days: Do not exceed 23 mi (37km)
        // For input of 5 running days: Do not exceed 30 mi (48km)
        // For input of 6 running days: Do not exceed 36 mi (58km)
        // For input of 7 running days: Do not exceed 60 mi (97km)

        // not isMarathon and not isMaintenance
        // For input of 3 running days: Do not exceed 26 mi (42km)
        // For input of 4 running days: Do not exceed 34 mi (54km)
        // For input of 5 running days: Do not exceed 42 mi (67km)
        // For input of 6 running days: Do not exceed 50 mi (80km)
        // For input of 7 running days: Do not exceed 58 mi (93km)


        if (isMarathon && !isKM) {

            if (runDaysPerWeek == 3) {
                if (fixedAverage > 25) {
                    finalAverage = 25
                }
                if (fixedPeak > 25) {
                    finalPeak = 25
                }
            }

            if (runDaysPerWeek == 4) {
                if (fixedAverage > 35) {
                    finalAverage = 35
                }
                if (fixedPeak > 35) {
                    finalPeak = 35
                }
            }

            if (runDaysPerWeek == 5) {
                if (fixedAverage > 45) {
                    finalAverage = 45
                }
                if (fixedPeak > 45) {
                    finalPeak = 45
                }
            }

            if (runDaysPerWeek == 6) {
                if (fixedAverage > 60) {
                    finalAverage = 60
                }
                if (fixedPeak > 60) {
                    finalPeak = 60
                }
            }

            if (runDaysPerWeek == 7) {
                if (fixedAverage > 75) {
                    finalAverage = 75
                }
                if (fixedPeak > 75) {
                    finalPeak = 75
                }
            }

        }


        if (isMarathon && isKM) {

            if (runDaysPerWeek == 3) {
                if (fixedAverage > 40) {
                    finalAverage = 40
                }
                if (fixedPeak > 40) {
                    finalPeak = 40
                }
            }

            if (runDaysPerWeek == 4) {
                if (fixedAverage > 56) {
                    finalAverage = 56
                }
                if (fixedPeak > 56) {
                    finalPeak = 56
                }
            }

            if (runDaysPerWeek == 5) {
                if (fixedAverage > 72) {
                    finalAverage = 72
                }
                if (fixedPeak > 72) {
                    finalPeak = 72
                }
            }

            if (runDaysPerWeek == 6) {
                if (fixedAverage > 97) {
                    finalAverage = 97
                }
                if (fixedPeak > 97) {
                    finalPeak = 97
                }
            }

            if (runDaysPerWeek == 7) {
                if (fixedAverage > 113) {
                    finalAverage = 113
                }
                if (fixedPeak > 113) {
                    finalPeak = 113
                }
            }
        }

        if (isMaintenance && !isKM) {

            if (runDaysPerWeek == 3) {
                if (fixedAverage > 18) {
                    finalAverage = 18
                }
                if (fixedPeak > 18) {
                    finalPeak = 18
                }
            }

            if (runDaysPerWeek == 4) {
                if (fixedAverage > 23) {
                    finalAverage = 23
                }
                if (fixedPeak > 23) {
                    finalPeak = 23
                }
            }

            if (runDaysPerWeek == 5) {
                if (fixedAverage > 30) {
                    finalAverage = 30
                }
                if (fixedPeak > 30) {
                    finalPeak = 30
                }
            }

            if (runDaysPerWeek == 6) {
                if (fixedAverage > 36) {
                    finalAverage = 36
                }
                if (fixedPeak > 36) {
                    finalPeak = 36
                }
            }

            if (runDaysPerWeek == 7) {
                if (fixedAverage > 60) {
                    finalAverage = 60
                }
                if (fixedPeak > 60) {
                    finalPeak = 60
                }
            }
        }

        if(isMaintenance && isKM) {

            if(runDaysPerWeek == 3) {
                if(fixedAverage > 29) {
                    finalAverage = 29
                }
                if(fixedPeak > 29) {
                    finalPeak = 29
                }
            }

            if(runDaysPerWeek == 4) {
                if(fixedAverage > 37) {
                    finalAverage = 37
                }
                if(fixedPeak > 37) {
                    finalPeak = 37
                }
            }

            if(runDaysPerWeek == 5) {
                if(fixedAverage > 48) {
                    finalAverage = 48
                }
                if(fixedPeak > 48) {
                    finalPeak = 48
                }
            }

            if(runDaysPerWeek == 6) {
                if(fixedAverage > 58) {
                    finalAverage = 58
                }
                if(fixedPeak > 58) {
                    finalPeak = 58
                }
            }

            if(runDaysPerWeek == 7) {
                if(fixedAverage > 97) {
                    finalAverage = 97
                }
                if(fixedPeak > 97) {
                    finalPeak = 97
                }
            }
        }

        if (!isMarathon && !isMaintenance && !isKM) {

            if (runDaysPerWeek == 3) {
                if (fixedAverage > 26) {
                    finalAverage = 26
                }
                if (fixedPeak > 26) {
                    finalPeak = 26
                }
            }

            if (runDaysPerWeek == 4) {
                if (fixedAverage > 34) {
                    finalAverage = 34
                }
                if (fixedPeak > 34) {
                    finalPeak = 34
                }
            }

            if (runDaysPerWeek == 5) {
                if (fixedAverage > 42) {
                    finalAverage = 42
                }
                if (fixedPeak > 42) {
                    finalPeak = 42
                }
            }

            if (runDaysPerWeek == 6) {
                if (fixedAverage > 50) {
                    finalAverage = 50
                }
                if (fixedPeak > 50) {
                    finalPeak = 50
                }
            }

            if (runDaysPerWeek == 7) {
                if (fixedAverage > 58) {
                    finalAverage = 58
                }
                if (fixedPeak > 58) {
                    finalPeak = 58
                }
            }
        }

        if (!isMarathon && !isMaintenance && isKM) {

            if (runDaysPerWeek == 3) {
                if (fixedAverage > 42) {
                    finalAverage = 42
                }
                if (fixedPeak > 42) {
                    finalPeak = 42
                }
            }

            if (runDaysPerWeek == 4) {
                if (fixedAverage > 54) {
                    finalAverage = 54
                }
                if (fixedPeak > 54) {
                    finalPeak = 54
                }
            }

            if (runDaysPerWeek == 5) {
                if (fixedAverage > 67) {
                    finalAverage = 67
                }
                if (fixedPeak > 67) {
                    finalPeak = 67
                }
            }

            if (runDaysPerWeek == 6) {
                if (fixedAverage > 80) {
                    finalAverage = 80
                }
                if (fixedPeak > 80) {
                    finalPeak = 80
                }
            }

            if (runDaysPerWeek == 7) {
                if (fixedAverage > 93) {
                    finalAverage = 93
                }
                if (fixedPeak > 93) {
                    finalPeak = 93
                }
            }
        }


        return { average: finalAverage ? (finalAverage + '-' + finalAverage) : responseAverage, peak: finalPeak ? (finalPeak + '-' + finalPeak) : responsePeak }
    }

    return { getSuggestedWeeklyMileage, getSuggestedGoalTime, checkPlanEligibility, getSuggestedPlanStartDistance, getSuggestedPlanStartDistanceSANDBOX, getSuggestedDistanceWeekStartAIcall }
}

export default useRSRunAIsuggestions