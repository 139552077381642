import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {RSButton1, RSButton2} from '../../components/buttons'
import RSButton from '../../components/buttons/RSButton'
import RSJourneyCard from '../../components/cards/RSJourneyCard'
import {H1, SH1, SH2, P1, P2} from '../../components/text/RSTypography'
import { getColorCode } from '../../modules/miscUtils'

const AdminUI = () => {
	const renderTitle = (title: string) => <H1 size={4} sx={{textDecoration: 'underline'}}>{title}</H1>
	const renderTypography = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				{renderTitle('Typography')}
			</Grid>

			<Grid container xs={12} justifyContent='space-evenly' alignItems='center'>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Thin !important'}}>
					Poppins Thin
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-ExtraLight !important'}}>
					Poppins Extra Light
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Light !important'}}>
					Poppins Light
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins !important'}}>
					Poppins Normal
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Medium !important'}}>
					Poppins Medium
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-SemiBold !important'}}>
					Poppins Semi Bold
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Bold !important'}}>
					Poppins Bold
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-ExtraBold !important'}}>
					Poppins Extra Bold
				</Grid>
			</Grid>


			<Grid container xs={12} sx={{pt: 2}} justifyContent='space-evenly' alignItems='center'>
				<Grid container xs={12}>
					<H1 sx={{mr: 2}}>Heading</H1>
					<SH1>Sub Head</SH1>
				</Grid>
				<Grid container xs={12}>
					<P1 sx={{mr: 2}}>Paragraph 1</P1>
					<P2>Paragraph 2</P2>
				</Grid>
			</Grid>
		</Box>
	}

	const renderCard = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				{renderTitle('Cards')}
			</Grid>
			<Grid container xs={12} justifyContent='space-evenly' alignItems='center'>
				<Grid container xs={3.5}>
					<RSButton card sx={{height: '60px'}}>On Boarding Selected Card</RSButton>
				</Grid>
				<Grid container xs={3.5}>
					<RSButton secondary card sx={{height: '60px'}}>On Boarding UnSelected Card</RSButton>
				</Grid>
			</Grid>
			<Box sx={{width: '100%', height: '1px', backgroundColor: '#00000038', mt: 3}}/>
			<Grid container xs={12} sx={{pt: 3}} justifyContent='space-evenly' alignItems='center'>
				<Grid container xs={3.5}>
					<RSJourneyCard>Training Future</RSJourneyCard>
				</Grid>
				<Grid container xs={3.5}>
					<RSJourneyCard isCurrentActive>Training Present</RSJourneyCard>
				</Grid>
				<Grid container xs={3.5}>
					<RSJourneyCard isPast>Training Past</RSJourneyCard>
				</Grid>
			</Grid>
		</Box>
	}

	const renderNewButton = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				{renderTitle('New Buttons')}
			</Grid>
			<Grid container xs={12} sx={{py: '10px'}} justifyContent='center' alignItems='center'>
				<SH2 size={2} sx={{width: '100px'}}>Button 1</SH2>
				<Box sx={{width: 'calc(100% - 100px)', height: '1px', backgroundColor: '#00000038'}}/>
			</Grid>
			<Grid container xs={12} sx={{py: 1}} justifyContent='flex-start' alignItems='center'>
				<Grid container xs={2.4} sx={{pr:2}}>
					<RSButton1 sx={{width: '100%'}}>Primary</RSButton1>
				</Grid>
				<Grid container xs={2.4} sx={{pr:2}}>
					<RSButton1 secondary sx={{width: '100%'}} >Secondary</RSButton1>
				</Grid>
				<Grid container xs={2.4} sx={{pr:2}}>
					<RSButton1 tertiary sx={{width: '100%'}}>Tertiary</RSButton1>
				</Grid>
				<Grid container xs={2.4} sx={{pr:2}}>
					<RSButton1 input sx={{width: '100%'}}>Input</RSButton1>
				</Grid>
				<Grid container xs={2.4} sx={{pr:2}}>
					<RSButton1 disabled sx={{width: '100%'}}>Disabled</RSButton1>
				</Grid>
			</Grid>

			<Grid container xs={12} sx={{py: '10px'}} justifyContent='center' alignItems='center'>
				<SH2 size={2} sx={{width: '100px'}}>Button 2</SH2>
				<Box sx={{width: 'calc(100% - 100px)', height: '1px', backgroundColor: '#00000038'}}/>
			</Grid>
			<Grid container xs={12} sx={{py: 1}}  justifyContent='flex-start' alignItems='center'>
				<Grid container xs={2} sx={{pr:2}}>
					<RSButton2 sx={{width: '100%'}}>Primary</RSButton2>
				</Grid>
				<Grid container xs={2} sx={{pr:2}}>
					<RSButton2 secondary sx={{width: '100%'}} >Secondary</RSButton2>
				</Grid>
				<Grid container xs={2} sx={{pr:2}}>
					<RSButton2 tertiary sx={{width: '100%'}}>Tertiary</RSButton2>
				</Grid>
				<Grid container xs={2} sx={{pr:2}}>
					<RSButton2 ghost sx={{width: '100%'}}>Ghost</RSButton2>
				</Grid>
				<Grid container xs={2} sx={{pr:2}}>
					<RSButton2 disabled selected sx={{width: '100%'}}>Selected Disabled</RSButton2>
				</Grid>
				<Grid container xs={2} sx={{pr:2}}>
					<RSButton2 disabled sx={{width: '100%'}}>Disabled</RSButton2>
				</Grid>
			</Grid>

		</Box>
	}

	const renderButton = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				{renderTitle('Old Buttons')}
			</Grid>
			<Grid container xs={12} justifyContent='space-between' alignItems='center'>
				<Grid container xs={3.5}>
					<RSButton>Primary</RSButton>
				</Grid>
				<Grid container xs={3.5}>
					<RSButton secondary>Secondary</RSButton>
				</Grid>
				<Grid container xs={3.5}>
					<RSButton disabled>Disabled</RSButton>
				</Grid>
			</Grid>
		</Box>
	}

	const renderColors = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				{renderTitle('Colors')}
			</Grid>
			<Grid container xs={12} justifyContent='space-between' alignItems='center'>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='primary-bg admin-ui-colors' />
					{`#9fcd2b className='primary-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='primary3-bg admin-ui-colors' />
					{`#bfd38c className='primary3-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='analogous-bg admin-ui-colors' />
					{`#6bcd2b className='analogous-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='orange-bg admin-ui-colors' />
					{`#ff9a00 className='orange-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='gray-disabled-bg admin-ui-colors' />
					{`#cbcbcb className='gray-disabled-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='gray-mid-bg admin-ui-colors' />
					{`#e7e7e7 className='gray-mid-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='gray-dark-bg admin-ui-colors' />
					{`#4a4a4a className='gray-dark-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='accent-bg admin-ui-colors' />
					{`#6b2bcd className='accent-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='blue-bg admin-ui-colors' />
					{`#2b9fcd className='blue-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='pink-bg admin-ui-colors'/>
					{`#cd2b6e className='pink-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='danger-bg admin-ui-colors' />
					{`#dc3545 className='danger-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('XT')}} />
					{getColorCode('XT')} {`getColorCode('XT')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('EASY')}} />
					{getColorCode('EASY')} {`getColorCode('EASY')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('BASE')}} />
					{getColorCode('BASE')} {`getColorCode('BASE')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors'  sx={{backgroundColor: getColorCode('MIXED')}} />
					{getColorCode('MIXED')} {`getColorCode('MIXED')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('TEMPO')}} />
					{getColorCode('TEMPO')} {`getColorCode('TEMPO')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('INTERVAL')}} />
					{getColorCode('INTERVAL')} {`getColorCode('INTERVAL')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('TRACK')}} />
					{getColorCode('TRACK')} {`getColorCode('TRACK')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('PACE')}} />
					{getColorCode('PACE')} {`getColorCode('PACE')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('RACE')}} />
					{getColorCode('RACE')} {`getColorCode('RACE')`}
				</Grid>
			</Grid>
		</Box>
	}

	return <>
		{renderButton()}
		{renderNewButton()}
		{renderCard()}
		{renderTypography()}
		{renderColors()}
	</>

}

export default AdminUI;
