import BillingForm from '../../forms/BillingForm'
import AccountSubmitButtons from '../../buttons/AuthSubmitButtons'
import useQueryParams from '../../../hooks/useQueryParams'
import {
	getCurrentPlanCode,
	getPlanArgs,
} from '../../../modules/subscriptionUtils'
import { AuthPageProps } from '../../../modules/types'
import { DEFAULT_COSTS } from '../../../modules/values'
import { titleCase } from '../../../modules/miscUtils'
import { navReq } from '../../../modules/apiConsume'
import { useRecurly } from '@recurly/react-recurly'
import {
	getAllAccountInfo,
	createSubscription,
	getSubscriptionInfo,
	getPlanCosts,
	updateBillingInfo,
} from '../../../modules/accountActions'
import { FC, useState, useEffect } from 'react'
// import ApplePayButton from '../../buttons/ApplePayButton'
import { IComponentStore } from '../../../store/createComponentStore'
import useStore from '../../../store/useStore'
// import GooglePayButton from '../../buttons/GooglePayButton'
import PaymentModal from '../../misc/PaymentModal'
import MobileModal from '../../misc/MobileModal'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import useRNBridge from '../../../hooks/useRNBridge'
import useDeviceDetect from '../../../hooks/useDeviceDetect'


// Billing Props
export type BillingProps = {
	userInfo: AuthPageProps['userInfo']
	hideDetails?: boolean
	hideDue?: boolean
	isUpdate?: boolean
	isPastDue?: boolean
	afterBillingChange?: () => any
	afterBillingProcess?: () => any
	onAlertOpen: () => any
	setAlertMessage: (x: string) => any
	setAlertVariant: (x: string) => any,
	isAccount?: boolean,
	side?: boolean
}

// Default Billing Info
const DEFAULT_BILLING_INFO = {
	lastFour: '',
	billingType: '',
	billingAmount: '',
	planType: '',
	nextBillDate: '',
}

/**
 * Billing Component
 */

const Billing: FC<BillingProps> = ({
	userInfo,
	isUpdate,
	afterBillingChange,
	afterBillingProcess,
	onAlertOpen,
	setAlertMessage,
	setAlertVariant,
	side,
	isPastDue,
	isAccount,
	hideDue
}) => {
	// Params
	const { plan_code: planCode, valid: planIsValid, renew: isRenew } = useQueryParams()

	const { isLoading, setIsLoading, setIsSuccess } = useStore((state: IComponentStore) => state)

	// State
	const [isValid, setIsValid] = useState(false)
	const [billingInfo, setBillingInfo] = useState({ ...DEFAULT_BILLING_INFO })
	const [planCosts, setPlanCosts] = useState({ ...DEFAULT_COSTS })

	const [showUpdateBillingRedirect, setShowUpdateBillingRedirect] = useState(false)
	const [showUpdateBillingModal, setShowUpdateBillingModal] = useState(false)
	const [invalidInfo, setInvalidInfo] = useState(false)

	const { sendDataToReactNative } = useRNBridge()
	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false
	const { mobileType } = useDeviceDetect()

	const isMobileScreen = useIsMobileScreen()
	// const [isSaving, setIsSaving] = useState(false)

	// const { development } = useQueryParams()

	// Hooks
	const recurly = useRecurly()

	const isFlashSale = planCode && planIsValid && planCode.includes('flash')
	// Get Billing Info
	const getBillingInfo = async () => {
		let newPlanCosts = planCosts
		const newBillingInfo = { ...DEFAULT_BILLING_INFO }
		const responses = await getAllAccountInfo({ account: false })
		const subscriptionResponse = responses.subscription
		const billingResponse = responses.billing
		const activeDetails = subscriptionResponse?.data?.active || subscriptionResponse?.data?.expired || {}
		const billingDetails = billingResponse?.data
		const newPlanCode = getCurrentPlanCode(activeDetails) || planCode || ''

		if (billingDetails) {
			newBillingInfo.lastFour = billingDetails.cardLastFour || ''
			newBillingInfo.billingType = billingDetails.paymentType
				? titleCase(billingDetails.paymentType)
				: ''
		}
		if (newPlanCode) {
			const { hasOffer, isRenew, hasSale } = getPlanArgs(newPlanCode)
			newPlanCosts = await getPlanCosts({
				hasOffer,
				isRenew,
				isYearlyRenewOnly: hasSale,
				hasSale,
			})
			newBillingInfo.nextBillDate = activeDetails.next_billing
				? new Date(activeDetails.next_billing).toLocaleDateString()
				: ''
			newBillingInfo.planType = newPlanCode.includes('month')
				? 'Monthly'
				: 'Yearly'
			newBillingInfo.billingAmount = activeDetails.amount
		}
		setPlanCosts(newPlanCosts)
		setBillingInfo(newBillingInfo)

	}

	// On Update Cancel
	const onUpdateCancel = () => navReq('/#')


	const handleOpenUrl = (url: string) => {
		if (isNativeApp && mobileType.toLowerCase() === "ios") {
			const dataToSend = {
				requestType: 'open-rs-shop',
				payload: { url: url },
				timestamp: new Date().getTime(),
			}
			sendDataToReactNative(dataToSend);
		} else
			window.open(url, '_blank')
	}

	// On Submit
	const onSubmit = () => {
		setIsLoading(true)
		const errorMsg = () => {
			setAlertVariant('danger')
			setAlertMessage(
				`Could not ${isUpdate ? 'update' : 'change'} subscription.`
			)
			onAlertOpen()
			return false
		}
		try {
			const form = document.querySelector('#billing-form') as HTMLFormElement
			recurly.token(form, async (err, token) => {
				if (err) {
					// setShowUpdateBillingModal(false)
					setInvalidInfo(true)
					setIsLoading(false)
					return errorMsg()
				} else {
					const billingResult = await updateBillingInfo(token.id)
					const subscriptionResult =
						planCode && planIsValid && planIsValid === "0" ? await createSubscription(planCode) : true
					if (billingResult?.data?.id && subscriptionResult) {
						// After Billing Update
						if (afterBillingChange) await afterBillingChange()
						const { isActive, subscription } = await getSubscriptionInfo()
						if (isActive && subscription?.id) {
							// const amount = subscription.amount || 0
							// const nextDate = subscription?.next_billing
							// 	? new Date(subscription.next_billing).toLocaleDateString()
							// 	: new Date().toLocaleDateString()
							// setAlertVariant('success')
							// setAlertMessage(
							// 	`Your subscription has been ${isUpdate || isAccount ? 'updated' : 'created'
							// 	}. You will be charged $${amount} on ${nextDate}.`
							// )

							// onAlertOpen()

							// After Billing Process

							setShowUpdateBillingModal(false)
							setIsLoading(false)
							setIsSuccess(true)

							setTimeout(() => {
								setIsSuccess(false)
								setTimeout(async () => {
									if (afterBillingProcess && !isRenew)
										await afterBillingProcess()
									if (isRenew) navReq('/#/')
								}, 500)
							}, 3000)


							return true
						}
					}
					setIsLoading(false)
					return errorMsg()
				}
			})
		} catch (err) {
			// setShowUpdateBillingModal(false)
			setInvalidInfo(true)
			setIsLoading(false)
			return errorMsg()
		}
	}


	// Mount Effect
	useEffect(() => {
		if (isPastDue) {
			const requestKey = localStorage.getItem('deviceRequestKey') || undefined
			requestKey ? setShowUpdateBillingRedirect(true) : setShowUpdateBillingModal(true)
		}

	}, [isPastDue])

	useEffect(() => {
		if (userInfo)
			getBillingInfo()
	}, [userInfo])

	const SubmitDetailsButton = (buttonText?: string) => {

		let primaryText = isAccount ? 'Save Billing Details' : 'Continue'
		if (buttonText) primaryText = buttonText

		return (
			<AccountSubmitButtons
				primaryText={primaryText}
				primaryAction={onSubmit}
				primaryDisabled={!isValid || isLoading}
				secondaryText={isUpdate && !planCode ? 'Do It Later' : undefined}
				secondaryAction={isUpdate && !planCode ? onUpdateCancel : undefined}
				isAccount={isAccount}
				addClassName={`billing-btn ${isUpdate && !planCode ? 'dil' : ''}`}
			/>
		)
	}

	const BillingInputForm = (hideDetails?: boolean) => {
		return (
			<BillingForm
				userInfo={userInfo}
				hideDetails={hideDetails}
				setIsValid={setIsValid}
				lastFour={billingInfo.lastFour}
				billingType={billingInfo.billingType}
				planType={billingInfo.planType}
				nextBillDate={billingInfo.nextBillDate}
				totalDue={isRenew && isFlashSale && planCode.includes('annual') ? String(planCosts.annualFlash || '') : isRenew && planCode.includes('monthly') ? String(planCosts.monthly || '') : isRenew && planCode.includes('annual') ? String(planCosts.annual || '') : billingInfo.billingAmount
				}
				planCode={planCode}
				isAccount={isAccount}
				hideDue={hideDue}
			/>
		)
	}

	// Render
	return (
		<>
			{!isPastDue ?
				(<>
					{!side && !isRenew ? <h3 className='ms-0 mt-3 mb-5' style={{ letterSpacing: 0 }}><b>Billing</b></h3> : <h3 className='ms-0 mt-3 mb-4' style={{ letterSpacing: 0, fontSize: '19px' }}><b>{isMobileScreen ? 'Update Billing Details' : ''}</b></h3>}
					{/* {isAccount && !isUpdate ? (
						<div className="mb-5 mt-2 other-btn">
							<ApplePayButton planCode={planCode} tranxType="update" />
							<GooglePayButton planCode={planCode} tranxType="update" />
						</div>
					) : undefined} */}

					{BillingInputForm(isRenew ? true : false)}

					<div className={isAccount ? 'account-billing-btn' : undefined}>
						{SubmitDetailsButton()}
					</div>
				</>) : (<>
					<PaymentModal
						type='update-app'
						show={showUpdateBillingModal}
						title="Your Account is Past Due"
						text={'Please take a minute to update your billing information.'}
						keyboard={false}
						backdrop="static"
						planCode={planCode}
						button1Text={'Credit Card'}
						planType={billingInfo.planType}
						totalDue={billingInfo.billingAmount}
						invalidInfo={invalidInfo}
						inputBilling={BillingInputForm}
						buttonSubmit={SubmitDetailsButton}
					/>

					<MobileModal
						type='update-app'
						show={showUpdateBillingRedirect}
						title="Your Account is Past Due"
						text={'Please take a minute to update your billing via the web to continue using RunSmart.'}
						setShow={setShowUpdateBillingRedirect}
						keyboard={false}
						backdrop="static"
						button1Text={'Update Billing'}
						button1Action={() => handleOpenUrl("https://app.runsmartonline.com/#/login")}
						button2Action={() => {
							navReq('/#/login')
						}}
						button2Text={'Exit'}
					/>
				</>)
			}



		</>
	)
}

export default Billing
