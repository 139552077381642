/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { OpenAIPrompt } from '../../api/v2/open-ai/OpenAIPrompt.types'
import { deleteReq, getReq, postReq, putReq } from '../../modules/apiConsume'
import CryptoJS from 'crypto-js';

const enabledAdditionalSteps = true
const enabledAlphaPrompts = false
const enabledAdditionalStepsForAll = true
const enabledAdditionalStepsForRaceTypes = ['HALF', 'FULL']

interface GPTPrompt {
    version?: number | 1
    race_type?: string | ''
    step1Params?: any
    step2Params?: any
}
const useGPTPrompts = ({ version, race_type, step1Params, step2Params }: GPTPrompt) => {

    const { data, isLoading, mutate: mutatePrompts } = useSWR(`/openai/get-prompts`)

    const [prompts, setPrompts] = useState<any>([])

    const [isSavingOrUpdating, setIsSavingOrUpdating] = useState(false)

    const generatePromptsFromText = (prompt: string, params: any) => {
        return prompt?.replace(/\${(.*?)}/g, (_, expression) => {
            try {
                return new Function('params', `with (params) { return ${expression} }`)(params);
            } catch (e) {
                // console.error(e);
                return ''
            }
        });
    };

    const getTotalSteps = (raceType: string, pr: any) => {
        try {
            const p = pr.data?.result?.filter((prompt: OpenAIPrompt) => prompt.race_type === raceType && prompt.status == 1)
            return p?.length
        } catch (e) {
            console.log(e)
            return 0
        }
    }

    const getTrainingPlanPrompts = (step: string, raceType: string, params: any) => {
        // console.log(params)

        try {
            const stepPrompts = prompts?.data?.result?.find((prompt: OpenAIPrompt) => prompt.step === step && prompt.race_type === raceType && prompt.status == 1)

            const existsAlternateSteps = prompts?.data?.result?.find((prompt: OpenAIPrompt) => Number(prompt.step) === 0.1 + Number(step) && prompt.race_type === raceType && prompt.status == 1) && true

            // step == '3' && console.log('alternate steps?', existsAlternateSteps)

            // jan 1, 2025. Step 3 is assign run types step
            if (step == '3' && enabledAdditionalSteps && enabledAlphaPrompts) {

                console.log('!!! initial prompts')

                const filledPrompts: string[] = []

                promptsAssignRunType.forEach((prompt) => {
                    filledPrompts.push(generatePromptsFromText(prompt, params))
                })

                return filledPrompts
            }
            else if (step == '3' && enabledAdditionalSteps && existsAlternateSteps && !enabledAlphaPrompts && (enabledAdditionalStepsForRaceTypes.includes(raceType?.toUpperCase()) || enabledAdditionalStepsForAll)) {

                console.log('/=/')

                const filledPrompts: string[] = []

                const stepIncrement = 0.1
                let additionalStepIndex = Number(step)

                let hasNextAlternateStep = true

                while (hasNextAlternateStep) {

                    additionalStepIndex = Number((additionalStepIndex + stepIncrement).toFixed(1))

                    const stepPromptAlternate = prompts?.data?.result?.find((prompt: OpenAIPrompt) => Number(prompt.step) === additionalStepIndex && prompt.race_type === raceType && prompt.status == 1)

                    if (!stepPromptAlternate) {
                        hasNextAlternateStep = false
                        break
                    }

                    filledPrompts.push(generatePromptsFromText(stepPromptAlternate?.plan_prompts, params))
                }

                return filledPrompts
            }

            if (stepPrompts)
                return generatePromptsFromText(stepPrompts?.plan_prompts, params)
            else return '';
        } catch (e) {
            // console.log(e)
            return ''
        }
    }

    const checkDupplicatePrompt = (prompts: OpenAIPrompt[], raceType: string, step: string, version: number) => {
        const dupp = prompts?.find((prompt: OpenAIPrompt) => prompt.race_type === raceType && prompt.step === step && prompt.version === version)
        if (dupp) return true
    }

    const updatePromptVersion = async (data: OpenAIPrompt) => {
        setIsSavingOrUpdating(true)
        await putReq(`/v2/openai/update-prompt`, data)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const createPromptVersion = async (data: OpenAIPrompt) => {
        setIsSavingOrUpdating(true)
        await postReq(`/v2/openai/create-prompt`, data)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const deletePromptVersion = async (id: number) => {
        setIsSavingOrUpdating(true)
        await deleteReq(`/v2/openai/delete-prompt?id=${id}`)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const restorePromptVersion = async (id: number) => {
        setIsSavingOrUpdating(true)
        await putReq(`/v2/openai/restore-prompt`, { id })
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    const activatePromptVersion = async (data: OpenAIPrompt) => {
        setIsSavingOrUpdating(true)
        await putReq(`/v2/openai/activate-prompt`, data)
        await mutatePrompts()
        setIsSavingOrUpdating(false)
    }

    useEffect(() => {
        const CRYPTO_KEY = "MWT3BlbkFJG2PIu";
        if (data?.data?.result && !isLoading) {
            if (typeof data.data.result === 'string') {
                const bytes = CryptoJS.AES.decrypt(data.data.result, String(CRYPTO_KEY));
                const decryptedData = JSON?.parse(bytes?.toString(CryptoJS.enc.Utf8));
                data.data.result = decryptedData
            }
            setPrompts(data)
        }
        else {
            getReq(`/v2/openai/get-prompts`).then((res) => {
                if (typeof res.data.result === 'string') {
                    const bytes = CryptoJS.AES.decrypt(res.data.result, String(CRYPTO_KEY));
                    const decryptedData = JSON?.parse(bytes?.toString(CryptoJS.enc.Utf8));
                    res.data.result = decryptedData
                }
                setPrompts(res)
            })
        }
    }, [data, isLoading])


    return { prompts, isLoading, getTrainingPlanPrompts, updatePromptVersion, createPromptVersion, isSavingOrUpdating, mutatePrompts, checkDupplicatePrompt, deletePromptVersion, activatePromptVersion, getTotalSteps, restorePromptVersion }
}

export default useGPTPrompts


////////////////////////////////////////////////////////////////////////////////////////

// alpha prompts
const promptsAssignRunType = [`You are a running coach building a half-marathon plan for a \${age}-year old \${gender}. You will receive a daily running plan and assign progression runs to the plan, optimizing for performance and injury prevention. 

Guidelines:
- Do not assign progression runs to \${preferredLongRun} 
- Add one progression run per week 
- Assign progression runs to the following days only: \${availableRunDays}
- Do not assign progression runs to training plan days with 0 distance

Output format: JSON. 
Output legend: [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 - Rest, 1 - Base Run, 2 - Progression Run] 

Provide the output in the following JSON format: 
{ 
"plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}]  
} 

Here is your training plan.  
\${plan}`
    ,
    `You are a running coach building a half-marathon plan for a \${age}-year old \${gender}. You will receive a daily running plan and assign tempo runs to the plan, optimizing for performance and injury prevention. 

Output format: JSON. 
Output legend: [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 = Rest, 1 = Base Run, 2 = Progression Run, 4 = Tempo] 
Provide the output in the following JSON format: 
{ 
"plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}]  
} 

Rule:
- Only assign tempo runs based on the guidelines listed 

Guidelines:
- Days with Progression run_type should still be a Progression Run (2 = Progression).
- Do not assign Tempo to \${preferredLongRun} 
- Do not add more than one tempo run per week 
- Tempo runs can begin on week \${Number(planWeeks && planWeeks > 8 ? planWeeks - 8 : 1)}.   
- DO NOT assign tempo runs longer than 10mi or 15km. 
- Ensure Tempo Runs are not scheduled for \${longRunDayPrev} or \${longRunDayNext}. Update if needed.
- DO NOT assign tempo to days with Progression Run.

Here is your training plan.  
\${plan}

Here is the current plan above using the run type legends:
\${planPreviousRawResponse}`
    ,
    `You are a running coach building a half-marathon plan for a \${age}-year old \${gender}. You will receive a daily running plan and assign interval runs to the plan, optimizing for performance and injury prevention. 

Output format: JSON. 
Output legend: [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 = Rest, 1 = Base Run, 2 = Progression Run, 4 = Tempo, 5 = Interval] 
Provide the output in the following JSON format: 
{ 
"plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}]  
} 

Rule: 
Only assign Interval runs based on the guidelines listed 

Guidelines:
- You can assign Interval to the following days only: \${availableRunDays}
- Days with Progression run_type should still be a Progression Run (2 = Progression).
- Do not assign Interval runs to \${preferredLongRun} 
- Do not add more than 1 interval run per week 
- Interval runs can begin on week \${Number(planWeeks && planWeeks > 5 ? planWeeks - 5 : 1)}
- DO NOT assign for runs longer than than 6mi or 10km 
- DO NOT assign to runs are not scheduled for \${longRunDayPrev} or \${longRunDayNext}. Update if needed.
- DO NOT assign Interval to days with Progression Run.
- Do NOT assign Interval to days with 0 distance or rest days.

Here is your training plan.  
\${plan}

Here is the current plan above using the run type legends:
\${planPreviousRawResponse}
`
    ,
    `You are a running coach building a half-marathon plan for a \${age}-year old \${gender}. You will receive a daily running plan and assign easy runs to the plan, optimizing for performance and injury prevention. 

Output format: JSON. 
Output legend: [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 = Rest, 1 = Base Run, 3 = Easy, 2 = Progression Run, 4 = Tempo, 5 = Interval] 
Provide the output in the following JSON format: 
{ 
"plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}]  
} 

Rule: 
- Only assign easy runs based on the guidelines listed 

Guidelines:
- You can only assign Easy runs to the following days only: \${availableRunDays}
- Do not update \${preferredLongRun} 
- Do not add more than 1 easy run per week 
- Prioritize easy runs after harder runs or workouts 
- Do NOT assign Easy to days with 0 distance or rest days.

Here is your training plan.  
\${plan}

Here is the current plan above using the run type legends:
\${planPreviousRawResponse}`
]