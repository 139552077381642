/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import is_numeric from 'locutus/php/var/is_numeric'
import React, { useEffect, useState } from 'react'
import useRSPlanWeekOutlookAI from '../../../hooks/useRSPlanWeekOutlookAI'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import { GetTrainingPlanInfo } from '../../../modules/aiTrainingPlanActions'
import { getReq } from '../../../modules/apiConsume'
import { GetAppleHealtActivity } from '../../../modules/appleHealthKitActions'
import { getCorosUserByUserId, getUserWorkoutsByRange } from '../../../modules/corosActions'
import { GetUserCustomPace, GetUserNotes } from '../../../modules/trainingPlanActions'
import useAIPlanOutlookPrompts from '../../../modules/useAIPlanOutlookPrompts'
import { CreateWeeklyOutlookDetails, GetPromptWeeklyOutlook, GetWeeklyOutlookDetails } from '../../../modules/weeklyOutlookActions'
import useTrainingTools from '../../../pages/admin/useTrainingTools'
import useGPTTrainingPlan from '../../../pages/race/useGPTTrainingPlan'
import { CorosWorkout } from '../coros/coros.types'
import { WeeklyOutlook } from './weekly-outlook.types'

const useWeeklyOutlook = (userInfo?: any) => {

    const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const { convertToLegacyTrainingInfo } = useGPTTrainingPlan()
    const { ConvertM2H2, getGarminPace, ConvertMetersToMiles } = useTrainingPlanUtils()
    const { buildPaceInitial, convertToMinPerMile, convertMinutesToMinutesAndSeconds } = useTrainingTools({ userInfo })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // const [weekDataSelected, setWeekDataSelected] = useState<any>({ current: {}, upcoming: {} })
    const [planCustomPaces, setPlanCustomPaces] = useState<any>([])
    const { buildPromptWeekOutlookCurrentSummary, buildPromptsAllWeeklyOutlook, getPromptWeeklyOutlookDefault } = useAIPlanOutlookPrompts()
    const { fetchResponseWeekOutlook } = useRSPlanWeekOutlookAI()

    const sanitizedText = (text: string) => {
        return text?.replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/\t/g, "\\t")
    }

    const getGarminUserDetails = async (userId: number) => {
        const response = await getReq(`/v2/garmin/user/id?userId=${userId}`)
        const data = response.data.result[0]
        return data
    }

    const getCorosUserDetails = async (userId: number) => {
        const response = await getCorosUserByUserId(userId)
        const data = response.data.result[0]
        return data
    }

    const getAppleHealthUserDetails = async (userId: number) => {
        const response = await GetAppleHealtActivity(userId, '')
        if (response?.data?.result?.length > 0)
            return true
    }

    const getPrompts = async () => {
        const current = await GetPromptWeeklyOutlook(1)
        const upcoming = await GetPromptWeeklyOutlook(2)

        return { current: current?.data?.result[0]?.prompt, upcoming: upcoming?.data?.result[0]?.prompt }
    }

    const getWeeklyOutputAIResponse = async (dataRace: any, promptDefault: string, weekDataSelected: any) => {
        const selectedGPTModel = "gpt-4o-2024-08-06"
        const result = await fetchResponseWeekOutlook(dataRace, weekDataSelected, promptDefault, '', selectedGPTModel)
        return result
    }

    const parseAIresponse = (response: any) => {
        const headline = response?.headline || ""
        const recap = response?.recap || ""

        if (headline && recap) {
            const parsedResponse: string = headline + '\n' + recap as string
            return parsedResponse
        }
        return ''
    }

    const getWeeklyOutlookDetails = async (allTrainingData: any, weekNumSelected: number) => {

        // setIsLoading(false)

        const planDedatails = {
            planType: allTrainingData?.race_type.toLowerCase() === 'half' ? 'Half Marathon' : allTrainingData?.race_type.toLowerCase() === "full" ? 'Full Marathon' : allTrainingData?.race_type.toLowerCase(),
            planName: allTrainingData?.event_name,
            currentWeek: weekNumSelected,
            totalWeeks: allTrainingData?.weeks,
            goal: ConvertM2H2(allTrainingData?.target_time),
            trainingPeak: allTrainingData?.max_distance,
            unit: allTrainingData?.is_km ? 'Kilometers' : 'Miles',
        }

        const mergedData = { ...allTrainingData, ...planDedatails }
        return mergedData
    }

    const createWeeklyOutlookDetails = async (userId: number, planId: number, weekNumSelected: number, weekDateStart: string, weekOutlook: string, upcomingOutlook: string, rawCurrentWeek: any, rawUpcomingWeek: any, device: string) => {
        // const weekDate = `"${moment(weekDateStart).format('YYYY-MM-DD')}-${moment(weekDateStart).add(1, 'week').format('YYYY-MM-DD')}"`
        const newData: WeeklyOutlook = {
            user_id: userId,
            plan_id: planId,
            week_number: weekNumSelected,
            week_date: weekDateStart,
            week_outlook: weekOutlook,
            upcoming_outlook: upcomingOutlook,
            feedback_message: "",
            feedback_rate: 0,
            raw_current_week: rawCurrentWeek?.daily_runs,
            raw_upcoming_week: rawUpcomingWeek?.daily_runs,
            device: device
        }

        const response = await CreateWeeklyOutlookDetails(newData)
        return response
    }

    const getCurrentWeeklyOutlook = async (userId: number, planId: number, weekNumSelected: number, weekDateStart: string) => {
        const currentWeeklyOutlook = await GetWeeklyOutlookDetails(userId, planId, weekNumSelected)
        const currentWeeklyOutlookData = currentWeeklyOutlook?.data?.result[0]
        // console.log('>>> currentWeeklyOutlookData: ', currentWeeklyOutlookData)

        const currentWeeklyOutlook1 = await GetWeeklyOutlookDetails(userId, planId, weekNumSelected - 1)
        const currentWeeklyOutlookData1 = currentWeeklyOutlook1?.data?.result[0]
        // console.log('>>> currentWeeklyOutlookData: ', currentWeeklyOutlookData1)

        const currentWeeklyOutlook2 = await GetWeeklyOutlookDetails(userId, planId, weekNumSelected - 2)
        const currentWeeklyOutlookData2 = currentWeeklyOutlook2?.data?.result[0]
        // console.log('>>> currentWeeklyOutlookData: ', currentWeeklyOutlookData2)


        return currentWeeklyOutlookData || currentWeeklyOutlookData1
    }

    // trainingPlanInfo is the equivalent of adminDash's dataRace
    const getWeeklyOutlook = async (userId: number, weekNumSelected: number, isKm: boolean, dataRace: any, trainingPlanInfo: any, regenerate?: boolean) => {

        regenerate && setIsLoading(true)

        // console.log('!!! weekNumSelected: ', weekNumSelected)
        let weeklyOutlook: any = {}

        const abortController = new AbortController()
        const customPaces = !regenerate ? null : await GetUserCustomPace(dataRace?.plan_id, abortController.signal).then((res: any) => {
            // console.log('!!! customPace: ', res?.data?.result)
            return res?.data?.result

        }).catch(err => {
            console.log('!!! customPace err: ', err)
        })

        // console.log('!!! trainingPlanInfo: ', trainingPlanInfo)
        const weekNotes = !regenerate ? null : await compileNotes(trainingPlanInfo, weekNumSelected, trainingPlanInfo?.uid).then(async (res: any) => {
            // console.log('>>> compileNotes: ', res)
            return res
        }).catch(err => {
            console.log('!!! compileNotes err: ', err)
        })


        const weekDataSelected = dataRace?.training?.filter((x: any) => x.week == weekNumSelected) && dataRace?.training?.filter((x: any) => x.week == weekNumSelected)[0]
        const weekDataUpcoming = dataRace?.training?.filter((x: any) => x.week == weekNumSelected + 1) && dataRace?.training?.filter((x: any) => x.week == weekNumSelected + 1)[0]
        const weekDateStart = trainingPlanInfo?.training_plan?.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        // disable this for testing purposes to generate new weekly outlook everytime in the homepage
        const currentWeeklyOutlook = regenerate ? null : await getCurrentWeeklyOutlook(userId, dataRace?.plan_id, weekNumSelected, weekDateStart)
        // let currentWeeklyOutlook: any = null

        const activeDevice = !regenerate ? 'SKIPPED_CHECK' : await checkActiveDevice(userId)
        if (activeDevice == "NULL") {
            // console.log('!!! no active device')
            return {
                current: 'not-connected',
                upcoming: 'not-connected',
            }
        } else {
            // console.log('!!! active device: ', activeDevice)
        }

        const corosData = !regenerate ? null : await getCorosData(userId, weekNumSelected, isKm, weekDataSelected, weekDataUpcoming, trainingPlanInfo, customPaces, weekNotes)
        const garminData = !regenerate ? null : await getGarminData(userId, weekNumSelected, isKm, weekDataSelected, weekDataUpcoming, trainingPlanInfo, dataRace, customPaces, weekNotes)
        const appleHealthData = !regenerate ? null : await getAppleHealthKitData(userId, weekNumSelected, isKm, weekDataSelected, weekDataUpcoming, trainingPlanInfo, customPaces, weekNotes)

        // if (!corosData || !garminData || !appleHealthData) {
        //     console.log('no synced data')
        //     return
        // }

        const allWatchData = await Promise.all([corosData, garminData, appleHealthData])

        // weeklyOutlook = { coros: corosData, garmin: garminData, appleHealth: appleHealthData }
        weeklyOutlook = { coros: allWatchData[0], garmin: allWatchData[1], appleHealth: allWatchData[2] }
        // console.log('>>>> weeklyOutlook: ', weeklyOutlook)

        const results = {
            current: activeDevice === "NULL" ? weeklyOutlook?.garmin?.current : activeDevice && weeklyOutlook?.[activeDevice]?.current,
            upcoming: activeDevice === "NULL" ? weeklyOutlook?.garmin?.upcoming : activeDevice && weeklyOutlook?.[activeDevice]?.upcoming
        }

        // console.log('>>>> results: ', results)

        let currentGraphData = []
        // const currentDailyRuns = results?.current?.daily_runs
        const currentDailyRuns = currentWeeklyOutlook?.raw_current_week || results?.current?.daily_runs
        if (currentDailyRuns) {
            const dailyRunsArr = JSON.parse(currentDailyRuns.replace(/[\r\n]+/g, ''))
            currentGraphData = dailyRunsArr.map((x: any, xi: number) => {

                const completedPaceArr = x?.completed_pace && x?.completed_pace !== "NA" ? x?.completed_pace?.replaceAll(' /mile', '').replaceAll(' /km', '')?.replaceAll(' hr', 'h')?.replaceAll(' min', 'm')?.replaceAll(' sec', 's')?.split(' ') : '0 h 0 m 0 s'.split(' ')
                let completedPace = 0
                for (const pc of completedPaceArr) {
                    if (pc.includes('h')) {
                        completedPace += (Number(pc.replaceAll('h', '')) || 0) * 60
                    }
                    if (pc.includes('m')) {
                        completedPace += (Number(pc.replaceAll('m', '')) || 0)
                    }
                    if (pc.includes('s')) {
                        completedPace += (Number(pc.replaceAll('s', '')) || 0) / 60
                    }
                }

                const planedPaceArr = x?.planned_pace?.replaceAll(' /mile', '').replaceAll(' /km', '')?.replaceAll(' hr', 'h')?.replaceAll(' min', 'm')?.replaceAll(' sec', 's')?.split(' ')
                let plannedPace = 0
                for (const pc of planedPaceArr) {
                    if (pc.includes('h')) {
                        plannedPace += (Number(pc.replaceAll('h', '')) || 0) * 60
                    }
                    if (pc.includes('m')) {
                        plannedPace += (Number(pc.replaceAll('m', '')) || 0)
                    }
                    if (pc.includes('s')) {
                        plannedPace += (Number(pc.replaceAll('s', '')) || 0) / 60
                    }
                }
                const plannedDistance = x.planned_distance && x.planned_distance !== 'NA' && is_numeric(x.planned_distance) ? Number(x.planned_distance) : 0
                const completedDistance = x.completed_distance && x.completed_distance !== 'NA' && is_numeric(x.completed_distance) ? Number(x.completed_distance) : 0
                return {
                    day: x.day_name,
                    completed_distance: completedDistance,
                    planned_distance: plannedDistance,
                    total_planned_time: plannedPace * plannedDistance,
                    total_completed_time: (completedPace > 0 ? completedPace : plannedPace) * completedDistance,
                }
            })
            // console.log('dailyRunsArr', dailyRunsArr)
        }

        //let upcomingGraphData = {}
        // const upcomingDailyRuns = results?.upcoming?.daily_runs
        const upcomingDailyRuns = currentWeeklyOutlook?.raw_upcoming_week || results?.upcoming?.daily_runs
        let upcomingDistance = 0
        if (upcomingDailyRuns) {
            const upcomingRunsArr = JSON.parse(upcomingDailyRuns)
            for (const u of upcomingRunsArr) {
                upcomingDistance += u.run_distance && u.run_distance !== 'NA' && u.run_distance != 0 ? u.run_distance : 0
            }
        }


        const graphData = { currentGraphData, upcomingDistance, isKm }

        if (!currentWeeklyOutlook) {
            setIsLoading(false)
            // console.log('!!!>>> no existing data')
            // disablling creation through homepage visit since we are now relying on the automated backend process
            // const prompts = await getPrompts()
            // const currentFilledPrompt = buildPromptsAllWeeklyOutlook(dataRace, results, prompts.current, weekNumSelected, '')
            // const currentResult = await getWeeklyOutputAIResponse(dataRace, currentFilledPrompt, weekDataSelected)
            // const currentResponseText = parseAIresponse(currentResult)

            // const upcomingFilledPrompt = buildPromptsAllWeeklyOutlook(dataRace, results, prompts.upcoming, weekNumSelected + 1, currentResponseText)
            // const upcomingResult = await getWeeklyOutputAIResponse(dataRace, upcomingFilledPrompt, weekDataSelected)

            // await createWeeklyOutlookDetails(userId, dataRace?.plan_id, weekNumSelected, weekDateStart, currentResult, upcomingResult, results.current, results.upcoming, activeDevice === "NULL" ? "-" : activeDevice)
            // currentWeeklyOutlook = await getCurrentWeeklyOutlook(userId, dataRace?.plan_id, weekNumSelected, weekDateStart)

            const sanitizedCurrent = sanitizedText(currentWeeklyOutlook?.week_outlook)
            const sanitizedUpcoming = sanitizedText(currentWeeklyOutlook?.upcoming_outlook)

            return {
                current: sanitizedCurrent && JSON.parse(sanitizedCurrent), upcoming: sanitizedUpcoming && JSON.parse(sanitizedUpcoming), details: currentWeeklyOutlook, ...graphData
            }
        }
        else {
            setIsLoading(false)
            const sanitizedCurrent = sanitizedText(currentWeeklyOutlook?.week_outlook)
            const sanitizedUpcoming = sanitizedText(currentWeeklyOutlook?.upcoming_outlook)
            return {
                current: sanitizedCurrent && JSON.parse(sanitizedCurrent), upcoming: sanitizedCurrent && JSON.parse(sanitizedUpcoming), details: currentWeeklyOutlook, ...graphData
            }
        }
    }

    const getCorosData = async (userId: number, weekNumSelected: number, isKm: boolean, weekDataSelected: any, weekDataUpcoming: any, trainingPlanInfo: any, customPaces: any, weekNotes: any) => {
        const weekDateStart = trainingPlanInfo?.training_plan?.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        const corosData = await getUserWorkoutsByRange(userId, weekDateStart, moment(weekDateStart).add(1, 'week').format('YYYY-MM-DD'))
        const dailyRunsNewCurrent: any[] = []

        const dailyRuns = JSON.parse(weekDataSelected?.daily_runs)
        dailyRuns.forEach((x: any, i: any) => {
            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
            const corosDataOnDate = corosData?.find((x: any) => x.activity_date == thisDate)
            const marathonPaces = trainingPlanInfo?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'

            let activities = corosDataOnDate?.activities && corosDataOnDate?.activities?.length ? JSON.parse(corosDataOnDate?.activities) as CorosWorkout[] : []
            //get only running activities
            activities = activities.filter(x => x.mode == 8)

            let totalDistance: any = 0
            let totalAvgSpeed = 0
            let totalAvgHeartRate = 0
            let totalDuration = 0

            //sum up all runnning activities
            activities?.forEach(activity => {
                totalDistance += activity.distance
                totalAvgSpeed += activity.avgSpeed
                totalAvgHeartRate += activity.avgHeartRate ? activity.avgHeartRate : 0
                totalDuration += activity.duration
            })

            totalAvgSpeed = totalAvgSpeed / activities?.length
            totalAvgHeartRate = Number((totalAvgHeartRate / activities?.length).toFixed(0))

            const completedPace = totalDistance / totalDuration
            const paceDuration = completedPace ? getGarminPace(isKm ? true : false, completedPace) : 'NA'
            const planned_pace = dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId, customPaces)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId, customPaces)?.paceSpeed)) + ' /mile' || 'NA'
            const convertedDistance = totalDistance ? (!isKm ? ConvertMetersToMiles(totalDistance as number)?.toFixed(1) : Number(totalDistance as number / 1000)?.toFixed(1)) : 0

            const completedDistanceUserMarkedComplete = weekNotes[i] && weekNotes[i]?.completed === 1 && plannedDistance
            // console.log('>>> completedDistanceUserMarkedComplete: ', completedDistanceUserMarkedComplete)


            if (dailyRuns[i]) {
                dailyRunsNewCurrent.push({
                    "day_name": dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "completed_distance": (completedDistanceUserMarkedComplete && !convertedDistance && completedDistanceUserMarkedComplete) || (convertedDistance ? convertedDistance : 0),
                    "run_type": dailyRuns[i]?.run_type,
                    // "cross_training": dailyRuns[i]?.cross_training,
                    "heartRate": totalAvgHeartRate || 'NA',
                    "planned_pace": planned_pace,
                    "completed_pace": paceDuration || 'NA',
                    "notes": weekNotes[i] ? weekNotes[i]?.notes : '',
                })

                // !configRunDataParams.xt && delete dailyRunsNewCurrent[i].cross_training
            }
        })


        weekDataSelected.daily_runs = JSON.stringify(dailyRunsNewCurrent)
        // corosData && corosData.length && setWeekDataSelected({ current: weekDataSelected, upcoming: weekDataUpcoming })
        return { current: weekDataSelected, upcoming: weekDataUpcoming }
    }

    const getGarminData = async (userId: number, weekNumSelected: number, isKm: boolean, weekDataSelected: any, weekDataUpcoming: any, trainingPlanInfo: any, dataRace: any, customPaces: any, weekNotes: any) => {

        // console.log('>>> getGarminData')
        // console.log('!!! weekNotes: ', weekNotes)
        let hasGarminData = false

        const weekDateStart = trainingPlanInfo?.training_plan.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal
        const userGarminDetails = await getGarminUserDetails(userId)

        const garminID = userGarminDetails?.garmin_id

        const currentActivity = await getReq(`/v2/garmin/activities/details?user_id=${garminID}&activity_date_from=${moment(weekDateStart).format('YYYY-MM-DD')}&activity_date_to=${moment(weekDateStart).endOf('week').format('YYYY-MM-DD')}`)

        // temporarily using an early date to get some garmin activity for testing purposes
        // moment().startOf('week').format('YYYY-MM-DD') to get this week's activities
        const pastActivity = await getReq(`/v2/garmin/activities/details?user_id=${garminID}&activity_date_from=${moment().startOf('week').format('YYYY-MM-DD')}&activity_date_to=${moment().endOf('week').format('YYYY-MM-DD')}`)

        const dailyRuns = weekDataSelected?.daily_runs ? JSON.parse(weekDataSelected?.daily_runs) : []

        const dailyRunsNewCurrent: any[] = []
        let mergedData: any[] = []

        // console.log('>>> dataUserTrainingPlans: ', dataUserTrainingPlans)

        // const isKm = dataUserTrainingPlans?.is_km

        const currentActivityData = currentActivity.data.result

        // console.log('>>> currentActivityData: ', currentActivityData)

        hasGarminData = currentActivityData.length > 0

        let hasActivityFiltered = false

        // if you want to only have a run data if the user has synced details, then use this instead
        // currentActivity.data.result.forEach
        dailyRuns.forEach((x: any, i: any) => {

            const garminDataRunning = currentActivityData.filter((x: any) => x.activity_details?.summary?.activityType?.toLowerCase().includes('run') || x.activity_details?.summary?.activityType?.toLowerCase().includes('treadmill') || x.activity_details?.summary?.activityName?.toLowerCase().includes('run') || x.activity_details?.summary?.activityName?.toLowerCase().includes('treadmill'))

            // console.log('>>> garminDataRunning: ', garminDataRunning)

            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')

            const allActivities = pastActivity.data.result
            // incase fetching data from the selected week yielded no results, we try getting from all activities data
            const activityFallbackThisDate = !hasGarminData && allActivities && allActivities.filter((x: any) => x.activity_date == thisDate)
            const activityFallbackFilter = activityFallbackThisDate && activityFallbackThisDate.find((x: any) => x.activity_details?.summary?.activityType?.toLowerCase().includes('run') || x.activity_details?.summary?.activityType?.toLowerCase().includes('treadmill') || x.activity_details?.summary?.activityName?.toLowerCase().includes('run') || x.activity_details?.summary?.activityName?.toLowerCase().includes('treadmill'))

            const garminDataOnDate = garminDataRunning && garminDataRunning?.find((x: any) => x.activity_date == thisDate) ? garminDataRunning?.find((x: any) => x.activity_date == thisDate) : activityFallbackFilter
            // const garminDataOnDate = activityFallbackFilter ? activityFallbackFilter : garminDataRunning && garminDataRunning?.find((x: any) => x.activity_date == thisDate)

            // const garminDataMultipleOnDate = currentActivityData?.filter((x: any) => x.activity_date == thisDate)
            const garminDataMultipleOnDate = garminDataRunning?.filter((x: any) => x.activity_date == thisDate)

            if (activityFallbackFilter && activityFallbackFilter != '') hasActivityFiltered = true

            const hasMultipleSyncedActivity = garminDataMultipleOnDate.length > 1

            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'
            const pacesOnThisDay = trainingPlanInfo?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            const plannedPace = dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(pacesOnThisDay, plannedDistance, trainingPlanInfo, userId, customPaces)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(pacesOnThisDay, plannedDistance, trainingPlanInfo, userId, customPaces)?.paceSpeed)) + ' /mile' || 'NA'
            // const plannedPace = buildPaceInitial(marathonPaces, plannedDistance, dataRace, userIdSelected)?.paceSpeed

            const completedDistance = garminDataOnDate && (isKm ? garminDataOnDate?.activity_details?.summary?.distanceInMeters * 0.001 : garminDataOnDate?.activity_details?.summary?.distanceInMeters * 0.000621371)
            const completedPace = garminDataOnDate?.activity_details?.summary?.averagePaceInMinutesPerKilometer

            const completedDistanceUserMarkedComplete = weekNotes[i] && weekNotes[i]?.completed === 1 && plannedDistance
            // console.log('>>> completedDistanceUserMarkedComplete: ', completedDistanceUserMarkedComplete)

            let dayDataUserCompleted: any = []
            let dayDataPlanDetails: any = []

            if (dailyRuns[i]) {

                dayDataPlanDetails = {

                    "day_name": dayOrder[i] || dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "planned_pace": plannedPace,

                    "run_type": dailyRuns[i]?.run_type,
                    "notes": weekNotes[i] ? weekNotes[i]?.notes : '',
                    // "cross_training": dailyRuns[i]?.cross_training,
                }


                hasMultipleSyncedActivity
                    ? garminDataMultipleOnDate.forEach((x: any, i: number) => {

                        // just in case we want the keys to be numbered
                        const keyNameDistance = `${'completed_distance_' + i}`
                        const keyNamePace = `${'completed_pace_' + i}`
                        const keyNameHeartRate = `${'heartRate_' + i}`

                        const completedDistance = isKm ? x?.activity_details?.summary?.distanceInMeters * 0.001 : x?.activity_details?.summary?.distanceInMeters * 0.000621371
                        const completedPace = x?.activity_details?.summary?.averagePaceInMinutesPerKilometer

                        dayDataUserCompleted.push({
                            'completed_distance': (completedDistanceUserMarkedComplete && !completedDistance && completedDistanceUserMarkedComplete) || completedDistance.toFixed(2) || x?.activity_details?.summary?.distanceInMeters,
                            'completed_pace': (isKm ? convertMinutesToMinutesAndSeconds(completedPace) + ' /km' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(completedPace)) + ' /mile') || x?.activity_details?.summary?.averagePaceInMinutesPerKilometer || 'NA',
                            'heartRate': x?.activity_details?.summary?.averageHeartRateInBeatsPerMinute || 'NA',
                        })

                    })
                    : dayDataUserCompleted = {
                        "heartRate": garminDataOnDate?.activity_details?.summary?.averageHeartRateInBeatsPerMinute || 'NA',
                        "completed_distance": (completedDistanceUserMarkedComplete && !completedDistance && completedDistanceUserMarkedComplete) || (completedDistance ? completedDistance.toFixed(2) : '0'),
                        "completed_pace": completedPace ? isKm ? convertMinutesToMinutesAndSeconds(completedPace) + ' /km' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(completedPace)) + ' /mile' : 'NA',
                    }


                let multipleRunningActivity: any = []
                if (hasMultipleSyncedActivity) multipleRunningActivity = { running_activity: dayDataUserCompleted }

                hasMultipleSyncedActivity ? mergedData = { ...dayDataPlanDetails, ...multipleRunningActivity } : mergedData = { ...dayDataPlanDetails, ...dayDataUserCompleted }

                dailyRunsNewCurrent.push(mergedData)

            }

        })

        weekDataSelected = { ...weekDataSelected, daily_runs: JSON.stringify(dailyRunsNewCurrent) }
        // console.log('>>> garmin dailyRunsNewCurrent: ', dailyRunsNewCurrent)
        return { current: weekDataSelected, upcoming: weekDataUpcoming }

    }

    const getAppleHealthKitData = async (userId: number, weekNumSelected: number, isKm: boolean, weekDataSelected: any, weekDataUpcoming: any, trainingPlanInfo: any, customPaces: any, weekNotes: any) => {

        const convertMinPerMeterToMinPerMile = (minPerMeter: any) => {

            const metersPerMile = 1609.344; // Conversion factor: meters in a mile
            const paceInMinPerMile = minPerMeter * metersPerMile
            return paceInMinPerMile
        }


        const convertMetersToMiles = (meters: number) => {
            const metersPerMile = 1609.344
            const miles = meters / metersPerMile
            return miles
        }

        const convertMetersToKm = (meters: number) => {
            const metersPerKm = 1000
            const km = meters / metersPerKm
            return km
        }

        const dailyRunsNewCurrentApple: any[] = []
        const dailyRuns = weekDataSelected?.daily_runs ? JSON.parse(weekDataSelected?.daily_runs) : []

        const weekDateStart = trainingPlanInfo?.training_plan?.find((x: any) => x.week == weekNumSelected)?.monthData?.dateVal

        for (const i in dailyRuns) {

            const thisDate = moment(weekDateStart).add(i, 'days').format('YYYY-MM-DD')
            const currentDate = moment().format('YYYY-MM-DD')
            const plannedDistance = (dailyRuns[i]?.planned_distance && dailyRuns[i]?.planned_distance != 0 && dailyRuns[i]?.planned_distance != '' && !isNaN(Number(dailyRuns[i]?.planned_distance))) ? dailyRuns[i]?.planned_distance : (dailyRuns[i]?.run_distance && dailyRuns[i]?.run_distance != '' && dailyRuns[i]?.run_distance != 0) ? dailyRuns[i]?.run_distance : '0'

            const marathonPaces = trainingPlanInfo?.training_plan.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathonPaces

            await GetAppleHealtActivity(userId, thisDate).then(async (res) => {

                const appleActivityResult = res?.data?.result?.length > 0 && res?.data?.result[0]

                const appleActivityData = appleActivityResult && JSON.parse(appleActivityResult?.activity)

                // might replace || to &&
                const appleActivityRunningData = appleActivityData.length > 0 && appleActivityData.find((x: any) => x.activityName.toLowerCase().includes('run') || x.distanceInMeters > 5)


                const completedDistanceUserMarkedComplete = weekNotes[i] && weekNotes[i]?.completed === 1 && plannedDistance
                // console.log('>>> completedDistanceUserMarkedComplete: ', completedDistanceUserMarkedComplete)

                dailyRunsNewCurrentApple.push({
                    "day_name": dailyRuns[i]?.day_name,
                    "planned_distance": plannedDistance,
                    "completed_distance": (completedDistanceUserMarkedComplete && !appleActivityRunningData.distanceInMeters && completedDistanceUserMarkedComplete) || (appleActivityRunningData.distanceInMeters ? !isKm ? convertMetersToMiles(appleActivityRunningData.distanceInMeters).toFixed(2) : convertMetersToKm(appleActivityRunningData.distanceInMeters).toFixed(2) : '0'),
                    "run_type": dailyRuns[i]?.run_type,
                    // "cross_training": dailyRuns[i]?.cross_training,
                    "heartRate": appleActivityRunningData.heartRate ? appleActivityRunningData.heartRate : 'NA',
                    "planned_pace": dailyRuns[i]?.planned_pace ? dailyRuns[i]?.planned_pace : isKm ? convertMinutesToMinutesAndSeconds(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId, customPaces)?.paceSpeed) + ' /km' || 'NA' : convertMinutesToMinutesAndSeconds(convertToMinPerMile(buildPaceInitial(marathonPaces, plannedDistance, trainingPlanInfo, userId, customPaces)?.paceSpeed)) + ' /mile' || 'NA',
                    "completed_pace": appleActivityRunningData?.pace ? isKm ? convertMinutesToMinutesAndSeconds(appleActivityRunningData.pace * 1000) + ' /km' : convertMinutesToMinutesAndSeconds(convertMinPerMeterToMinPerMile(appleActivityRunningData.pace)) + ' /mile' : 'NA',
                    "notes": weekNotes[i] ? weekNotes[i]?.notes : '',
                })

                // if (dailyRunsNewCurrentApple?.length > 5) {

                //     const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

                //     const sortedRuns = dailyRunsNewCurrentApple?.sort((a: any, b: any) => {
                //         return dayOrder.indexOf(a.day_name) - dayOrder.indexOf(b.day_name)
                //     })

                //     weekDataSelected.daily_runs = JSON.stringify(sortedRuns)
                //     return { current: weekDataSelected, upcoming: weekDataUpcoming }
                // }

            }).catch(err => {
                console.log('>>> GetAppleHealtActivityData err: ', err)
            })
        }
        // console.log('>>>> dailyRunsNewCurrentApple: ', dailyRunsNewCurrentApple)
        weekDataSelected = { ...weekDataSelected, daily_runs: JSON.stringify(dailyRunsNewCurrentApple) }
        return { current: weekDataSelected, upcoming: weekDataUpcoming }
    }

    const checkActiveDevice = async (userId: number) => {
        const devices = ["garmin", "coros", "appleHealth"]
        const garminData = await getGarminUserDetails(userId)
        const corosData = await getCorosUserDetails(userId)
        const appleHealthData = await getAppleHealthUserDetails(userId)
        if (garminData && garminData?.status == 1)
            return devices[0]
        if (corosData && corosData?.status == 1)
            return devices[1]
        if (appleHealthData)
            return devices[2]
        else
            return "NULL"
    }

    const compileNotes = async (dataRace: any, weekNumSelected: number, planIdActive: number) => {

        const abortController = new AbortController()
        const userNotes: any = []

        let i = 0
        for (const day in dayOrder) {

            // dayId = marId
            const dayId = dataRace?.training_plan?.filter((x: any) => x.week == weekNumSelected)[i]?.activitiesValue?.marathon?.id
            // console.log('!!!>>> getting notes, dayId', dayId)

            await GetUserNotes(planIdActive, dayId, abortController.signal)
                .then(async (res: any) => {
                    const notes = res?.data?.result[0]?.comments
                    const markedCompleted = res?.data?.result[0]?.wo_complete
                    // console.log('[ userId, marId ]', [planIdActive, dayId])
                    // console.log('!!! userNotes res', res)

                    userNotes.push({ day: day, notes: notes, completed: markedCompleted })

                    // return comments

                }).catch(err => {
                    console.log('!!! userNotes err', err)
                })

            i++
        }

        const sortedNotes = userNotes?.sort((a: any, b: any) => {
            return dayOrder.indexOf(a.day_name) - dayOrder.indexOf(b.day_name)
        })

        return sortedNotes
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (userInfo && userInfo?.allTrainingData?.user_id) {
            GetUserCustomPace(userInfo?.allTrainingData?.user_id, abortController.signal).then((res: any) => {
                setPlanCustomPaces(res?.data?.result)

            }).catch(err => {
                console.log('!!! customPace err: ', err)
            })
        }

        return () => {
            abortController.abort()
        }

    }, [userInfo])

    return { getWeeklyOutlookDetails, getWeeklyOutlook }
}

export default useWeeklyOutlook
