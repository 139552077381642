/* eslint-disable @typescript-eslint/no-unused-vars */
import useTrainingPlanUtils from "../../hooks/useTrainingPlanUtils"
import useGPTTrainingPlan from "../race/useGPTTrainingPlan"
import useBuildTrainingPlan from "../training-plan/hooks/useBuildTrainingPlan"

const useTrainingTools = (props: any) => {

    const { userInfo } = props

    const { extractPace, getDailyActivityDisplay, getRaceDetailsValues, getRaceDayDetails, getRaceDistance, getRaceDistancePace,
        getOverridePace, distanceInterpret, convertPaceBase, getWorkoutVideoDetails, computelWeekTotalDistance, handleAdjustPaceByLevel } = useBuildTrainingPlan(Number(userInfo?.training_level), Number(userInfo?.type), userInfo?.plan_type, true)

    const { convertToLegacyTrainingInfo, buildProgressionWorkouts, buildTempoWorkouts, buildIntervalWorkouts, buildLastWeekRuns } = useGPTTrainingPlan()


    const getOverridePaceData = async (userId: number, abort: AbortSignal) => {
        await getOverridePace(userId, abort).then((res) => {
            // console.log('the user^s custom pace', res?.data?.result)
            return res?.data?.result
        })
    }


    const calculatePaceSpeed = (pace: string) => {
        const minutesMatch = pace.match(/(\d+)m/)
        const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
        const secondsMatch = pace.match(/(\d+)s/);
        const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0

        const paceMinutes = minutes + (seconds / 60)
        const speed = 1 / (paceMinutes / 60)

        !isFinite(speed) && console.log('pace', pace, 'minutes', minutes, 'seconds', seconds)

        return isFinite(speed) ? convertMphToMinPerKm(speed) : pace
    }


    const convertMphToMinPerKm = (paceMph: any) => {
        // Convert mph to km/h (1 mile = 1.60934 kilometers)
        const speedKmPerHour = paceMph * 1.60934;

        // Calculate pace in min/km
        const minPerKm = 60 / speedKmPerHour;

        return minPerKm;
    }



    const assemblePacesInterval = (raceData: any) => {

        // console.log('>>> assembling interval paces')

        const progressions = raceData?.training_plan.filter((x: any) => x.activitiesValue.marathonPaces.find((y: any) => y.pace === "Progression"))
        const tempo = raceData?.training_plan.filter((x: any) => x.activitiesValue.marathonPaces.find((y: any) => y.pace === "Tempo"))
        const intervals = raceData?.training_plan.filter((x: any) => x.activitiesValue.marathonPaces.find((y: any) => y.pace === "Interval" || y.pace === "Track"))

        if (tempo.length)
            tempo.forEach((x: any) => {
                const distace = x.activitiesValue.marathon.distance
                const workouts = buildTempoWorkouts(distace, x.activitiesValue.marathonPaces, userInfo?.km)
                x.activitiesValue.marathonPaces = undefined
                x.activitiesValue.marathonPaces = workouts
            })
        if (progressions.length)
            progressions.forEach((x: any) => {
                const distace = x.activitiesValue.marathon.distance
                const workouts = buildProgressionWorkouts(distace, x.activitiesValue.marathonPaces, userInfo?.km)
                x.activitiesValue.marathonPaces = undefined
                x.activitiesValue.marathonPaces = workouts
            })
        if (intervals.length)
            intervals.forEach((x: any) => {
                const distace = x.activitiesValue.marathon.distance
                const workouts = buildIntervalWorkouts(distace, x.activitiesValue.marathonPaces, userInfo?.km)
                x.activitiesValue.marathonPaces = undefined
                x.activitiesValue.marathonPaces = workouts
            })

    }

    // builds out the pace for the day, only returns the first pace
    // can create new func to return all paces and change marathonPaces[0] to a for loop instead
    // params gets marathonPaces, current day run distance, and  convertToLegacyTrainingInfo the results of GetTrainingPlanInfo
    const buildPaceInitial = (pacesOnThisDay: any, distanceRunDayCurrent: any, raceData: any, userId: number, customPaces?: any) => {

        let convert: any = []

        assemblePacesInterval(raceData)

        if (isNaN(Number(distanceRunDayCurrent))) distanceRunDayCurrent = 0

        if (!pacesOnThisDay) return { value: [''], rawPace: '', paceSpeed: 0 }

        // console.log('!!! pacesOnThisDay', pacesOnThisDay)
        // console.log('!!! customPaces', customPaces)

        const marathonPaceId = pacesOnThisDay && pacesOnThisDay[0] && ((pacesOnThisDay[0]?.mar_id * 10000) + pacesOnThisDay[0]?.paceId)
        // console.log('!!! marathonPaceId', marathonPaceId)

        const customPaceThisDay = customPaces?.filter((x: any) => x.pid == marathonPaceId)
        // console.log('!!! customPaceThisDay', customPaceThisDay)
        // customPaceThisDay && customPaceThisDay.length > 1 && console.log('!!! multiple paces for the pace_id')


        // customPaces = customPaces?.filter((x: any) => x.marathonPaceId === marathonPaceId)

        // only returns the starting pace of the day, hence pacesOnThisDay[0]
        convert = extractPace(
            pacesOnThisDay[0],
            distanceRunDayCurrent,
            0,
            raceData?.vdot,
            raceData?.km === 'Y' ? true : false,
            raceData?.targetm,
            raceData?.aggressiveness,
            [],
            raceData?.distance
        )

        const value = convert.setPace.split('/')
        const rawPace = convert.rawPace
        const paceSpeedCustom = customPaceThisDay && customPaceThisDay[0]?.pace
        const paceSpeed = paceSpeedCustom ? paceSpeedCustom : rawPace ? (!isNaN(Number(calculatePaceSpeed(rawPace.toString() || '0'))) ? calculatePaceSpeed(rawPace.toString() || '0') : 0) : 0

        // console.log('!!! paceSpeed', paceSpeed)

        return { value, rawPace, paceSpeed }
    }

    const convertToMinPerMile = (minPerKm: any) => {

        minPerKm = minPerKm + ''
        minPerKm = minPerKm.replace('min/km', '')

        const kmToMile = 0.621371; // Conversion factor from kilometers to miles
        const paceInMinPerMile = minPerKm / kmToMile

        return paceInMinPerMile

        // const returnedPace = !isNaN(Number(paceInMinPerMile.toFixed(2))) ? paceInMinPerMile.toFixed(2) : 0
        // return returnedPace + ' min/mile'
    }

    const convertMinutesToMinutesAndSeconds = (minutes: any) => {

        if (isNaN(Number(minutes))) return minutes

        const wholeMinutes = Math.floor(minutes); // Get the whole minutes
        const seconds = Math.round((minutes - wholeMinutes) * 60); // Convert fractional part to seconds
        return `${wholeMinutes} min ${seconds} sec`;
    }


    return { buildPaceInitial, convertToMinPerMile, convertMinutesToMinutesAndSeconds }

}

export default useTrainingTools