import { AppEvent } from "../api/v2/app_events/app-events.types";
import { getReq, postReq } from "./apiConsume";

export const CreateEvent = async (appEvent: AppEvent) =>
    await postReq('/v2/app-events', appEvent)

export const GetAllEvents = async (page: number, limit: number) =>
    await getReq('/v2/app-events', { page, limit })

export const GetEventsByUser = async (userId: number) =>
    await getReq(`/v2/app-events/user/${userId}`)

