/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState, useEffect } from 'react'
import Box from '@mui/material/Box'

import { RSButton1 } from '../../components/buttons';
import { P1, SH1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'
import useRNBridge from '../../hooks/useRNBridge';
import { UpdateNotificationStatus } from '../../modules/accountActions';
import useQueryParams from '../../hooks/useQueryParams';

export type NotificationProps = {
	isAllowed?: any,
	description?: string
	userId?: number
	autoOpen?: boolean
}

const Notification: FC<NotificationProps> = ({ isAllowed, description, userId, autoOpen }) => {

	const { sendDataToReactNative } = useRNBridge()

	const { requestKey: deviceKey } = useQueryParams()

	const requestKey = localStorage.getItem('deviceRequestKey') || deviceKey

	// const handleSave = async () => {
	// 	handleUpdateProfile({ notification: notificationValue }, 'CHANGE_NOTIFICATION')
	// }

	const handleCheckNotification = () => {
		const dataToSend = {
			requestType: 'notification-permission',
			payload: {
				requestPermission: true
			},
			timestamp: new Date().getTime(),
		}
		sendDataToReactNative(dataToSend)
	}

	const handleDisablePermission = () => {
		const dataToSend = {
			requestType: 'notification-permission',
			payload: {
				disable: true
			},
			timestamp: new Date().getTime(),
		}
		sendDataToReactNative(dataToSend)
	}

	useEffect(() => {
		const dataToSend = {
			requestType: 'notification-permission',
			payload: {
				permissionCheck: true
			},
			timestamp: new Date().getTime(),
		}
		sendDataToReactNative(dataToSend)
	}, [])

	useEffect(() => {
		if (autoOpen) {
			handleCheckNotification()
		}
	}, [autoOpen])

	useEffect(() => {
		if (userId) {
			UpdateNotificationStatus(requestKey, isAllowed ? 1 : 0).then((res) => {
				console.log('Notification status updated', res)
			}).catch((err) => {
				console.log('Notification status update failed', err)
			})
		}
	}, [isAllowed, userId])

	return (
		<Box>
			<SH1 size={1} sx={{ width: '100%', mb: 1, ...description && { fontFamily: 'Poppins-Light !important' } }}>{'Notification Settings'}</SH1>
			<P1>{isAllowed ? "RunSmart notification is enabled." : "RunSmart notification is disabled."}</P1>
			<P1>{isAllowed ? "To disable, open RunSmart notification settings." : "To enable, open RunSmart notification settings."}</P1>
			<RSButton1 onClick={isAllowed ? handleDisablePermission : handleCheckNotification} sx={{ mt: 3 }}>Open Settings</RSButton1>
		</Box>
	)
}

export default Notification;
