/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { abs, exp, log, sqrt } from "locutus/php/math";
import ceil from "locutus/php/math/ceil";
import { is_numeric } from "locutus/php/var";
import { WEATHER_API_KEYS } from "../../../modules/cliEnvValues";
import { ITrainingPlanStore } from "../../../store/createTrainingPlanStore";
import useStore from "../../../store/useStore";

const WEATHER_URL_NAME = `https://api.openweathermap.org/data/2.5/weather?units=imperial&APPID=${WEATHER_API_KEYS}&q=`;
const WEATHER_URL_ZIP = `https://api.openweathermap.org/data/2.5/weather?units=imperial&APPID=${WEATHER_API_KEYS}&zip=`;

const WTA_MAX = 180;

const C_OFFSET = 273.15
const n1 = 0.11670521452767e4
const n6 = 0.14915108613530e2
const n2 = -0.72421316703206e6
const n7 = -0.48232657361591e4
const n3 = -0.17073846940092e2
const n8 = 0.40511340542057e6
const n4 = 0.12020824702470e5
const n9 = -0.23855557567849
const n5 = -0.32325550322333e7
const n10 = 0.65017534844798e3
const k0 = -5.8666426e3
const k1 = 2.232870244e1
const k2 = 1.39387003e-2
const k3 = -3.4262402e-5
const k4 = 2.7040955e-8
const k5 = 6.7063522e-1
// const NaN = null
const minT = 173 // -100 Deg. C.
const maxT = 678


// this is used by Weekly Outlook, since v1 has hooks
const useWeatherV2 = () => {

    // const { currentLocation } = useStore((state: ITrainingPlanStore) => state)

    let weatherValue = 0
    let weatherData: any = null

    const isValidZipCode = (zipCode: string) => {
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
    }

    const applyCurrentLocation = async (currentLocation: string) => {
        
        // console.log('getting weather')
        const abortController = new AbortController();
        const validZipCode = isValidZipCode(currentLocation)

        if (currentLocation)
            await axios.get(validZipCode ? WEATHER_URL_ZIP + currentLocation : WEATHER_URL_NAME + currentLocation, { signal: abortController.signal }).then(res => {
                weatherData = res
                const dew = getDewPoint(weatherData?.data?.main?.humidity, weatherData?.data?.main?.temp);
                const val = ceil(weatherData?.data?.main?.temp + dew)
                weatherValue = val

                // console.log('!!! weatherValue:', weatherValue)

            }).catch((err) => {
                console.log('weather error:', err)
            })

    }


    const getAdjustment = (location?: string) => {

        applyCurrentLocation(location || '')

        if (weatherValue == 0) {
            return false;
        }
        if (weatherValue < 100) {
            return 0;
        } else if (weatherValue >= 100 && weatherValue <= 110) {
            return gradingSolve(0, 0.5, 100, 110, weatherValue);
        } else if (weatherValue > 110 && weatherValue <= 120) {
            return gradingSolve(0.5, 1, 110, 120, weatherValue);
        } else if (weatherValue > 120 && weatherValue <= 130) {
            return gradingSolve(1, 2, 120, 130, weatherValue);
        } else if (weatherValue > 130 && weatherValue <= 140) {
            return gradingSolve(2, 3, 130, 140, weatherValue);
        } else if (weatherValue > 140 && weatherValue <= 150) {
            return gradingSolve(3, 4.5, 140, 150, weatherValue);
        } else if (weatherValue > 150 && weatherValue <= 160) {
            return gradingSolve(4.5, 6, 150, 160, weatherValue);
        } else if (weatherValue > 160 && weatherValue <= 170) {
            return gradingSolve(6, 8, 160, 170, weatherValue);
        } else if (weatherValue > 170 && weatherValue <= 180) {
            return gradingSolve(8, 10, 170, 180, weatherValue);
        } else if (weatherValue > 180) {
            return 10000;
        }
    }

    const getWTA = (location?: string) => {

        applyCurrentLocation(location || '')

        const wta = ceil(weatherValue * 100 / WTA_MAX);
        // if (wta > 94) {
        //     const msg = "Due to current weather conditions and your WTA Score, we suggest you avoid all hard running or perform the given run inside on a treadmill.";
        // }
        return wta;
    }

    const getDewPoint = (RH: any, T: any) => {
        if (!is_numeric(T)) {
            return T;
        }

        T = T + C_OFFSET; // C to K
        return solve(RH / 100 * PVS(T), T) - C_OFFSET;
    }

    const solve = (y: any, x0: any) => {
        let x = x0;
        const maxCount = 10;
        let count = 0;
        let cont;

        do {
            const dx = x / 1000;
            const z = PVS(x);
            const xNew = x + dx * (y - z) / (PVS(x + dx) - z);
            if (abs((xNew - x) / xNew) < 0.0001) {
                cont = false;
                return xNew;
            } else if (count > maxCount) {
                const xnew = NaN;
                cont = false;
                return C_OFFSET;
            }
            cont = true;
            x = xNew;
            count++;
        } while (cont);
    }

    const PVS = (T: any) => {
        if (T < minT || T > maxT) {
            return NaN;
        } else if (T < C_OFFSET) {
            return pvsIce(T);
        } else {
            return pvsWater(T);
        }
    }

    const pvsIce = (T: any) => {
        const lnP = k0 / T + k1 + (k2 + (k3 + (k4 * T)) * T) * T + k5 * log(T);
        return exp(lnP);
    }

    const pvsWater = (T: any) => {
        const th = T + n9 / (T - n10);
        const A = (th + n1) * th + n2;
        const B = (n3 * th + n4) * th + n5;
        const C = (n6 * th + n7) * th + n8;

        let p = 2 * C / (-B + sqrt(B * B - 4 * A * C));
        p *= p;
        p *= p;
        return p * 1e6;
    }

    const gradingSolve = (x1: any, x2: any, x3: any, x4: any, x5: any) => {
        const difference_limits = x4 - x3;
        const difference_ratio = x2 - x1;
        const removed = x5 - x3;
        return 1 + (x1 + (removed * difference_ratio) / difference_limits) / 100;
    }

    return { getWTA, getAdjustment, weatherData }
}

export default useWeatherV2
