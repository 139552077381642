/* eslint-disable @typescript-eslint/no-unused-vars */

import { Accordion, AccordionDetails, AccordionSummary, Box, ClickAwayListener, FormControl, Grid, Input, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, Switch, Tab, TableContainer, Tabs } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Form, Table } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import moment from 'moment'
import RSButton from '../../components/buttons/RSButton'
import RaceSelection from '../../pages/training-plan/build/RaceSelection.json'
import JSONTable from './JSONTable'
import { DAYS_OF_WEEK, sortDays } from '../training-plan/build/DaysOptions'
import { User } from 'recurly'
import RSDrawer from '../../components/layout/RSDrawer'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import GPTTrainingPlan from '../../pages/training-plan/GPTTrainingPlan'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import numberHelperUtil from '../../utils/numberHelperUtil'
import ScrollRow from '../../components/layout/ScrollRow'
import SelectPill from '../../components/input/SelectPill'
import useScrollRow from '../../hooks/useScrollRow'
import PromptVersion from './PromptVersion'
import PromptVersionForm from './PromptVersionForm'
import useGPTPrompts from './useGPTPrompts'
import { OpenAIPrompt } from '../../api/v2/open-ai/OpenAIPrompt.types'
import BuildPlanAvailability from './BuildPlanAvailability'
import { TabPanel } from '@mui/base/TabPanel';
import useRSTabs from '../../hooks/useRSTabs';
import CalendarMonthIcon from '@mui/icons-material/EventNoteOutlined';
import RSFlagButton from '../../components/buttons/RSFlagButton';
import { getReq } from '../../modules/apiConsume';
import ChatCompletionSingle from './ChatCompletionSingle'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow"
import { UpdateAItrainingPlan } from '../../modules/aiTrainingPlanActions';

////////

import { createChangeLogEditDailies, getChangeLogEditDailies } from '../../modules/trainingPlanActions'
import useBuildTrainingPlan from '../training-plan/hooks/useBuildTrainingPlan';

const tableHeaderStyling = {
    padding: "0px 0px",
    fontSize: 18,
    borderBottom: "1px solid",
    borderBottomColor: "#1976d2",
    backgroundColor: "primary.main"
};

const tableBodyStyling = {
    backgroundColor: "success.light",
    // borderLeft: "1px solid #EEEEEE",
    color: "secondary.dark",
    fontSize: 16
}

const RUN_TYPES = ['Easy', 'Progression', 'Tempo', 'Race Pace', 'Base', 'Interval', 'Rest']
const XT_TYPES = ['Strength Training', 'Yoga', 'Stretching', 'None']

///////

const TrainingPlanManagerV2 = (option: any) => {
    const { isScrolling, setIsScrolling } = useScrollRow()

    const MENUS = ['Plan History', 'Build Availability', 'Version']
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const STATUS_FILTER = ['Declined', 'Active', 'All']
    const RACE_TYPE_FILTER = ['5K', '8K', '10K', 'HALF', 'FULL', 'MAINTENANCE', 'All']

    const activeColor = '#e0e0e0'

    const [status, setStatus] = useState(2)
    const [filterRaceType, setFilterRaceType] = useState('...')
    const [searchValue, setSearchValue] = useState('')
    const [page, setPage] = useState(0)
    const [showOutput, setShowOutput] = useState(false)
    const [trainingPlans, setTrainingPlans] = useState<any>([])
    const [selectedMenu, setSelectedMenu] = useState(MENUS[0])
    const [selectedPlan, setSelectedPlan] = useState<any>(null)
    const [isEndOfResults, setIsEndOfResults] = useState(false)
    const [searchUserText, setSearchUserText] = useState('')
    const [searchTextVal, setSearchTextVal] = useState('')
    const [openViewPlan, setOpenViewPlan] = useState(false)
    const [tabOptions, setTabOptions] = useState([])
    const [selectedPill, setSelectedPill] = useState('Final Plan')
    const { ConvertM2H2, ConvertM2H, ConvertFullToHalf } = useTrainingPlanUtils()
    const { convertNumberToWord } = numberHelperUtil()
    const [selectedPrompt, setSelectedPrompt] = useState<OpenAIPrompt>()
    const [lastVersion, setLastVersion] = useState(0)
    const { prompts, updatePromptVersion, createPromptVersion, isSavingOrUpdating, mutatePrompts, checkDupplicatePrompt, deletePromptVersion, activatePromptVersion, restorePromptVersion } = useGPTPrompts({})
    const { extractPace } = useBuildTrainingPlan(Number(selectedPlan?.training_level), 0, 'GPT')


    const [planListSlim, setPlanListSlim] = useState(true)
    const [pillKey, setPillKey] = useState(0)
    const [displayTokens, setDisplayTokens] = useState(false)
    const [displayTokenHovered, setDisplayTokenHovered] = useState('')
    const [displayFirstName, setDisplayFirstName] = useState(false)
    const [displayExpand, setDisplayExpand] = useState(true)
    const [styleBulkColor, setStyleBulkColor] = useState(false)
    const [isSearching, setIsSearching] = useState(false)


    ////// >> edit plan changes. can seperate into another component

    const [rowIndex, setRowIndex] = React.useState(-1);
    const [columnIndex, setColumnIndex] = React.useState(-1)
    const [planRows, setPlanRows] = useState<any>([])
    const [planWeeksChanged, setPlanWeeksChanged] = useState<any>([])
    const [isUpdating, setIsUpdating] = useState(false)
    const [onDropdown, setOnDropdown] = useState(false)
    const [displaySavePopup, setDisplaySavePopup] = useState(false)
    const [editSaveSuccess, setEditSaveSuccess] = useState(false)
    const [editSaveErrorMsg, setEditSaveErrorMsg] = useState('Contact Runsmart Devs')
    const [planLastEdited, setPlanLastEdited] = useState('')
    const [editedPlanList, setEditedPlanList] = useState<any>([])

    const distanceUnit = selectedPlan?.profile?.distance_unit?.toLowerCase() ? (" " + selectedPlan?.profile?.distance_unit?.toLowerCase()) : ' mi'

    const handleChangeRunDistance = (rowIndex: number, dayIndex: string, value: string) => {

        const runDistance = !isNaN(Number(value)) ? Number(value) : 0

        if (planRows[rowIndex].days[dayIndex].run_distance != runDistance) {

            planRows[rowIndex].days[dayIndex].run_distance = runDistance

            !planWeeksChanged.includes(rowIndex) && setPlanWeeksChanged([...planWeeksChanged, rowIndex])

        }
    }


    const handleChangeSelectRunType = (rowIndex: number, dayIndex: string, value: string) => {
        const runType = value

        if (planRows[rowIndex].days[dayIndex].run_type != runType) {

            planRows[rowIndex].days[dayIndex].run_type = runType

            !planWeeksChanged.includes(rowIndex) && setPlanWeeksChanged([...planWeeksChanged, rowIndex])

        }

    }

    const handleChangeSelectXTtype = (rowIndex: number, dayIndex: string, value: string) => {
        const crossTraining = value

        if (planRows[rowIndex].days[dayIndex].cross_training != crossTraining) {

            planRows[rowIndex].days[dayIndex].cross_training = crossTraining

            !planWeeksChanged.includes(rowIndex) && setPlanWeeksChanged([...planWeeksChanged, rowIndex])

        }
    }

    const handleExit = () => {
        setRowIndex(-1)
        setColumnIndex(-1)
    }

    const handleUpdateSelectedPlan = async () => {

        planWeeksChanged.map((weekIndex: number) => {

            setIsUpdating(true)

            const weekData = planRows[weekIndex].days
            const planId = selectedPlan?.plan_id - 50000

            const updatedPlan = {
                plan_id: planId,
                week: weekIndex + 1,
                user_id: selectedPlan?.user_id,
                daily_runs: JSON.stringify(weekData),
            }

            // console.log('updatedPlan. week: ', weekIndex + 1, updatedPlan)

            UpdateAItrainingPlan(updatedPlan).then((res) => {
                // console.log('updatedPlan. res: ', res)
                setEditSaveSuccess(true)
                setDisplaySavePopup(true)
                setPlanWeeksChanged([])

                createChangeLogEditDailies({
                    plan_id: planId,
                    week: weekIndex + 1,
                    day: 0,
                    previous_value: 'N/A',
                    new_value: JSON.stringify(weekData),
                    user_id: selectedPlan?.user_id,
                    change_type: 'EDIT_DAILIES'
                })
                    .then((res) => {
                        // console.log('>>> saving logs', res)
                    })
                    .catch((err) => {
                        console.log('>>> saving logs err', err)
                    })


            }).catch((err) => {
                // console.log('>>> saving to DB err', err)
                setEditSaveErrorMsg(err)
                setEditSaveSuccess(false)
                setDisplaySavePopup(true)
            })
        })

        setIsUpdating(false)
    }


    const getPlanLastEditDate = async () => {
        selectedPlan?.plan_id && selectedPlan?.plan_id > 0 && await getChangeLogEditDailies(selectedPlan?.plan_id - 50000).then((res) => {
            // console.log('>>> last modified: ', res?.data?.result[0]?.last_modified)
            // console.log('>>> last modified translated: ', moment(res?.data?.result[0]?.last_modified).format('MMM D, HH:MM zz'))
            res?.data?.result[0]?.last_modified && setPlanLastEdited(res?.data?.result[0]?.last_modified ? moment(res?.data?.result[0]?.last_modified).format('MMM D, HH:MM zz') || 'unreadable date' : 'N/A')
        }).catch((err) => {
            console.log('>>> getChangeLogEditDailies err', err)
            setPlanLastEdited('')
        })
    }

    const checkPlanEdited = async (planId: number) => {
        // const isEdited = await 

        return getChangeLogEditDailies(planId - 50000).then((res) => {
            // console.log('>>> checkPlanEdited: ', planId, res?.data?.result[0]?.last_modified)
            return res?.data?.result[0]?.last_modified != '' && res?.data?.result[0]?.last_modified != null && res?.data?.result[0]?.last_modified != undefined ? true : false
        }).catch((err) => {
            return false
        })
    }

    const markEditedPlans = async () => {
        const modifiedPlans: any = []

        trainingPlans.map(async (plan: any) => {
            if (await checkPlanEdited(plan?.plan_id)) {
                modifiedPlans.push(plan?.plan_id)
                setEditedPlanList(modifiedPlans)
            }
        })

    }

    ///// <<

    const limit = planListSlim ? 8 : 8


    const { data, isLoading } = useSWR(`${searchValue != '' && !isNaN(Number(searchValue)) ? `/gpt-training-plan-info/id?plan=${searchValue}`
        : searchValue != '' ? `/gpt-training-plan-info/search?searchText=${searchValue}&page=${page}&limit=${limit}&value=${status}&type=${filterRaceType}`
            : (filterRaceType != '' && filterRaceType != 'All') ? `/gpt-training-plan-info/status-type?value=${status}&type=${filterRaceType}&page=${page}&limit=${limit}`
                : `/gpt-training-plan-info/status?value=${status}&page=${page}&limit=${limit}`}`, { revalidateOnFocus: false })



    const { mutate } = useSWRConfig()




    const handleChangeSelectStatus = (event: any) => {
        setPage(0)
        setTrainingPlans([])
        setStatus(event.target.value)
    }

    const handleChangeSelectRaceType = (event: any) => {
        setOnDropdown(false)
        setPage(0)
        setTrainingPlans([])
        setFilterRaceType(event.target.value)
    }

    const handleSearchEnter = async (e: any) => {
        // setIsSearching(true)
        setPage(0)
        try {
            if (e.target.value === "") {
                setTrainingPlans([])
                setSearchUserText('')
                setSearchValue(e.target.value)
                setIsSearching(false)
            }
            else if (e.target.value.length < 3) {
                alert('Please enter at least 3 characters')
                setIsSearching(false)
            }
            else {
                setTrainingPlans([])
                setSearchValue(e.target.value)
                // const result = await getReq(`/v2/gpt-training-plan-info/search?searchText=${e.target.value}&page=${0}&limit=${limit}`)
                // setTrainingPlans(result.data.result)
                // setIsSearching(false)
            }
        }
        catch (error) {
            console.log('Error searching', error)
            setIsSearching(false)
        }
    }

    const handleSubmitPrompt = async (data: OpenAIPrompt, type: string) => {
        if (type === 'update') {
            await updatePromptVersion(data)
            return true
        }
        else {
            const isDupp = checkDupplicatePrompt(prompts?.data?.result as OpenAIPrompt[], data?.race_type as string, data?.step as string, data?.version as number)
            if (isDupp) {
                alert('Prompt with the same version and step already exists')
                return false
            }
            else {
                await createPromptVersion(data)
                return true
            }
        }
    }

    const onPillChange = (value: any) => {
        setSelectedPill(value)
        // setPillKey(value === "Introduction" ? 0 : 1)
    }

    const getSuggestedDistance = (range: string) => {
        const diff: number = Number(range?.split("-")[1] || 0) - Number(range?.split("-")[0] || 0)
        const suggestedDistance = diff / 2
        return Math.ceil(Number(range?.split("-")[1] || 0) - suggestedDistance)
    }

    const getGoalTime = (goalTime: number, type: string) => {
        if (goalTime) return ConvertM2H2(goalTime)
        // if (goalTime)
        //     if (type === "half" || type === "full") {
        //         // return ConvertM2H2((goalTime / 2))
        //         return ConvertM2H2(goalTime)
        //     }
        //     else {
        //         return ConvertM2H(goalTime)
        //     }
    }

    const renderVersionForm = () => {
        return <>
            <PromptVersionForm lastVersion={lastVersion} isLoading={isSavingOrUpdating} submit={(data, type) => handleSubmitPrompt(data, type)} data={selectedPrompt} setSelectedPrompt={setSelectedPrompt} />
        </>

    }

    const getReruns = (plan: any, stepNumber: string) => {
        const stepReruns = plan?.steps?.find((obj: any) => obj.step?.toUpperCase()?.includes(stepNumber))?.step.match(/\[(.*?)\]/)
        return stepReruns ? (stepReruns[1] > 0 ? stepReruns[1] : '') : ''
    }

    const getRerunsTotal = (data: any) => Number(getReruns(data, '2') > 0 ? getReruns(data, '2') : 0) + Number(getReruns(data, '3') > 0 ? getReruns(data, '3') : 0)


    const getPlanLevel = () => selectedPlan?.race_type?.toUpperCase().includes('MAINTENANCE') ? selectedPlan?.training_level == 1 ? 'Maintain' : 'Build' : selectedPlan?.training_level

    const calculateTotalTokens = (data: any) => {

        let totalTokens = 0
        data?.steps?.map((step: any) => {
            const parsedData = JSON.parse(step.data || '{}')
            totalTokens += (parsedData.tokens?.total_tokens || 0)
        })
        return totalTokens
    }

    const calculateInputTokens = (data: any) => {

        let totalTokens = 0
        data?.steps?.map((step: any) => {
            const parsedData = JSON.parse(step.data || '{}')
            totalTokens += (parsedData.tokens?.prompt_tokens || 0)
        })
        return totalTokens
    }

    const calculateOutputTokens = (data: any) => {

        let totalTokens = 0
        data?.steps?.map((step: any) => {
            const parsedData = JSON.parse(step.data || '{}')
            totalTokens += (parsedData.tokens?.completion_tokens || 0)
        })
        return totalTokens
    }


    const loadMorePlans = async () => {

        const result = await getReq(`/v2/gpt-training-plan-info/search?searchText=${searchValue}&page=${page}&limit=${limit}`)
        setTrainingPlans([...trainingPlans, ...result.data.result])

    }


    const displayTokensUsed = (data: any, type: string) => {

        const totalTokens = type == 'TOTAL' ? calculateTotalTokens(data) : type == 'INPUT' ? calculateInputTokens(data) : calculateOutputTokens(data)
        return totalTokens > 0 ? totalTokens : "N/A"
    }


    // // under construction
    const getAllPaces = () => {

        const allPaceTypes = ['Base', 'Easy', 'Progression', 'Tempo', 'Interval', 'Race Pace']

        const paceValues = []

        allPaceTypes.forEach((paceType: string) => {

            const dayPaceValues = {
                "pace": paceType,
                "notes": paceType == 'Race Pace' ? '5 miles' : "1 miles",
                "orderid": 1
            }

            // ? does this value affect the pace
            const currentDayDistance = 1

            // temp value, value used is half marathon
            // const currentRaceDistance = 38

            const pace = extractPace(
                dayPaceValues,
                currentDayDistance,
                0,
                selectedPlan?.vdot,
                selectedPlan?.isKm,
                selectedPlan?.target_time,
                0,
                [],
                selectedPlan?.max_distance
            )

            console.log('pace', paceType, pace)

            paceValues.push({ paceType: paceType, pace: pace })

        })
    }

    useEffect(() => {
        mutate(
            key => true, // which cache keys are updated
            undefined, // update cache data to `undefined`
            { revalidate: false } // do not revalidate
        )

        setTimeout(() => {
            setFilterRaceType('All')
        }, 1000)
    }, [])



    useEffect(() => {
        if (searchTextVal === "") {
            setPage(0)
            setTrainingPlans([])
            setSearchUserText('')
        }
    }, [searchTextVal])


    useEffect(() => {
        setIsEndOfResults(false)
        if (data && trainingPlans.length > 0 && data.data.result.length > 0 && trainingPlans[0] != data.data.result[0]) {
            setTrainingPlans([...trainingPlans, ...data.data.result])
            setIsEndOfResults(data?.data?.result?.length < limit)
            setIsSearching(false)
        }
        else if (data) {
            // console.log('>>> new set of fetched data', data?.data?.result)
            setTrainingPlans(data.data.result)
            setIsEndOfResults(data?.data?.result?.length < limit)
            setIsSearching(false)
        }
    }, [data, page, status, searchUserText])


    useEffect(() => {

        markEditedPlans()

    }, [trainingPlans, displaySavePopup])

    useEffect(() => {
        // console.log('selectedPlan', selectedPlan)
        // console.log('selectedPlan', JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 5 : 4]?.data || '{}') || 'no data')
        const tabs: any = []
        tabs.push('Final Plan')
        // tabs.push('Plan Preview')

        if (selectedPlan?.steps?.length > 0) {
            const steps = selectedPlan?.steps.length > 5 ? selectedPlan?.steps.slice(0, 5) : selectedPlan?.steps
            steps.map((x: any, i: number) => {
                i != 0 && tabs.push('Step ' + convertNumberToWord(i))
            })
        }

        setTabOptions(tabs)

        ///// edit plan changes
        if (selectedPlan?.steps?.length > 0) {

            const planComplete: any[] = []

            selectedPlan?.training?.map((week: any, i: number) => {

                const planDailyRuns: { day_name: any; run_distance: any; run_type: any; cross_training: any; }[] = []

                JSON.parse(week?.daily_runs)?.map((run: any, index: number) => (

                    planDailyRuns.push({
                        day_name: run?.day_name,
                        run_distance: run?.run_distance,
                        run_type: run?.run_type,
                        cross_training: run?.cross_training
                    })
                ))

                planComplete.push({
                    week: week?.week,
                    weekly_mileage: week?.weekly_mileage,
                    days: planDailyRuns
                })

            })
            // console.log('planComplete', planComplete)
            setPlanRows(planComplete)
        }

        console.log('selectedPlan', selectedPlan)

        getPlanLastEditDate()

        getAllPaces()

        console.log('selectedPlan', selectedPlan)

    }, [selectedPlan])


    useEffect(() => {
        setSelectedMenu(option.selectedTP != '' && option.selectedTP || 'plans')
    }, [option])



    const renderRurunTabWeekly = () => {

        // console.log('!!! selectedPlan', selectedPlan)

        // const parsedData = JSON.parse(selectedPlan?.steps[1]?.data || '{}')

        // console.log('step one parsedData1', selectedPlan?.steps[1]?.data)
        // console.log('step one parsedData2', selectedPlan?.steps?.find((x: any) => x?.step?.toLowerCase()?.includes('step 2'))?.data)

        const stepData2 = selectedPlan?.steps?.find((x: any) => x?.step?.toLowerCase()?.includes('step 2'))?.data

        const parsedData = stepData2 ? JSON.parse(stepData2) : JSON.parse(selectedPlan?.steps[1]?.data || '{}')

        const dataResultsUnmodified = (parsedData?.unmodified?.length > 0 && parsedData?.unmodified) || []
        const dataResultsModified = (parsedData?.modified?.length > 0 && parsedData?.modified) || (parsedData?.length > 0 && parsedData)

        const dataResultsHeader = (dataResultsModified?.length > 0 && Object.keys(dataResultsModified[0])) || []

        return <>
            <div style={{ border: '4px solid #CCC', borderRadius: '10px' }}>
                <Accordion sx={{ my: '1rem' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        Unmodified Results
                    </AccordionSummary>
                    <AccordionDetails>
                        {dataResultsUnmodified?.length > 0 && (
                            <Grid container>
                                <Grid xs={12}>
                                    <Table responsive bordered hover size="sm">
                                        <thead>
                                            <tr style={{ fontWeight: 'bold' }}>
                                                {dataResultsHeader?.map((x: any, i: number) => (
                                                    <td key={i}>{x.replaceAll('_', ' ')}</td>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataResultsUnmodified?.map((x: any, i: number) => (
                                                    <tr key={i}>
                                                        <td>{x.Week}</td>
                                                        <td>{x.Long_run}</td>
                                                        <td>{x.Weekly_mileage}</td>
                                                        <td>{x.Taper ? 'true' : ''}</td>
                                                        <td>{x.Recovery ? 'true' : ''}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Grid>
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        Final Results
                    </AccordionSummary>
                    <AccordionDetails>
                        {dataResultsModified?.length > 0 && (
                            <Grid container>
                                <Grid xs={12}>
                                    <Table responsive bordered hover size="sm">
                                        <thead>
                                            <tr style={{ fontWeight: 'bold' }}>
                                                {dataResultsHeader?.map((x: any, i: number) => (
                                                    <td key={i}>{x.replaceAll('_', ' ')}</td>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataResultsModified?.map((x: any, i: number) => (
                                                    <tr key={i}>
                                                        <td>{x.Week}</td>
                                                        <td>{x.Long_run}</td>
                                                        <td>{x.Weekly_mileage}</td>
                                                        <td>{x.Taper ? 'true' : ''}</td>
                                                        <td>{x.Recovery ? 'true' : ''}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Grid>
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div style={{ border: '4px solid #CCC', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignContent: 'space-evenly', margin: '0.2rem 0' }}>
                <span style={{ margin: '0 1rem' }}>INPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.prompt_tokens}</span></span>
                <span style={{ margin: '0 1rem' }}>OUTPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.completion_tokens}</span></span>
                <span style={{ margin: '0 1rem' }}>TOTAL TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.total_tokens}</span></span>
            </div>
        </>
    }

    const renderRurunTabDaily = () => {


        // console.log('step two parsedData1', selectedPlan?.steps[2]?.data)
        // console.log('step two parsedData2', selectedPlan?.steps?.find((x: any) => x?.step?.toLowerCase()?.includes('step 3'))?.data)

        const stepData2 = selectedPlan?.steps?.find((x: any) => x?.step?.toLowerCase()?.includes('step 3'))?.data

        const parsedData = stepData2 ? JSON.parse(stepData2) : JSON.parse(selectedPlan?.steps[2]?.data || '{}')

        

        const dataResultsUnmodified = (parsedData?.unmodified?.length > 0 && parsedData?.unmodified) || []
        const dataResultsModified = (parsedData?.modified?.length > 0 && parsedData?.modified) || (parsedData?.length > 0 && parsedData)

        const dataResultsHeader = (dataResultsModified?.length > 0 && Object.keys(dataResultsModified[0])) || []

        return <>
            {!stepData2 || (stepData2 && !dataResultsModified[0]?.days ) ? <div style={{ border: '4px solid #CCC', borderRadius: '10px' }}>
                <Accordion sx={{ my: '1rem' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        Unmodified Results
                    </AccordionSummary>
                    <AccordionDetails>
                        {dataResultsUnmodified?.length > 0 && (
                            <Grid container>
                                <Grid xs={12}>

                                    <Table responsive bordered hover size="sm">
                                        <thead>
                                            <tr style={{ fontWeight: 'bold' }}>
                                                {dataResultsHeader?.map((x: any, i: number) => (
                                                    <td key={i}>{x.replaceAll('_', ' ')}</td>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataResultsUnmodified?.map((x: any, i: number) => (
                                                    <tr key={i}>
                                                        {dataResultsHeader?.map((e: any, o: number) => (
                                                            <td key={o}>{x[`${e}`]}</td>
                                                        ))}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                    {/* displaying data that are not properly transformed */}
                                    {/* <Table responsive  bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Week</th>
                                            <th>Weekly Mileage</th>
                                            {dataResultsHeader?.map((x: any, i: number) => (
                                                !isNaN(Number(x)) && <td key={i}>{daysOfWeek[(x - 1) || 0]}</td>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataResultsUnmodified?.map((week: any, i: number) => (
                                                <tr key={i}>
                                                    <td>{week.w}</td>
                                                    <td>{week?.m}</td>
                                                    {dataResultsHeader?.map((d: any, o: number) => (
                                                        !isNaN(d) && <td key={o}>{week[`${d}`]}</td>
                                                    ))}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> */}

                                </Grid>
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        Final Results
                    </AccordionSummary>
                    <AccordionDetails>
                        {dataResultsModified?.length > 0 &&
                            <Grid container>
                                <Grid xs={12}>
                                    <Table responsive bordered hover size="sm">
                                        <thead>
                                            <tr style={{ fontWeight: 'bold' }}>
                                                {dataResultsHeader?.map((x: any, i: number) => (
                                                    <td key={i}>{x.replaceAll('_', ' ')}</td>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataResultsModified?.map((x: any, i: number) => (
                                                    <tr key={i}>
                                                        {dataResultsHeader?.map((e: any, o: number) => (
                                                            <td key={o}>{x[`${e}`]}</td>
                                                        ))}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Grid>
                            </Grid>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>

                : <div style={{ border: '4px solid #CCC', borderRadius: '10px' }}>
                    <Table responsive bordered hover size="sm">
                        <thead>
                            <tr>

                                <th>Week</th>
                                <th>Weekly Mileage</th>
                                {dataResultsModified?.length > 0 && dataResultsModified[0].days.map((day: any, dI: number) => <>
                                    <th>{day.day_name || day.day}</th>
                                    {/* <th>Type</th> */}
                                </>)}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataResultsModified?.map((week: any, wI: number) =>
                                    <tr key={wI}>
                                        <td>{week.week}</td>
                                        <td>{week?.weekly_mileage || week?.mileage}</td>
                                        {week.days.map((day: any, dI: number) => <>
                                            <td>{day.run_distance || day.distance} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type) ? '-' : ''} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type).replace('Run', '')}</td>
                                        </>)}
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>}
            <div style={{ border: '4px solid #CCC', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignContent: 'space-evenly', margin: '0.2rem 0' }}>
                <span style={{ margin: '0 1rem' }}>INPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.prompt_tokens}</span></span>
                <span style={{ margin: '0 1rem' }}>OUTPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.completion_tokens}</span></span>
                <span style={{ margin: '0 1rem' }}>TOTAL TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.total_tokens}</span></span>
            </div>
        </>
    }


    const renderRunTypeTable = () => {

        const stepDataFound = selectedPlan?.steps?.find((x: any) => (x.step?.toLowerCase()?.includes('step 4')))?.data

        const dataResults = stepDataFound ? JSON.parse(stepDataFound)?.modified : JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 4 : 3]?.data || {})?.modified || JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 4 : 3]?.data || {})

        const parsedData = stepDataFound ? JSON.parse(stepDataFound) : JSON.parse(selectedPlan?.steps[3]?.data || '{}')

        return <>
            <div style={{ border: '4px solid #CCC', borderRadius: '10px' }}>
                <Table responsive bordered hover size="sm">
                    <thead>
                        <tr>

                            <th>Week</th>
                            <th>Weekly Mileage</th>
                            {dataResults?.length > 0 && dataResults[0].days.map((day: any, dI: number) => <>
                                <th>{day.day_name || day.day}</th>
                                {/* <th>Type</th> */}
                            </>)}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataResults?.map((week: any, wI: number) =>
                                <tr key={wI}>
                                    <td>{week.week}</td>
                                    <td>{week?.weekly_mileage || week?.mileage}</td>
                                    {week.days.map((day: any, dI: number) => <>
                                        <td>{day.run_distance || day.distance} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type) ? '-' : ''} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type).replace('Run', '')}</td>
                                    </>)}
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>

            <div style={{ border: '4px solid #CCC', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignContent: 'space-evenly', margin: '0.2rem 0' }}>
                <span style={{ margin: '0 1rem' }}>INPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.prompt_tokens}</span></span>
                <span style={{ margin: '0 1rem' }}>OUTPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.completion_tokens}</span></span>
                <span style={{ margin: '0 1rem' }}>TOTAL TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.total_tokens}</span></span>
            </div>
        </>
    }


    const renderCrossTrainTable = () => {

        const stepDataFound = selectedPlan?.steps?.find((x: any) => (x.step?.toLowerCase()?.includes('final')))?.data

        const parsedData = stepDataFound ? JSON.parse(stepDataFound) : JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 5 : 4]?.data || '{}')

        const dataResults = parsedData?.modified?.length > 0 ? parsedData?.modified : parsedData

        return <>
            <div style={{ border: '4px solid #CCC', borderRadius: '10px' }}>
                <Table responsive bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Week</th>
                            <th>Weekly Mileage</th>
                            {dataResults?.length > 0 && dataResults[0]?.days?.map((day: any, dI: number) => <>
                                <th>{day?.day_name || day?.day}</th>
                                {/* <th>Type</th> */}
                            </>)}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataResults?.map((week: any, wI: number) =>
                                <tr key={wI}>
                                    <td>{week?.week}</td>
                                    <td>{week?.weekly_mileage || week?.mileage}</td>
                                    {week.days.map((day: any, dI: number) => <>
                                        <td key={dI}>{day.run_distance || day.distance} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type) ? '-' : ''} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type).replace('Run', '')}
                                            {day.cross_training ? <><br /><span>{day.cross_training}</span></> : ''}
                                        </td>
                                    </>)}
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
            <div style={{ border: '4px solid #CCC', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignContent: 'space-evenly', margin: '0.2rem 0' }}>
                <span style={{ margin: '0 1rem' }}>INPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.prompt_tokens || 'PENDING'}</span></span>
                <span style={{ margin: '0 1rem' }}>OUTPUT TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.completion_tokens || 'PENDING'}</span></span>
                <span style={{ margin: '0 1rem' }}>TOTAL TOKENS: <span style={{ color: '#616E0D', fontWeight: 'bolder' }}> {parsedData?.tokens?.total_tokens || 'PENDING'}</span></span>
            </div>

        </>
    }


    const renderPlanPreview = () => {
        return <Table responsive bordered hover size="sm">
            <thead>
                <tr>
                    <th>Week</th>
                    <th>Weekly Mileage</th>
                    {DAYS_OF_WEEK?.map((day, index) => (
                        <th key={index}>{day}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {
                    selectedPlan?.training?.map((training: any, index: number) => (
                        <tr key={index}>
                            <td>{training?.week}</td>
                            <td>{training?.weekly_mileage}</td>
                            {
                                JSON.parse(training?.daily_runs)?.map((run: any, index: number) => (
                                    <td key={index}>
                                        <tr>
                                            <td>{run?.run_distance} -</td>
                                            <td>{run?.run_type.replace('Run', '')}</td>
                                        </tr>
                                        <tr><td colSpan={2}>{run?.cross_training?.replace('Training', '')}</td></tr>
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    }


    const renderPlanItemsSlim = (data: any) => <Grid sx={{ width: displayExpand ? '100%' : displayTokens ? '80%' : '70%', height: '3rem', mb: '0.5rem', fontFamily: 'Poppins-Light !important', color: '#555', p: 0, overflow: 'hidden', transitionDuration: '300ms' }}>
        <RSButton customized secondary card sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
            onClick={() => {
                setSelectedPlan(data)
                setShowOutput(true)
            }}
        >
            <Grid sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onMouseEnter={() => setDisplayTokenHovered(data?.plan_id)}
                onMouseLeave={() => setDisplayTokenHovered('')}
            >
                <Grid sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', mx: '1rem' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                        <span style={{ color: '#666', fontWeight: 'lighter', marginRight: '1.4rem', fontSize: '0.9rem' }}>{moment(data?.date_created).format('MM/DD/YYYY')}</span>
                        <span style={{ fontFamily: 'Poppins-Semibold', fontSize: '0.9rem' }}>{displayFirstName ? data?.account?.first_name : data?.account?.email}</span>
                        {editedPlanList.includes(data?.plan_id) ? <i className="fa-solid fa-pen-circle" style={{ color: '#B2DBA7', fontSize: '20px', marginLeft: '0.3rem' }}></i>
                            : ''}

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ fontWeight: 'bold', color: '#BBB', margin: '0 0.8rem' }}>{data?.plan_id}</span>
                        <span style={{ fontWeight: 'bold', color: '#959E47', margin: '0 0.5rem' }}>{data?.race_type}</span>
                        <span style={{ display: 'flex', color: `${getRerunsTotal(data) > 1 ? '#FF574F' : '#0096FF'}` }}>
                            {getRerunsTotal(data) == 0 ? <i className="fa-solid fa-face-smile" style={{ fontSize: '20px' }} />
                                : <div style={{ width: '22px', height: '22px', border: `${getRerunsTotal(data) > 1 ? '3px solid #FF6E2F' : '2px solid #2F75BA'}`, borderRadius: '14px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                    <b style={{ marginTop: '2px' }}>{getRerunsTotal(data)}</b>
                                </div>}
                        </span>

                        <span style={{ display: 'flex', color: `${data?.status ? '#BBD457' : '#9CD4C7'}`, marginLeft: '0.4rem' }}>
                            {data?.status ? <i className="fa-solid fa-circle-check" style={{ fontSize: '20px' }} />
                                : <i className="fa-solid fa-circle" style={{ fontSize: '20px' }} />}
                        </span>
                    </div>
                </Grid>

                {/* { (displayTokens || displayTokenHovered) && */}
                <div className="card-slanted--reverse" style={{ display: 'flex', flexDirection: 'row', width: `${(displayTokens || displayTokenHovered == data?.plan_id) ? displayTokens ? '45%' : '15%' : '0%'}`, height: '3rem', background: '#B8BA4A', color: 'white', justifyContent: 'center', alignItems: 'center', transitionDuration: '500ms' }} >

                    {displayTokens ? <>
                        <span style={{ marginLeft: '0.6rem' }}>
                            <span style={{ color: '#333' }}>IN: </span>{displayTokensUsed(data, 'INPUT')}
                        </span>
                        <span style={{ marginLeft: '0.6rem' }}>
                            <span style={{ color: '#333' }}>OUT: </span>{displayTokensUsed(data, 'OUTPUT')}
                        </span>
                        <span style={{ marginLeft: '0.6rem' }}>
                            <span style={{ color: '#333' }}>T: </span>{displayTokensUsed(data, 'TOTAL')}
                        </span>
                    </> :
                        <span style={{ marginLeft: '0.3rem' }}>
                            {displayTokensUsed(data, 'TOTAL')}
                        </span>
                    }
                </div>

            </Grid>
        </RSButton>
    </Grid >


    const renderPlanItemsBulk = (data: any) => <RSButton customized secondary card
        sx={{
            height: '6rem', mb: '0.5rem', color: '#555', overflow: 'hidden',
            display: 'flex', flexDirection: 'column', paddingBottom: '0'
        }}
        onClick={() => {
            setSelectedPlan(data)
            setShowOutput(true)
        }}
    >
        <Grid sx={{ px: '1rem', width: '100%', display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', pb: `${styleBulkColor ? '0.4rem' : '1rem'}`, borderBottom: `${styleBulkColor ? '1px solid white' : '2px solid #DDD'}`, transitionDuration: '300ms' }}>
            <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: '#A3A3A3' }}>Date <span style={{ fontFamily: `${styleBulkColor ? 'Poppins-Semibold' : 'Poppins-Medium'}`, marginLeft: '0.2rem', color: `${styleBulkColor ? '#555' : 'black'}`, transitionDuration: '300ms' }}>{moment(data?.date_created).format('MM/DD/YYYY')}</span></span>

            <div style={{ backgroundColor: `${styleBulkColor ? '#888' : 'white'}`, padding: `${styleBulkColor ? '0.2rem 0.7rem' : '0 0'}`, marginTop: `${styleBulkColor ? '-0.4rem' : '0'}`, borderRadius: '8px', transitionDuration: '300ms' }}>
                <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: `${styleBulkColor ? '#DDD' : '#A3A3A3'}` }}>User <span style={{ fontFamily: 'Poppins-Medium', marginLeft: '0.4rem', color: `${styleBulkColor ? 'white' : 'black'}` }}>{data?.account?.email}</span></span>
            </div>

            <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: '#A3A3A3' }}>Type <span style={{ fontFamily: 'Poppins-Medium', marginLeft: '0.2rem', color: 'black' }}>{data?.race_type}</span></span>

            <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: '#A3A3A3' }}>ID <span style={{ fontFamily: 'Poppins-Medium', marginLeft: '0.2rem', color: 'black' }}>{data?.plan_id}</span></span>

            <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: '#A3A3A3' }}>Status <span style={{ fontFamily: 'Poppins-Light', marginLeft: '0.2rem', color: 'black' }}>{data?.status ? <i className="fa-solid fa-circle-check" style={{ fontSize: '25px', color: '#BACC1A' }} />
                : <i className="fa-solid fa-circle" style={{ fontSize: '25px', color: '#A3A3A3' }} />}</span></span>
        </Grid>
        <Grid sx={{ px: '1rem', fontFamily: 'Poppins-Light', width: '100%', display: 'flex', justifyContent: 'space-between', paddingBottom: '0', marginBottom: '0', backgroundColor: `${styleBulkColor ? '#FBFFE0' : 'white'}`, transitionDuration: '300ms' }}>
            <Grid sx={{ width: '70%', display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontFamily: 'Poppins-ExtraBold', fontSize: '12px', color: '#A3A3A3' }}>TOKENS</span>
                <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: '#A3A3A3' }}>Input<span style={{ fontFamily: 'Poppins-Light', marginLeft: '0.3rem', color: 'black' }}>{displayTokensUsed(data, 'INPUT')}</span></span>
                <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: '#A3A3A3' }}>Output<span style={{ fontFamily: 'Poppins-Light', marginLeft: '0.3rem', color: 'black' }}>{displayTokensUsed(data, 'OUTPUT')}</span></span>
                <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px', color: '#A3A3A3' }}>Total<span style={{ fontFamily: 'Poppins-Light', marginLeft: '0.3rem', color: 'black' }}>{displayTokensUsed(data, 'TOTAL')}</span></span>
            </Grid>

            <span style={{ fontFamily: 'Poppins-Bold', fontSize: '12px' }}>Reruns<span style={{ fontFamily: 'Poppins-Light', marginLeft: '0.6rem', marginRight: '0.5rem' }}>{getRerunsTotal(data)}</span></span>
        </Grid>

    </RSButton>


    const renderButtonsBackNext = () => {
        return <div style={{ textAlign: 'end' }}>
            <RSButton secondary={page > 0} disabled={page < 1} className='mt-2' sx={{ width: '8rem', mr: '0.8rem' }}
                onClick={() => {
                    // setTrainingPlans([])
                    setPage(page - 1)
                }}>Back</RSButton>
            <RSButton className='mt-2' sx={{ width: '8rem' }} disabled={isEndOfResults}
                onClick={() => {
                    // setTrainingPlans([])
                    setPage(page + 1)
                }}>
                Next
            </RSButton>
        </div>
    }


    const renderButtonLoadMorePlans = () => <div style={{ textAlign: 'center' }}>
        <RSButton className='mt-2' sx={{ width: '16rem' }} disabled={isEndOfResults}
            onClick={() => {
                // setTrainingPlans([])
                setPage(page + 1)
                if (!isEndOfResults && searchValue != '') {
                    // loadMorePlans()
                }
            }}>
            Load More
        </RSButton>
    </div>


    const renderSavePopup = () => <RSDrawer bottom popUpWidth='450px' popUpHeight='600px' open={displaySavePopup} onClose={() => setDisplaySavePopup(false)}>

        {/* bgcolor #F4E6D3 */}
        <Grid container xs={12} sx={{ flexDirection: 'column', height: '190px' }} alignItems='center'>

            {editSaveSuccess ?
                <>
                    <Box>
                        <Box sx={{ width: '100%', fontSize: '22px', fontFamily: 'Poppins-Bold', p: 2, display: 'flex', justifyContent: 'center', marginTop: '10px', textAlign: "center", lineHeight: 1.3 }}>
                            Plan Updated Successfully
                        </Box>

                        <Box sx={{ mt: '2.8rem', zIndex: '1' }}>
                            <b><a href={`mailto:${selectedPlan?.account?.email}?subject=${selectedPlan?.event_name}`}>
                                <RSButton sx={{ color: 'black', overflow: 'hidden' }}>
                                    Email {selectedPlan?.account?.first_name ? selectedPlan?.account?.first_name : 'User'}
                                    <img src='/img-new/misc/email-stamp.png' style={{ position: 'absolute', right: -4, top: -4, width: '40px', height: '40px', opacity: '0.7' }} />
                                </RSButton></a></b>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", bottom: 0, position: 'absolute', mt: 3, zIndex: '-1' }}>
                        <img src='/img-new/race-events/Runner-Ready-M-Mobile.jpg' />
                    </Box>
                </>
                : <>
                    <Box>
                        <Box sx={{ width: '100%', fontSize: '22px', fontFamily: 'Poppins-Bold', p: 2, display: 'flex', justifyContent: 'center', marginTop: '10px', textAlign: "center", lineHeight: 1.3 }}>
                            Plan Failed to Update
                        </Box>

                        <Box sx={{ width: '100%', fontSize: '19px', fontFamily: 'Poppins-Light', p: 2, display: 'flex', justifyContent: 'center', marginTop: '20px', textAlign: "center", lineHeight: 1.3 }}>
                            {editSaveErrorMsg}
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", bottom: 0, position: 'absolute', mt: 3 }}>
                        <img src='/img-new/race-events/Runner-ShoeTie-Mobile.jpg' style={{ opacity: '0.4' }} />
                    </Box>
                </>

            }
        </Grid>




        {/* /img-new/race-events/Runner-ShoeTie-Mobile.jpg */}
    </RSDrawer>


    const renderPlanEditor = () =>
        <>
            <ClickAwayListener onClickAway={() => !onDropdown && handleExit()}>
                <TableContainer component={Paper} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ ...tableHeaderStyling, width: 100 }}>
                                    Week
                                </TableCell>
                                <TableCell sx={{ ...tableHeaderStyling, width: 100 }}>
                                    Weekly Mileage
                                </TableCell>
                                {DAYS_OF_WEEK?.map((day, index) => (
                                    <TableCell key={index} sx={{ ...tableHeaderStyling, width: 100 }}>
                                        {day}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {planRows?.map((row: any, rI: any) => (<>
                                <TableRow>
                                    <TableCell sx={{ ...tableBodyStyling, width: 100 }}>
                                        {row?.week}
                                    </TableCell>
                                    <TableCell sx={{ ...tableBodyStyling, width: 100 }}>
                                        {row?.weekly_mileage}
                                    </TableCell>

                                    {row?.days?.map((day: any, dI: any) => (
                                        <TableCell key={dI} sx={{ ...tableBodyStyling, width: 100, height: 100 }}>
                                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignContent: 'start', alignItems: 'start' }}>
                                                <div onClick={() => { setRowIndex(rI); setColumnIndex(dI) }} >
                                                    {rowIndex === rI && columnIndex === dI ?
                                                        <>
                                                            <TextField
                                                                sx={{ width: 100, ml: '-0.8rem' }}
                                                                placeholder={day.run_distance}
                                                                defaultValue={day.run_distance}
                                                                onChange={(event) => handleChangeRunDistance(rI, dI, event.target.value)}
                                                                onKeyPress={(e) => { if (e.key === "Enter") handleExit() }}
                                                            />
                                                        </>
                                                        : day.run_distance
                                                    }
                                                </div>
                                                <div onClick={() => { setRowIndex(rI); setColumnIndex(dI) }}>
                                                    {rowIndex === rI && columnIndex === dI ?
                                                        <>
                                                            {/* <TextField
                                                                sx={{ ml: '-0.8rem' }}
                                                                placeholder={day.run_type}
                                                                defaultValue={day.run_type}
                                                                onChange={(event) => handleChangeRunType(rI, dI, event.target.value)}
                                                                onKeyPress={(e) => { if (e.key === "Enter") handleExit() }}
                                                            /> */}
                                                            <FormControl sx={{ width: 100, ml: '-0.8rem', mt: '0.6rem', height: '3.6rem' }}>
                                                                <InputLabel id="demo-simple-select-helper-label">Run Type</InputLabel>
                                                                <Select
                                                                    value={day.run_type}
                                                                    label="Run Type"
                                                                    onChange={(e) => handleChangeSelectRunType(rI, dI, e.target.value)}
                                                                    onClose={() => setOnDropdown(false)}
                                                                    onOpen={() => setOnDropdown(true)}
                                                                >
                                                                    {RUN_TYPES?.map((type, index) => (<MenuItem key={index} value={type} style={{ border: 'none' }}>{type}</MenuItem>))}
                                                                </Select>
                                                            </FormControl>
                                                        </>
                                                        : day.run_type
                                                    }
                                                </div>
                                                <div onClick={() => { setRowIndex(rI); setColumnIndex(dI) }}>
                                                    {rowIndex === rI && columnIndex === dI ?
                                                        <>
                                                            {/* <TextField
                                                                sx={{ ml: '-0.8rem' }}
                                                                placeholder={day.cross_training}
                                                                defaultValue={day.cross_training}
                                                                onChange={(event) => handleChangeCrossTraining(rI, dI, event.target.value)}
                                                                onKeyPress={(e) => { if (e.key === "Enter") handleExit() }}
                                                            /> */}
                                                            <FormControl sx={{ width: 100, ml: '-0.8rem', mt: '0.4rem', height: '3.6rem' }}>
                                                                <InputLabel id="demo-simple-select-helper-label">XT</InputLabel>
                                                                <Select
                                                                    value={day.cross_training}
                                                                    label="XT"
                                                                    onChange={(e) => handleChangeSelectXTtype(rI, dI, e.target.value)}
                                                                    onClose={() => setOnDropdown(false)}
                                                                    onOpen={() => setOnDropdown(true)}
                                                                >
                                                                    {XT_TYPES?.map((type, index) => (<MenuItem key={index} value={type} style={{ border: 'none' }}>{type}</MenuItem>))}
                                                                </Select>
                                                            </FormControl>
                                                        </>
                                                        : day.cross_training
                                                    }
                                                </div>
                                            </div>
                                        </TableCell>
                                    ))}

                                </TableRow>
                            </>

                            ))
                            }


                        </TableBody>
                    </Table>
                </TableContainer>
            </ClickAwayListener>
        </>



    const rerunTabsListWeekly = [{
        title: '1',
        body: <>{renderRurunTabWeekly()}</>
    }, {
        title: '2',
        body: <>* under construction, currently showing the latest results on 1 *</>
    }]

    const rerunTabsListDaily = [{
        title: '1',
        body: <>{renderRurunTabDaily()}</>
    }, {
        title: '2',
        body: <>* under construction, currently showing the latest results on 1 *</>
    }]

    const { tabsUI, tab, setTab } = useRSTabs({
        data: selectedPill === 'Step one' ? rerunTabsListWeekly : selectedPill === 'Step two' ? rerunTabsListDaily : [],
        btnContSX: { width: '100%' },
        btnTitleSX: { fontFamily: 'Poppins-Light !important', fontSize: '12px', letterSpacing: 'normal', width: '100%' }
    })


    return <>

        <h1 style={{ fontSize: '1.8rem', color: '#777', lineHeight: '0.6rem', marginBottom: '1.2rem', marginLeft: '1rem' }}>
            {selectedMenu === 'prompts' ? 'Prompts Management' :
                selectedMenu === 'builds' ? 'Race Build Toggles' :
                    selectedMenu === 'sandbox' ? 'Sandbox Plan Builder' :
                        selectedMenu === '' && 'Place your AD here'}
        </h1>
        <Grid container spacing={1} sx={{ pb: '3rem' }}>

            {selectedMenu == 'plans' && !showOutput && <Grid item xs={selectedMenu == 'plans' ? 1 : 0} />}

            <Grid item xs={selectedMenu == 'prompts' ? 6 : selectedMenu == 'sandbox' ? 12 : 8.4}>

                {
                    selectedMenu === 'plans' && !showOutput ? (
                        <>
                            <h1 style={{ fontSize: '1.8rem', color: '#777', lineHeight: '0.6rem', marginBottom: '1.2rem', marginLeft: '1rem' }}>Plan History</h1>
                            <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ backgroundColor: '#E6EBF0' }}>

                                {/* <Table responsive  bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>User</th>
                                            <th>Status</th>
                                            <th>RTs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trainingPlans?.map((data: any, index: number) => (
                                            <tr onClick={() => {
                                                setSelectedPlan(data)
                                                setShowOutput(true)
                                            }} style={{ cursor: 'pointer' }} key={index}>
                                                <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{index + 1}</td>
                                                <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{moment(data?.date_created).format('MM/DD/YYYY')}</td>
                                                <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{data?.account?.email}</td>
                                                <td style={{ color: `${data.status ? 'green' : 'red'}`, backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}><b>{data?.status ? 'Active' : 'Declined'}</b></td>
                                                <td style={{ justifyContent: 'center', color: `${getRerunsTotal(data) > 1 ? '#FF5733' : '#0096FF'}` }}>{getRerunsTotal(data) == 0 ? <i className="fa-solid fa-face-smile" /> : <b>{getRerunsTotal(data)}</b>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table> */}

                                <div style={{ width: '100%', backgroundColor: 'white', padding: '1rem 0.4rem', borderRadius: '4px', display: 'flex', flexDirection: 'row', marginBottom: '1.5rem' }}>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>

                                        <div>

                                            {/* race type */}
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Race Type</InputLabel>
                                                <Select
                                                    value={filterRaceType}
                                                    label="Race Type"
                                                    onChange={(e) => handleChangeSelectRaceType(e)}
                                                    sx={{ width: '140px', height: '50px', backgroundColor: 'white', marginRight: '1rem' }}
                                                >
                                                    {RACE_TYPE_FILTER?.map((race, index) => (<MenuItem key={index} value={race} style={{ border: 'none' }}>{race}</MenuItem>))}
                                                </Select>
                                            </FormControl>

                                            {/* status */}
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                                <Select
                                                    value={status}
                                                    label="Status"
                                                    onChange={(e) => handleChangeSelectStatus(e)}
                                                    sx={{ width: '140px', height: '50px', backgroundColor: 'white', marginRight: '1rem' }}
                                                >
                                                    {STATUS_FILTER?.map((status, index) => (<MenuItem key={index} value={index} style={{ border: 'none' }}>{status}</MenuItem>))}
                                                </Select>
                                            </FormControl>


                                            {/* Search */}
                                            <FormControl sx={{ m: 1 }}>
                                                <Input autoFocus inputProps={{ enterkeyhint: 'search' }} placeholder="Search User / Plan ID"
                                                    onKeyDown={async (e) => e.key == 'Enter' ? handleSearchEnter(e) : undefined}
                                                    // onChange={(e) => setSearchValue(e.target.value)}
                                                    // value={searchValue}
                                                    sx={{ py: '0.6rem' }} /
                                                >
                                            </FormControl>
                                        </div>


                                        {!isSearching && <div style={{ display: 'flex', flexDirection: 'row' }}>

                                            <div style={{ width: `${!planListSlim ? '5rem' : '0rem'}`, transitionDuration: '300ms', overflow: 'hidden', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid sx={{ fontFamily: 'Poppins-Light', color: '#B8BA4A', fontWeight: 'bolder', transitionDuration: '300ms', overflow: 'hidden' }}>COLOR</Grid>
                                                    <Switch color="warning" onClick={() => setStyleBulkColor(!styleBulkColor)} />
                                                </div>
                                            </div>

                                            <div style={{ width: `${planListSlim ? '5rem' : '0rem'}`, transitionDuration: '300ms', overflow: 'hidden', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid sx={{ fontFamily: 'Poppins-Light', color: '#B8BA4A', fontWeight: 'bolder' }}>TOKENS</Grid>
                                                    <Switch color="warning" onClick={() => setDisplayTokens(!displayTokens)} />
                                                </div>
                                            </div>

                                            <div style={{ width: `${planListSlim ? '5rem' : '0rem'}`, transitionDuration: '300ms', overflow: 'hidden', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid sx={{ fontFamily: 'Poppins-Light', color: '#B8BA4A', fontWeight: 'bolder' }}>EXPAND</Grid>
                                                    <Switch defaultChecked color="warning" onClick={() => setDisplayExpand(!displayExpand)} />
                                                </div>
                                            </div>

                                            <div style={{ marginRight: '1.2rem', marginLeft: '0.6rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid sx={{ fontFamily: 'Poppins-Light', color: '#B8BA4A', fontWeight: 'bolder' }}>SLIM</Grid>
                                                <Switch color="warning" defaultChecked onClick={() => setPlanListSlim(!planListSlim)} />
                                            </div>

                                        </div>}

                                    </div>

                                </div>



                                <Box>
                                    {trainingPlans?.map((data: any, index: number) => (
                                        <Grid key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            {planListSlim ? renderPlanItemsSlim(data) : renderPlanItemsBulk(data)}

                                            {/* <tr onClick={() => {
                                            setSelectedPlan(data)
                                            setShowOutput(true)
                                        }} style={{ cursor: 'pointer' }} key={index}>
                                            <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{index + 1}</td>
                                        
                                            <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{moment(data?.date_created).format('MM/DD/YYYY')}</td>
                                            <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{data?.account?.email}</td>
                                            <td style={{ color: `${data.status ? 'green' : 'red'}`, backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}><b>{data?.status ? 'Active' : 'Declined'}</b></td>
                                            <td style={{ justifyContent: 'center', color: `${getRerunsTotal(data) > 1 ? '#FF5733' : '#0096FF'}` }}>{getRerunsTotal(data) == 0 ? <i className="fa-solid fa-face-smile" /> : <b>{getRerunsTotal(data)}</b>}</td>
                                        </tr> */}
                                        </Grid>
                                    ))}

                                </Box>

                                {(isSearching || isLoading) && <div style={{ width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                                    <Stack sx={{ width: '100%', color: 'grey.500', borderRadius: '10px' }} spacing={0}>
                                        <LinearProgress color="secondary" sx={{ height: '7px' }} />
                                        <LinearProgress color="success" sx={{ height: '7px' }} />
                                        <LinearProgress color="inherit" sx={{ height: '10px', color: 'white' }} />
                                        <LinearProgress color="success" sx={{ height: '7px' }} />
                                        <LinearProgress color="secondary" sx={{ height: '7px' }} />
                                    </Stack>
                                </div>}
                            </div>

                            {!isEndOfResults && !isSearching && trainingPlans.length > 0 && renderButtonLoadMorePlans()}
                            {/* {renderButtonsBackNext()} */}


                        </>
                    ) : selectedMenu === 'prompts' ? renderVersionForm() : undefined
                }
                {
                    selectedMenu === 'plans' && showOutput ? (
                        <>
                            <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <div onClick={() => {
                                            setSelectedPill('Final Plan')
                                            setShowOutput(false)
                                        }} style={{ cursor: 'pointer', width: '3rem' }}>
                                            <h1 style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: '1rem', marginTop: '0.6rem' }}>{'  < '}</h1>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} sx={{ mt: '-0.4rem', color: '#AAA', fontWeight: 'bold' }}>PLAN ID: {selectedPlan?.plan_id}</Grid>
                                    <Grid item xs={5}>
                                        <div style={{ cursor: 'pointer', textAlign: 'right', marginBottom: '0.6rem' }}>
                                            {/* <h5>{'View Calendar'}</h5> */}
                                            <CalendarMonthIcon onClick={() => setOpenViewPlan(true)} sx={{ fontSize: "2em", stroke: 'black', strokeWidth: '0.4px' }} />
                                        </div>
                                    </Grid>

                                </Grid>
                                <Box sx={{ mt: '-0.9rem' }}>

                                    <Grid sx={{ mb: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                                        <SelectPill
                                            index={pillKey}
                                            key={pillKey}
                                            options={tabOptions}
                                            onChange={onPillChange}
                                            color={'#85E0F2'}
                                        />
                                    </Grid>
                                    {/* <ScrollRow setIsScrolling={setIsScrolling}>
                                    </ScrollRow> */}
                                </Box>

                                {selectedPill === 'Final Plan' ? (<>
                                    <div style={{ border: '4px solid #CCC', borderRadius: '10px' }}>
                                        {renderPlanEditor()}
                                    </div>
                                    {(isSearching || isLoading || isUpdating)
                                        ? <div style={{ width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                                            <Stack sx={{ width: '100%', color: 'grey.500', borderRadius: '10px' }} spacing={0}>
                                                <LinearProgress color="secondary" sx={{ height: '7px' }} />
                                                <LinearProgress color="success" sx={{ height: '7px' }} />
                                                <LinearProgress color="inherit" sx={{ height: '10px', color: 'white' }} />
                                                <LinearProgress color="success" sx={{ height: '7px' }} />
                                                <LinearProgress color="secondary" sx={{ height: '7px' }} />
                                            </Stack>
                                        </div>
                                        : <div>
                                            <RSButton disabled={planWeeksChanged && planWeeksChanged.length < 1}
                                                onClick={() => { handleUpdateSelectedPlan() }}>
                                                SAVE
                                            </RSButton>
                                        </div>}

                                </>

                                )
                                    : selectedPill === 'Plan Preview' ? (<>
                                        <div style={{ border: '4px solid #CCC', borderRadius: '10px' }}>
                                            {renderPlanPreview()}
                                        </div>
                                    </>

                                    ) : selectedPill === "Step zero" ? (
                                        <>
                                            <Grid container>
                                                {selectedPlan?.steps.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <h5>{'Long Runs'}</h5>
                                                        <Table responsive bordered hover size="sm">
                                                            <thead>
                                                                <tr>
                                                                    {JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.longRuns?.map((x: any, i: number) => (
                                                                        <th key={i}>w {x.w}</th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    {JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.longRuns?.map((x: any, i: number) => (
                                                                        <th key={i}>{x.lr}</th>
                                                                    ))}
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Grid>
                                                )}
                                            </Grid>

                                            {JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.latestResults.length > 0 && (
                                                <Grid container>
                                                    <Grid xs={12}>
                                                        <Table responsive bordered hover size="sm">
                                                            <thead>
                                                                <tr>
                                                                    {Object.keys(JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.latestResults[0])?.splice(0, 3).map((x: any, i: number) => (
                                                                        <td key={x}>{x.replaceAll('_', ' ')}</td>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.latestResults?.map((x: any, i: number) => (
                                                                        <tr key={i}>
                                                                            <td>{x.Week}</td>
                                                                            <td>{x.Long_run}</td>
                                                                            <td>{x.Weekly_mileage}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </>
                                    ) : selectedPill === "Step one" ? (
                                        <div>
                                            {tabsUI}
                                        </div>
                                    ) : selectedPill === "Step two" ? (
                                        <div>
                                            {tabsUI}
                                        </div>
                                    ) : selectedPill === "Step three" ? (
                                        <div>
                                            {renderRunTypeTable()}
                                        </div>
                                    ) : selectedPill === "Step four" ? (
                                        <div>
                                            {/* {console.log(JSON.parse(selectedPlan?.steps[4]?.data || {}))}
                                        {console.log(Object.keys(JSON.parse(selectedPlan?.steps[4]?.data || {})[0]))} */}
                                            {renderCrossTrainTable()}
                                        </div>
                                    ) : undefined}
                            </div>

                            <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                                <Grid container sx={{ width: '100%' }}>

                                    <Box sx={{ fontFamily: 'poppins-light', width: '100%', border: '4px double #EEE', borderRadius: '12px', padding: '0.2rem 0.4rem', }}>

                                        <Grid sx={{ display: 'flex', flexDirection: 'row', marginTop: '0.2rem' }}>
                                            <div style={{ width: '40%' }}>
                                                Created on:
                                                <b style={{ marginLeft: '0.4rem' }}>{moment(selectedPlan?.date_created).format('MMM D, HH:MM zz') || ''}</b>
                                            </div>

                                            <div style={{ width: '30%' }}>Dailies reruns: </div>
                                            <div style={{ width: '10%' }}><b>{getReruns(selectedPlan, '3') > 2 ? <i className='fa-solid fa-poo' style={{ fontSize: '25px' }} /> : getReruns(selectedPlan, '3')}</b></div>

                                            <div style={{ width: '20%' }}>
                                                Status:
                                                <b style={{ marginLeft: '0.4rem', color: `${selectedPlan?.status ? '#BBBD0D' : '#9CD4C7'}` }}>{selectedPlan?.status ? 'Accepted' : 'Declined'}</b>
                                            </div>
                                        </Grid>

                                        <Grid sx={{ display: 'flex', flexDirection: 'row', marginTop: '0.2rem' }}>
                                            <div style={{ width: '40%' }}>
                                                Last edit on:
                                                <b style={{ marginLeft: '0.4rem' }}>{planLastEdited}</b>
                                            </div>
                                            <div style={{ width: '30%' }}>Weeklies reruns: </div>
                                            <div style={{ width: '10%' }}><b>{getReruns(selectedPlan, '2') > 2 ? <i className='fa-solid fa-poo' style={{ fontSize: '25px' }} /> : getReruns(selectedPlan, '2')}</b></div>

                                        </Grid>

                                    </Box>
                                </Grid>
                            </div>
                        </>
                    ) :
                        selectedMenu === 'builds' ? (<div style={{ marginTop: '2.2rem' }}>
                            <BuildPlanAvailability />
                        </div>

                        )
                            : undefined
                }
                {
                    selectedMenu === 'sandbox' && (<ChatCompletionSingle userInfo={option?.userInfo} />)
                }

                <RSDrawer bottom fitContent popUpWidth='100%' popUpHeight='90%' open={openViewPlan} onClose={() => {
                    setOpenViewPlan(false)
                }}>
                    <GPTTrainingPlan planIdLink={selectedPlan?.plan_id} onCloseModal={() => {
                        setOpenViewPlan(false)
                    }} />
                </RSDrawer>
            </Grid>

            <Grid item xs={selectedMenu == 'prompts' ? 6 : 3}>

                {showOutput &&
                    selectedMenu === 'plans' ? (
                    <>
                        <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                            <Table responsive bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>User Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td><b>{selectedPlan?.profile?.name}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td><b><a href={`mailto:${selectedPlan?.account?.email}?subject=${selectedPlan?.event_name}`}>{selectedPlan?.account?.email}</a></b></td>
                                    </tr>
                                    <tr>
                                        <td>Age</td>
                                        <td><b>{selectedPlan?.profile?.dob ? moment().diff(selectedPlan?.profile?.dob, 'years') : ''}</b></td>
                                    </tr>
                                    <tr>
                                        <td>VDot</td>
                                        <td><b>{selectedPlan?.vdot}</b></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                            <Table responsive bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Plan Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '100px' }}>Race</td>
                                        <td><b>{RaceSelection?.find(x => x?.title.toLowerCase() === selectedPlan?.race_type?.toLowerCase())?.subtitle}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td><b>{selectedPlan?.event_name}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Race Date</td>
                                        <td><b>{moment(selectedPlan?.race_date).format('MMMM DD YYYY, dddd')}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Goal Time</td>
                                        <td colSpan={3} ><b>{getGoalTime(selectedPlan?.target_time, selectedPlan?.race_type.toLowerCase())}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '50%' }}>Level</td>
                                        <td><b>{getPlanLevel()}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '50%' }}>Unit</td>
                                        <td><b>{selectedPlan?.profile?.distance_unit?.toUpperCase() || 'MI'}</b></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                            <Table responsive bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Runner Preferences</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Avg (Last 4 Weeks)</td>
                                        <td><b>{selectedPlan?.average_distance + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Long Run (2 weeks)</td>
                                        <td><b>{selectedPlan?.longest_run + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Longrun Day</td>
                                        <td><b>{selectedPlan?.longrun_day}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Run (days)</td>
                                        <td><b>{selectedPlan?.max_run_days}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Run Days</td>
                                        <td><b>{sortDays(selectedPlan?.run_days)?.split(',')?.map((e: string, i: number) => (<span key={i}>{e.slice(0, 3)?.toUpperCase()}{i !== sortDays(selectedPlan?.run_days)?.split(',').length - 1 ? ', ' : ''}</span>))}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Stretch Areas</td>
                                        <td><b>{selectedPlan?.stretching_areas.split(',').map((e: string) => (<span key={e}>{e}<br /></span>))}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Training</td>
                                        <td><b>{selectedPlan?.cross_training}</b></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                            <Table responsive bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th colSpan={4}>Recommendations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50%' }}>Suggested Starting Long Run Distance</td>
                                        <td><b>{selectedPlan?.suggested_start_distance + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '50%' }}>Suggested Starting Week Distance</td>
                                        <td><b>{selectedPlan?.suggested_start_week_distance + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '50%' }}>Suggested Average Range</td>
                                        <td><b>{selectedPlan?.average_range + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Weekly Avg</td>
                                        <td><b>{getSuggestedDistance(selectedPlan?.average_range) + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Suggested Max Range</td>
                                        <td><b>{selectedPlan?.max_range + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Peak Weekly</td>
                                        <td><b>{getSuggestedDistance(selectedPlan?.max_range) + distanceUnit}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Final Saved Range</td>
                                        <td><b>{selectedPlan?.max_distance + distanceUnit}</b></td>
                                    </tr>

                                </tbody>
                            </Table>
                        </div>

                        {/* <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                            <Table responsive bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th colSpan={4}>Paces</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50%' }}>Easy</td>
                                        <td><b>0</b></td>
                                    </tr>
                                    <tr>
                                        <td>Base</td>
                                        <td><b>0</b></td>
                                    </tr>
                                    <tr>
                                        <td>Tempo</td>
                                        <td><b>0</b></td>
                                    </tr>
                                    <tr>
                                        <td>Race</td>
                                        <td><b>0</b></td>
                                    </tr>

                                </tbody>
                            </Table>
                        </div> */}

                        {/* <div className='mt-2'>
                                <Table responsive  bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Plan Health</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%' }}>Created on</td>
                                            <td><b>{moment(selectedPlan?.date_created).format('MMM D, HH:MM zz') || ''}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '50%' }}>Weekly Mileage</td>
                                            <td><b>{getReruns(selectedPlan, '2') > 2 ? <i className='fa-solid fa-poo' style={{ fontSize: '25px' }} /> : getReruns(selectedPlan, '2')}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '50%' }}>Daily Runs</td>
                                            <td><b>{getReruns(selectedPlan, '3') > 2 ? <i className='fa-solid fa-poo' style={{ fontSize: '25px' }} /> : getReruns(selectedPlan, '3')}</b></td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div> */}

                    </>
                ) : selectedMenu === 'builds' ? (
                    <></>
                ) : selectedMenu === 'sandbox' ? (
                    <></>
                ) : selectedMenu === 'prompts' ?
                    (<div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                        <PromptVersion setLastVersion={setLastVersion} selectedPrompt={selectedPrompt} isLoading={isSavingOrUpdating} restorePromptVersion={restorePromptVersion} setSelectedPrompt={setSelectedPrompt} data={prompts?.data?.result || []} deletePromptVersion={deletePromptVersion} activatePromptVersion={activatePromptVersion} />
                    </div>)
                    : undefined
                }
                {!showOutput && selectedMenu === 'overview' &&
                    <>
                        <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden' }}>
                            {/* {MENUS.map((menu, index) => (
                        <div onClick={() => setSelectedMenu(menu)} style={{ cursor: 'pointer', textDecoration: `${menu === selectedMenu ? 'underline' : ''}` }} key={index}>
                            <h5>{menu}</h5>
                        </div>
                    ))} */}
                            <Grid container mb={1} sx={{ mr: '2rem' }}>
                                {/* <h5><b>Training Plans</b></h5> */}
                                <Grid sm={2}>
                                    <Select style={{ width: '130px' }} value={status} onChange={(e) => handleChangeSelectStatus(e)} >
                                        {STATUS_FILTER?.map((status, index) => (<MenuItem key={index} value={index}>{status}</MenuItem>))}
                                    </Select>
                                </Grid>
                            </Grid>

                            <div style={{ marginRight: '1.2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid sx={{ fontFamily: 'Poppins-Light', color: '#B8BA4A', fontWeight: 'bolder' }}>TOKENS</Grid>
                                <Switch color="warning" onClick={() => setDisplayTokens(!displayTokens)} />
                            </div>
                            <div style={{ marginRight: '1.2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid sx={{ fontFamily: 'Poppins-Light', color: '#B8BA4A', fontWeight: 'bolder' }}>NAME</Grid>
                                <Switch color="warning" onClick={() => setDisplayFirstName(!displayFirstName)} />
                            </div>
                            {/* <div style={{ marginRight: '1.2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ fontFamily: 'Poppins-Light', color: '#B8BA4A', fontWeight: 'bolder' }}>DETAILS</Grid>
                        <Switch color="warning" onClick={() => setDisplayTokens(!displayTokens)} />
                    </div> */}
                        </div>

                        <div className='half-md-card w-100 card mx-auto p-4 mt-2' style={{ height: '30rem', color: '#444' }}>
                            <div style={{ fontWeight: 'bolder', marginBottom: '0.5rem' }}>DRAFT BOARD</div>
                            <div style={{ fontSize: '0.8rem' }}>Tokens used today: #</div>
                            <div style={{ fontSize: '0.8rem' }}>Plans created today: #</div>
                            <div style={{ fontSize: '0.8rem' }}>Rerun exceeded plans today: #</div>
                        </div>
                        <div className='half-md-card w-100 card mx-auto p-4 mt-2' style={{ height: '14rem', color: '#444' }}>
                            <div style={{ fontWeight: 'bolder', marginBottom: '0.5rem' }}>NOTES</div>
                            <div style={{ fontSize: '0.8rem' }}>To implem board above</div>
                            <div style={{ fontSize: '0.8rem' }}>To add in more details toggle</div>
                            <div style={{ fontSize: '0.8rem' }}>Note old plans dont show some info like tokens</div>
                        </div>

                    </>
                }
            </Grid>
        </Grid>

        {renderSavePopup()}
    </>
}

export default TrainingPlanManagerV2